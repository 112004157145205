import { ScreenSizeService } from './screen-size.service';
import { isPlatformBrowser } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UnsubscribeOnDestroyAdapter } from '../../adapters/unsubscribe-adapter';
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, PLATFORM_ID, Inject } from '@angular/core';

class TemplateContext {
  hasView: boolean = false;
}

@Directive({ selector: '[screenSize]' })
export class ScreenSizeDirective extends UnsubscribeOnDestroyAdapter implements OnInit {
  innerWidth: any;
  option: string;
  context: TemplateContext = new TemplateContext();

  @Input() set screenSize (option: string) {
    if (option) {
      this.option = option;
    }
  }

  constructor (
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<TemplateContext>,
    private deviceDetector: DeviceDetectorService,
    private screenSizeService: ScreenSizeService,
    @Inject(PLATFORM_ID) private platformId: Object) {
    super();
  }

  ngOnInit () {
    if (this.option == 'mobile') {
      if (this.deviceDetector.isMobile()) {
        this.show()
      } else {
        this.hide()
      }
    } 
    else if (this.option == 'notMobile') {
      if (this.deviceDetector.isMobile()) {
        this.hide()
      } else {
        this.show()
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      this.subs.sink = this.screenSizeService.screenSize.subscribe((size) => {
        if (window.innerWidth > 767 && this.option == 'mobile') {
          this.hide()
        } else if (window.innerWidth < 767 && this.option == 'notMobile') {
          this.hide()
        } else {
          this.show()
        }
      })
    }
  }

  show () {
    if (!this.context.hasView) {
      this.context.hasView = true;
      this.viewContainer.createEmbeddedView(this.template, this.context);
    }
  }

  hide () {
    if (this.context.hasView) {
      this.context.hasView = false;
      this.viewContainer.remove();
    }
  }

}
