import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { map } from 'rxjs/operators';

@Injectable()
export class StorageService {
  localStorageToken: any;
  sessionStorageToken: any;

  demoFirst: any;
  demoLast: any;
  demoLiving: any;
  demoSelf: any;
  demoType: any;

  constructor(private storage: StorageMap) {
  }

  get(key: string) {
    return this.storage.get(key);
  }

  getObject(key: string) {
    return this.storage.get(key).pipe(
      map(response => {
        if (typeof response === 'string' || response instanceof String) {
          return JSON.parse(<string>response);
        } else {
          return response;
        }
      })
    );
  }

  set(key: string, value: any, ttl?: string) {
    this.storage.set(key, value).subscribe({
      next: () => {},
      error: error => {
        //handle the error
      }
    });
  }

  setObject(key: string, obj: Object, ttl?: string) {
    this.storage.set(key, JSON.stringify(obj)).subscribe({
      next: () => {},
      error: error => {
        //handle the error
        console.log(error);
      }
    });
  }

  delete(key: string) {
    this.storage.delete(key).subscribe();
  }

  clear() {
    this.storage.clear();
  }

  clearSession() {
    this.storage.clear();
  }

  setTempFirstName (name: string) {
    this.demoFirst = name;
  }

  setTempLastName (name: string) {
    this.demoLast = name;
  }

  setTempType (type: string) {
    this.demoType = type
  }

  resetTempMemorial () {
    this.demoFirst = '';
    this.demoLast = '';
    this.demoType = '';
    this.demoSelf = '';
    this.demoLiving = '';
  }
}
