/**
 * Content Type, options: 'question', 'memory', 'like', 'link', 'list', 'comment'
 */
export enum ContentType {
  question = 'question',
  memory = 'memory',
  like = 'like',
  link = 'link',
  list = 'list',
  comment = 'comment',
  memorial = 'memorial'
}

export interface TrackingOptions {
  // Generic ones when others don't fit

  // sometimes its just nice to have a count
  count?: number;
  // button text etc
  text?: string;

  // manual
  relationshipOther?: string;
  /**
   *  for our content types, see ContentType Enum.  Only needed in non-memory cases.  Memory cases are handled automatically
   */
  contentType?: ContentType;
  contentId?: string;
  imageId?: string;
  imageCount?: number;
  imageSelectCount?: number;
  imageLimit?: boolean;
  imageUploadBytes?: number;
  ownerless?: boolean;
  questionText?: string
  questionId?: string
  imageChanged?: boolean;
  imageRemoved?: boolean;
  externalUrl?: string;
  /**
   * Image source
   * web, device, facebook, instagram
   */
  imageSource?: string;
  componentType?: string;
  /**
   * Youtube memory
   */
  youtubeEdit?: boolean;
  /**
   * Parent component type of tracking event
   *
   * ie.header, footer, banner, memoryTile.To help us identify where on the page was clicked.Useful for pages with multiple places to take the action
   */
  parentComponentType?: string;
  /**
   * Router component of tracking event
   *
   * ie.Memorial, Help Center, Search.The route based component, maybe redundant with $current_url
   */
  routerComponent?: string;
  /**
   * Dialog  of tracking event
   * (ie.AddMemoryDialog, EditProfileDialog)
   */
  dialog?: string;
  dialogTab?: string;
  /**
   * User input of tracking event
   * what people put in the field before submission, useful for search, 'other' field on relationship, add reason to report, blocked, or deleted
   */
  userInput?: string;
  uiName?: string;
  uiType?: 'dialog' | 'menu' | 'message' | 'article' | 'tab';
  // step or tab name
  stepName?: string;
  routeName?: string;
  routeRedirect?: boolean;
  buttonLocation?: string;
  buttonName?: string;
  buttonType?: string;
  /**
   * Edits  of tracking event
   *
   * csv key / value string(ie.theme: #050A03)
   */
  edits?: string;
  error?: boolean;
  errorText?: string;
  errorCode?: string;

  /**
   * Edit Page Settings
   */
  toggleGifts?: boolean;
  privacyChange?: string;
  contributionChange?: string;
  themeChange?: string;
  addLink?: boolean;
  removeLink?: boolean;

  /**
   * Communication Settings
   */
  news?: boolean;
  unsubAll?: boolean;

  /**
   * Email
   */
  emailType?: string;

  /**
   * Moderator Actions
   */
  toggleModerator?: boolean;
  approveUser?: boolean;
  blockUser?: boolean

  /**
   * Page of results, any kind
   *
   * e.g. page 1 of search, page 2 of search
   */
  pagination?: number;

  /**
   *  Native sharing?, or using our dialog (prob desktop)
   */
  shareNavigator?: boolean;
  shareMedium?: 'facebook' | 'email' | 'copyLink' | 'flyer';
  shareTarget?: 'memorial' | 'memory';

  /**
   * Login
   */
  loginProvider?: string;
  loginSuccess?: boolean;
  emailSupplied?: boolean;
  nameSupplied?: boolean;
}
