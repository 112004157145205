import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class LoaderService {
  openLoader: EventEmitter<any> = new EventEmitter()

  open (message?, overlay?: boolean) {
    let value = {visible: true, message: '', overlay: (overlay?overlay:false)}
    if (message) {
      value.message = message;
    }
    
    this.openLoader.next(value);
  }

  close (noScroll?: boolean) {
    this.openLoader.next(false);
    
    if (!noScroll && typeof document !== 'undefined') {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }
}
