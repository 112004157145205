<div class="search-input-container">
  <div class="search" [class.white-background]="whiteBackground" [class.inline]="pageSelect">
    <input [formControl]="searchQuery" [placeholder]="placeholder" (focus)="searchFocused = true; searchFocus()"
      (blur)="searchBlur()" (keydown)="onKeydown($event)" aria-labelledby="searchLabel" role="searchbox" #searchInput />
    <button *ngIf="searchQuery.value.length > 0" class="icon icon-alt-close" role="img" (click)="searchClear()"
      role="button" aria-label="clear search input" tabindex="0" (keydown.enter)="searchClear()"
      (focus)="removePreviewFocus()"></button>
    <span id="searchLabel" class="icon icon-search" *ngIf="searchQuery.value.length === 0"><span
        class="cdk-visually-hidden">Search</span></span>
  </div>
  <div class="search-preview" *ngIf="searchQuery.value !== '' && prefetch && searchFocused">
    <div class="loader-wrapper" *ngIf="!doneLoading" role="progressbar" aria-label="Loading">
      <mem-loading [doneLoading]="doneLoading" [allowTimeout]="false"></mem-loading>
    </div>
    <div class="results" *ngIf="doneLoading" role="list">
      <div *ngIf="searchResult.memorials.length > 0" role="list">
        <div class="preview" role="list">
          <a class="preview-item" *ngFor="let page of searchResult.memorials;" (click)="pageClick(page)" tabindex="-1" #result focusElement>
            <mem-memorial-preview [memorial]="page" [small]="true"></mem-memorial-preview>
          </a>
        </div>
        <button class="view-all" *ngIf="!pageSelect" (click)="goToResults()" role="button" #result tabindex="-1"
          focusElement>
          View all results ({{searchResult.hits.found}})
        </button>
      </div>
      <div class="no-results" *ngIf="searchResult.memorials.length === 0">
        <div class="no-results-inner">
          <div class="circle">
            <span class="icon icon-person white" aria-hidden="true"></span>
          </div>
          <div class="no-results-text">
            Sorry, we didn’t find any pages
          </div>
          <div class="no-results-text">
            matching that search
          </div>
        </div>
        <button class="newPage" buttonIcon iconLeft="icon-page" [large]="true" (click)="createPage()" #result
          focusElement tabindex="-1">Start New
          Page</button>
      </div>
    </div>
  </div>
</div>