import { ButtonModule } from './../buttons/button.module';
import { TipLinkComponent } from './tip-link/tip-link.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TipComponent } from './tip.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule
  ],
  declarations: [
    TipComponent,
    TipLinkComponent
  ],
  exports: [
    TipComponent,
    TipLinkComponent
  ],
  providers: []
})

export class TipModule {
}
