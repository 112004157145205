import { ContentType } from './trackingOptions.interface';
import { forEach, isString } from 'lodash-es';
import { unescapeHtml } from '../helper/unescapeHtml';

/**
 * User type
 */
export enum UserType {
  guest = 'guest',
  moderator = 'moderator',
  member = 'member',
  visitor = 'visitor',
  siteAdmin = 'siteAdmin'
}

export class AutoTracking {

  constructor (data) {
    if (data) {
      forEach(data, (value, key) => {
        if (isString(value)) {
          value = unescapeHtml(value);
        }
        this[key] = value;
      });
    }
  }
  // auto
  // from object memorial
  memorialClaimable?: boolean;
  memorialId?: string;
  memorialLiving?: boolean;
  memorialSeoName: string;
  memorialGuestbookCount: number;
  memorialBadges: number;
  memorialTheme: string
  memorialMemoryCount: number;
  memorialModCount: number;
  memorialPrivateMemories: boolean;
  memorialGuestmodeDisabled: boolean
  memorialLinkCount: number;
  // from object user
  userType?: UserType;
  loggedIn?: boolean;
  editor?: boolean;
  // from object signature
  userRelationGroup?: string;
  userRelation?: string;
  userAcceptCookie?: string;
  userImage?: string;
  userCreatedAt?: string;
  // fom object memory
  memoryAnsweredQuestion?: string;
  memoryPrivacy?: string;
  memoryScore?: number;
  memoryLikesCount?: number;
  memoryMediaCount?: number;
  memoryCaption?: boolean;
  memoryCreatedAt?: string;

  contentType?: ContentType;

  appVersion?: string;
  guestContent?: boolean;
  siteAdmin?: string;
}
