<div id="footer-placeholder" #placeholder></div>
<div id="footer-container" #footerContainer>
  <div class="mainFooter pr-page-width-container">
    <a class="section logo" routerLink="/">
      <img src="https://assets.weremember.com/image/upload/f_auto/v1573676759/logoCombined_skssfy.png"
        alt="We Remember Logo" class="wrLogo">
    </a>
    <div class="section">
      <a class="link bold" routerLink="/" target="_blank" rel="noopener"><span class="icon icon-link"
          aria-hidden="true"></span>We Remember Home</a>
      <a class="link bold" routerLink="/search" target="_blank" rel="noopener"><span class="icon icon-link"
          aria-hidden="true"></span>Search Memorials</a>
      <a class="link bold" routerLink="/pages/recent" target="_blank" rel="noopener"><span class="icon icon-link"
          aria-hidden="true"></span>Browse Memorials</a>
      <a class="link bold" routerLink="/create-page" target="_blank" rel="noopener"><span class="icon icon-link"
          aria-hidden="true"></span>Create a Memorial Website</a>
      <a class="link bold" routerLink="/help-center" target="_blank" rel="noopener"><span class="icon icon-link"
          aria-hidden="true"></span>Help Center</a>
    </div>
    <div class="section">
      <a class="link" href="https://www.ancestry.com/s78053/t36925/rd.ashx" target="_blank" rel="noopener"><span
          class="icon icon-link" aria-hidden="true"></span>Corporate Information</a>
      <a class="link" routerLink="/privacy" target="_blank" rel="noopener"><span class="icon icon-link"
        aria-hidden="true"></span>Privacy Statement&nbsp;<span class="update-pill">Updated</span></a>
      <a class="link" routerLink="/terms" target="_blank" rel="noopener"><span class="icon icon-link"
          aria-hidden="true"></span>Terms and Conditions&nbsp;<span class="update-pill">Updated</span></a>
      <a class="link" routerLink="/cookies" target="_blank" rel="noopener"><span class="icon icon-link"
          aria-hidden="true"></span>Cookie Policy</a>
      <!-- <div id="privacy_link"></div> -->
      <div id="privacy_link" class="link"><span class="icon icon-link"></span></div>
      <a class="link" href="https://www.ancestry.com/cs/legal/privacystatement#personal-info-categories" target="_blank"
        rel="noopener"><span class="icon icon-link"></span>CCPA Notice at Collection</a>
      <a class="link" href="https://www.ancestry.com/cs/legal/community-rules" target="_blank" rel="noopener"><span
          class="icon icon-link" aria-hidden="true"></span>Community Rules</a>
      <!-- <a class="link" routerLink="/terms" target="_blank" rel="noopener"><span class="icon icon-link" aria-hidden="true"></span>Do Not Sell My Info</a> -->
    </div>
  </div>
  <div class="legal pr-page-width-container">
    <span class="copyright">&copy;1997-{{currentYear}} Ancestry. All rights reserved. </span>
  </div>
</div>