import { UnsubscribeOnDestroyAdapter } from './../../adapters/unsubscribe-adapter'
import { Component, HostBinding, ElementRef, ViewChild } from '@angular/core'
import { Subject } from 'rxjs'
import { CdkTrapFocus } from '@angular/cdk/a11y'

@Component({
  selector: 'mem-base-dialog',
  template: `<div class="base-dialog"></div>`,
})
export class BaseDialogComponent extends UnsubscribeOnDestroyAdapter {

  close: Subject<any> = new Subject();
  confirmClose: boolean = false;

  @ViewChild('dialogWrapper', { read: ElementRef }) dialogWrapper: ElementRef



  constructor () { super() }

}
