import { BaseDialogComponent } from './../../../shared/components/dialog/base-dialog.component'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal, ComponentType } from '@angular/cdk/portal'
import { ComponentRef, Injectable, InjectionToken, Injector } from "@angular/core"
import { iif, Observable, race, Subject } from 'rxjs'
import { finalize, map, take } from 'rxjs/operators'

interface CustomOverlayConfig {
  width?: string,
  topMargin?: string,
  disableClose?: boolean
  data?: any
  hasBackdrop?: boolean
  positionStrategy?
  panelClass?: string
}

const DEFAULT_CONFIG: CustomOverlayConfig = {
  width: '600px',
  topMargin: '12px',
  disableClose: false,
  hasBackdrop: true,
  panelClass: 'custom-overlay-class'
}

export const CUSTOM_OVERLAY_DATA = new InjectionToken<any>('CUSTOM_OVERLAY_DATA')

@Injectable()

export class CustomOverlayService {

  constructor (private overlay: Overlay) { }

  closeEvent: Subject<boolean> = new Subject();

  open (component: ComponentType<BaseDialogComponent>, config: CustomOverlayConfig): Observable<boolean | CustomOverlayRef> {
    const focusElement = document.activeElement as HTMLElement
    let selfClose = true
    const overlayConfig = { ...DEFAULT_CONFIG, ...config }

    const overlayRef = this.createOverlay(overlayConfig)

    const customRef = this.attachContainer(overlayRef, overlayConfig, component)

    if (typeof window !== 'undefined') {
      // if no autofocus focus on first button (probably close)
      setTimeout(() => {
        if (!customRef.componentRef.location.nativeElement.contains(document.activeElement)) {
          customRef.componentRef.location.nativeElement.querySelector('button').focus()
        }
      }, 0)
    }
    // set up backdrop click
    let overlayClose = overlayRef.backdropClick().pipe(
      map((event) => {
        // always close on backdrop click
        return false
      })
    )
    let componentClose: Observable<boolean | CustomOverlayRef> = customRef.componentRef.instance.close.pipe(
      map((action: string | boolean) => {
        if (action) {
          customRef.action = action
          selfClose = false
          return customRef
        } else {
          return false
        }
      })
    )
    // only listen to backdrop click if enabled, otherwise only listen to confirmation
    return iif(
      () => config.disableClose,
      race(componentClose, this.closeEvent),
      race(componentClose, overlayClose, this.closeEvent)
    ).pipe(
      take(1),
      finalize(() => {
        if (selfClose) {
          customRef.close()
        }
        focusElement.focus()
      })
    )
  }

  private createOverlay (config: CustomOverlayConfig): OverlayRef {
    config.positionStrategy = this.overlay.position().global().centerHorizontally().top(config.topMargin)
    return this.overlay.create(config)
  }

  private attachContainer (overlayRef: OverlayRef, config: CustomOverlayConfig, component: ComponentType<any>) {
    const customRef = new CustomOverlayRef(overlayRef)

    const injector = this.createInjector(config, customRef)

    const customOverlayPortal = new ComponentPortal(component, null, injector)

    const componentRef = overlayRef.attach(customOverlayPortal)
    customRef.componentRef = componentRef

    return customRef
  }

  private createInjector (config: CustomOverlayConfig, ref: CustomOverlayRef): Injector {

    return Injector.create({ providers: [{ provide: CUSTOM_OVERLAY_DATA, useValue: config.data }, { provide: CustomOverlayRef, useValue: ref }] })
  }
}


export class CustomOverlayRef {

  private _componentRef: ComponentRef<any>
  overlayRef: OverlayRef
  action: string | boolean

  set componentRef (componentRef: ComponentRef<any>) {
    this._componentRef = componentRef
  }

  get componentRef () {
    return this._componentRef
  }

  constructor (overlayRef: OverlayRef, componentRef?: ComponentRef<any>) {
    this.overlayRef = overlayRef
    if (componentRef) {
      this.componentRef = componentRef
    }
  }

  close (): void {
    this.overlayRef.dispose()
  }
}
