import { A11yModule } from '@angular/cdk/a11y'
import { AutosizeModule } from 'ngx-autosize'
import { SelectGroupComponent } from './select-group/select-group.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { ButtonModule } from '../buttons/button.module'
import { PersonModule } from '../person/person.module'
import { TipModule } from '../tip/tip.module'
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component'
import { InputBasicComponent } from './input-basic/input-basic.component'
import { InputButtonComponent } from './input-button/input-button.component'
import { ImagePreviewComponent } from './photo-preview/image-preview.component'
import { SelectBasicComponent } from './select-basic/select-basic.component'
import { TextAttributionComponent } from './text-attribution/text-attribution.component'
import { TextBasicComponent } from './text-basic/text-basic.component'
import { TypeAheadComponent } from './type-ahead/type-ahead.component'
import { VisibilityComponent } from './visibility/visibility.component'


@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatMenuModule,
    MatInputModule,
    PersonModule,
    ButtonModule,
    TipModule,
    MatSelectModule,
    MatAutocompleteModule,
    AutosizeModule,
    A11yModule
  ],
  declarations: [
    CheckboxInputComponent,
    InputBasicComponent,
    InputButtonComponent,
    TextBasicComponent,
    TextAttributionComponent,
    VisibilityComponent,
    SelectBasicComponent,
    SelectGroupComponent,
    ImagePreviewComponent,
    TypeAheadComponent
  ],
  exports: [
    CheckboxInputComponent,
    InputBasicComponent,
    InputButtonComponent,
    TextBasicComponent,
    TextAttributionComponent,
    VisibilityComponent,
    SelectBasicComponent,
    SelectGroupComponent,
    ImagePreviewComponent,
    TypeAheadComponent
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }
  ]
})

export class InputModule {
}
