import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class RaygunStubService {

  rg4js: any = function () { // eslint-disable-line prefer-arrow/prefer-arrow-functions
    console.log('raygunError: ', arguments);
  }

  constructor () {

  }

  init () {
    return of(null);
  }

  stringifyError (thrownError) {

    return 'stub';
  }

  sendError (error, customData?) {
    try {
      throw error;
    } catch (error) {

    }
  }
}
