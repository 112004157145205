import { Component, Input } from '@angular/core'
import { FormControlName, FormGroup } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'

@Component({
  selector: 'mem-input-basic',
  template: `
  <div [formGroup]="form">
    <div class="label" *ngIf="label">
      <div><ng-content></ng-content></div>
      <div class="show" *ngIf="hideable" [innerHTML]="showPassword ? 'Hide' : 'Show'" (click)="togglePassword()" tabindex="0"></div>
    </div>
    <mat-form-field floatLabel="never" appearance="outline" errorState="true" >
      <input matInput [id]="inputId || ''" placeholder="{{placeholder}}" [formControlName]="controlName" [errorStateMatcher]="errorStateMatcher" [type]="hideable && !showPassword ? 'password' : type" [readOnly]="isReadOnly" [maxLength]="limit" minLength="-999" [attr.aria-label]="ariaLabel">
      <mat-error>
      {{errorMessage}}
      </mat-error>
      </mat-form-field>
      <div class="limit" *ngIf="limit && limit !== 250">
        {{limit - form.get(controlName.toString()).value.length}} characters remaining
      </div>
  </div>
  `,
  styleUrls: [`./input-basic.component.scss`]
})
export class InputBasicComponent {
  @Input('placeholder') placeholder: string = '';
  @Input('ariaLabel') ariaLabel: string = '';
  @Input('errorMessage') errorMessage: string = '';
  @Input('form') form: FormGroup
  @Input('controlName') controlName: FormControlName
  @Input('erroStateMatcher') errorStateMatcher: ErrorStateMatcher
  @Input('hideable') hideable: boolean = false;
  @Input('label') label: boolean = true;
  @Input('type') type: string = 'text';
  @Input('limit') limit: number = 250;
  @Input() inputId: string
  @Input() isReadOnly: boolean = false;

  showPassword: boolean = false;

  togglePassword () {
    this.showPassword = !this.showPassword
  }

}
