import { Router } from '@angular/router';
import { BaseDialogComponent } from './../dialog/base-dialog.component';
import { CookieNoticeService } from './cookie-notice.service';
import { Component, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'mem-cookie-notice',
  templateUrl: './cookie-notice.component.html',
  styleUrls: ['./cookie-notice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CookieNoticeComponent extends BaseDialogComponent {

  storageSub: any;
  notice: boolean = false;

  constructor(public cookieNoticeService: CookieNoticeService, private router: Router) {
    super();
  }

  openManage() {
    this.router.navigate(['', { outlets: { modal: 'manage-cookies' } }]);
    this.close.next(true);
  }

}
