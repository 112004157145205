import { ThemeService } from './../../../services/theme/theme.service';
import { SignInButtonComponent } from './button-sign-in/sign-in-button.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../../directives/directives.module';
import { ButtonArticleComponent } from './button-article/button-article.component';
import { ButtonBoxGroupComponent } from './button-box-group/button-box-group.component';
import { ButtonBoxComponent } from './button-box/button-box.component';
import { ButtonCTAComponent, SaveCTAComponent } from './button-cta/button-cta.component';
import { ButtonIconComponent } from './button-icon/button-icon.component';
import { ButtonNavComponent } from './button-nav/button-nav.component';
import { ButtonStackComponent } from './button-stack/button-stack.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ButtonCTAComponent,
    SaveCTAComponent,
    ButtonIconComponent,
    ButtonBoxComponent,
    ButtonBoxGroupComponent,
    ButtonStackComponent,
    ButtonArticleComponent,
    ButtonNavComponent,
    SignInButtonComponent
  ],
  exports: [
    ButtonCTAComponent,
    SaveCTAComponent,
    ButtonIconComponent,
    ButtonBoxComponent,
    ButtonBoxGroupComponent,
    ButtonStackComponent,
    ButtonArticleComponent,
    ButtonNavComponent,
    SignInButtonComponent
  ],
  providers: [
    ThemeService
  ]
})

export class ButtonModule {
}
