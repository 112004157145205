<div class="confirmation" [attr.aria-expanded]="true" cdkTrapFocus cdkTrapFocusAutoCapture>
  <div class="confirmation-wrapper">
    <div class="content">
      <h3>{{title}}</h3>
      <p>{{description}}</p>
      <ng-content></ng-content>
    </div>
    <div class="buttons">
      <mem-button-cta class="button" (click)="buttonClick(true);" [showSpinner]="submitting">
        <span *ngIf="!submitting">{{actionText}}</span>
      </mem-button-cta>
      <mem-button-cta class="button" (click)="buttonClick(false);" cdkFocusInitial>
        {{cancelText}}
      </mem-button-cta>
    </div>
  </div>
</div>