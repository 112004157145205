<mem-section-stack #stackRef [modal]="true" [closeConfirmation]="false">
  <mem-named-stack-step name="landing">
    <mem-section-stack-header></mem-section-stack-header>
    <div class="sub-heading">More people make better memorials—share this page wherever you can</div>
    <div class="share-options">
      <mem-button-box icon="icon-facebook"
        (click)="shareService.shareViaFacebook(shareForm.controls.shareUrl.value, this.tracking);close.next(null);">
        SHARE ON
        FACEBOOK</mem-button-box>
      <mem-button-box icon="icon-link-alt" (click)="stackRef.openNamed('link')">SHARE A LINK</mem-button-box>
    </div>
    <div class="share-options">
      <mem-button-box icon="icon-email" *ngIf="showEmail" (click)="stackRef.openNamed('email')">SEND ME AN EMAIL TO
        FORWARD</mem-button-box>
      <a [routerLink]="['/flyer/active', memorialId]" (click)="trackFlyer()" [queryParams]="{visit: 'true'}"
        target="_blank" rel="noopener">
        <mem-button-box icon="icon-print">PRINT A FLYER</mem-button-box>
      </a>
    </div>
  </mem-named-stack-step>
  <mem-named-stack-step name="email">
    <mem-section-stack-header></mem-section-stack-header>
    <div class="share-prompt">
      <div class="sub-heading">Enter your email address and we’ll send you an email you can forward to family & friends
      </div>
      <mem-input-basic [label]="false" [form]="shareForm" placeholder="name@example.com..." controlName="forwardEmail"
        style="margin-bottom: 24px"></mem-input-basic>
      <mem-button-cta [large]="true" (click)="shareViaEmail()" [showSpinner]="sending">SEND EMAIL</mem-button-cta>
    </div>
  </mem-named-stack-step>
  <mem-named-stack-step name="link">
    <mem-section-stack-header></mem-section-stack-header>
    <div class="sub-heading">Copy this link and paste into an email or messaging app</div>
    <mem-input-basic [label]="false" [inputId]="'shareUrl'" [form]="shareForm" [isReadOnly]="true"
      controlName="shareUrl" (click)="copyTextToClipboard()" style="margin-bottom: 24px"></mem-input-basic>
    <mem-button-cta [large]="true" (click)="copyTextToClipboard()">{{ isCopied ? 'COPIED!' : 'COPY LINK'}}
    </mem-button-cta>
  </mem-named-stack-step>
</mem-section-stack>