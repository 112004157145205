export const environment = {
  ads: true,
  ancestryRegsiterLink: 'https://www.ancestry.com/account/create?returnUrl=https%3A%2F%2Fwww.ancestrystage.com%2Fsso%2Foidc%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3Db49f3cb3028768fd01bada63f50f3463d587e166%26scope%3Dopenid%26redirect_uri%3Dhttps%253A%252F%252Fwww.weremember.com%252Fcallback%26extSite%3DWe%2520Remember%26hideHeaderFooter%3D1&extSite=We%20Remember&hideHeaderFooter=1',
  ancestrySignInLink: 'https://www.ancestry.com/sso/oidc/authorize?response_type=code&client_id=b49f3cb3028768fd01bada63f50f3463d587e166&scope=openid&redirect_uri=https://www.weremember.com/callback',
  apiUrl: 'https://api.weremember.com',
  imageUrl: 'https://d29tmzbyvxp3pr.cloudfront.net/',
  s3Url: 'https://d29tmzbyvxp3pr.cloudfront.net/',
  cloudinaryUrl: 'https://assets.weremember.com/image/upload/',
  cloudinaryApiKey: '733687519221571',
  cloudinaryFolder: '/memorials/',
  appleClientId: 'com.ancestry.werememberlogin',
  appleRedirectUrl: 'https://temp.weremember.com/callback',
  facebookAppId: '1283240661725293',
  googleClientId: '681022278164-or4kt7c1tprvdhtnspni3brm8tdorn9u.apps.googleusercontent.com',
  production: false,
  isServer: false,
  hmr: false,
  cookieDomain: '.weremember.com',
  siteUrl: 'https://www.weremember.com',
  raygunKey: 'NmNrfGXdl0BcnoY4imqk6g==',
  gaTrackingId: 'UA-60272843-14',
  splitIO: 'crcijioddo0llov4ji2q7db44gdor7ros4u6',
  saveToAncestryScript: 'https://www.ancestry.com/savetoancestry/javascript/saveToAncestryApi.js'
}
