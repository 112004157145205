import { ThemeService } from './../../../../services/theme/theme.service'
import { UnsubscribeOnDestroyAdapter } from './../../../adapters/unsubscribe-adapter'
import { Subscription } from 'rxjs'
import { FormGroup } from '@angular/forms'
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AnalyticsService } from './../../../../services/analytics/analytics.service'

@Component({
  selector: 'mem-button-cta',
  template: `
      <button [type]="type" [disabled]="disabled" [spinner]="showSpinner" [class.outline]="outline" [class.gold]="themeService.gold" [class.large]="large" [class.shadow]="shadow" [class.medium]="medium" [class.small]="small"[style.width]="large ? '100%' : buttonWidth + 'px'" [style.background-color]="color">
          <span class="icon left {{iconLeft}}" *ngIf="iconLeft" aria-hidden="true"></span>
          <span #content style="display: flex; align-items: center">
            <ng-content *ngIf="(!showMobile && !showSpinner) || ((showMobile && !mobile) && !showSpinner)"></ng-content>
            <ng-container *ngIf="showMobile && mobile && !showSpinner">{{mobile}}</ng-container>
            <i *ngIf="showSpinner" class="icon spinner icon-loading"></i>
          </span>
          <span class="icon right {{iconRight}}" [class.gold]="themeService.gold" *ngIf="iconRight" aria-hidden="true"></span>
      </button>
  `,
  styleUrls: [`./button-cta.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonCTAComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
  @Input('outline') outline: boolean = true;
  @Input('iconLeft') iconLeft: string = '';
  @Input('iconRight') iconRight: string = '';
  @Input('disabled') disabled: boolean = false;
  @Input('large') large: boolean = false;
  @Input() shadow: boolean = false;
  @Input() medium: boolean = false;
  @Input() small: boolean = false;
  @Input('type') type: string = 'button';
  @Input() showSpinner: boolean = false;
  @Input() mobile: string = '';
  @Input() width: string = '160'
  @Input() mobileWidth: string = '120'
  @Input() color = 'var(--primary)'
  @Input() form: FormGroup
  gold: boolean = false;

  @ViewChild('content') ctaContent: ElementRef

  buttonWidth: string
  showMobile = false;

  subscriptions: Subscription[] = [];

  constructor (
    private breakpointObserver: BreakpointObserver,
    private changeDetection: ChangeDetectorRef,
    private element: ElementRef,
    public themeService: ThemeService) {
    super()
  }

  ngOnInit () {
    // only run this breakpoint observer if mobile text override is included
    this.subs.sink = this.breakpointObserver.observe(['(max-width: 767px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          if (this.mobile) {
            this.showMobile = state.matches
          }
          this.buttonWidth = this.mobileWidth
        } else {
          this.buttonWidth = this.width
        }
        this.changeDetection.markForCheck()
      })
    if (this.form) {
      this.subs.sink = this.form.statusChanges.subscribe((value: string) => {
        this.disabled = (value !== 'VALID')
        this.changeDetection.detectChanges()
      })
    }
  }

  ngAfterViewInit () {
    if (typeof window !== 'undefined') {
      const children = this.element.nativeElement.querySelectorAll('span')
      children.forEach((el: Element) => {
        el.addEventListener('click', (event) => {
          if (this.disabled) {
            event.stopPropagation()
            event.preventDefault()
          }
        })
      })
    }
  }
}

@Component({
  selector: 'mem-save-cta',
  template: `
    <div class="save-cta">
      <mem-button-cta mem-sign-in-button location="save-cta" [showSpinner]="showSpinner" [disabled]="disabled" (finished)="saveEvent.emit($event)" [step]="step" width="120">SAVE</mem-button-cta>
      <button type="button" class="cancel-button" (click)="cancelEvent.emit($event);">CANCEL</button>
    </div>
  `,
  styleUrls: [`./button-cta.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveCTAComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input() showSpinner: boolean = false;
  @Input() step: string
  @Input() actionCTA: string = 'SAVE';
  @Output() saveEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();

  ngOnInit () {

  }

  constructor (private analyticsService: AnalyticsService, private route: ActivatedRoute) {

  }

}
