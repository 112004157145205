import * as moment from 'moment';

const STRING_DATE = 'YYYY-MM-DD';
const RFC2822 = 'ddd, DD MMM YYYY HH:mm:ss ZZ';
const WRRFC = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ';

export const dateToPieces = (date) =>  {
  if (!date) return {};

  let yearDate;

  yearDate = moment(date, ['YYYY', STRING_DATE, WRRFC, RFC2822, moment.RFC_2822, moment.ISO_8601]);
  if (yearDate) {
    return {
      day: yearDate.format('DD'),
      month: yearDate.format('MM'),
      year: yearDate.format('YYYY')
    };
  } else {
    yearDate = moment(date);
    if (yearDate) {
      return {
        day: yearDate.format('DD'),
        month: yearDate.format('MM'),
        year: yearDate.format('YYYY')
      };
    } else {
      return {};
    }
  }
}

export const dateToYear = (date) => {
  if (!date) return '';
  let yearDate;
  yearDate = moment(date, ['YYYY', STRING_DATE, WRRFC, RFC2822, moment.RFC_2822, moment.ISO_8601]);
  if (yearDate) {
    return yearDate.year();
  } else {
    yearDate = moment(date);
    return yearDate.year();
  }
};
