import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'
import { Params, Route, ActivatedRoute, Router } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { TrackingName } from './../../../models/tracking/trackingEvent.model'
import { AnalyticsService } from './../../../services/analytics/analytics.service'
import { HelperService } from './../../../services/helper/helper.service'
import { MemorialStore } from './../../../services/memorial.store'
import { SignInService } from './../../../services/sign-in/sign-in.service'
import { UserStore, UserUpdateObject } from './../../../services/user.store'
import { UnsubscribeOnDestroyAdapter } from './../../adapters/unsubscribe-adapter'
import { CookieNoticeService } from './../cookie-notice/cookie-notice.service'

@Component({
  selector: 'mem-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  animateHeader: boolean = false;
  deanimateHeader: boolean = false;
  offMemorialScroll: boolean = false;
  name: string = '';
  userProfileImage: boolean = false;
  loggedIn: boolean = false;
  logoName: string = 'We Remember';
  targetBlank: boolean = true;
  screenSize: string = 'desktop';
  personSize: string = 'menu';
  demoMode: boolean = false;
  searchButton: boolean = true;
  searchAutoFocus: boolean = false;

  authDone: boolean = false;
  showSearch: boolean = false;

  version: any = {};

  constructor (
    private signInService: SignInService,
    private helperService: HelperService,
    private analyticsService: AnalyticsService,
    public store: MemorialStore,
    public userStore: UserStore,
    public cns: CookieNoticeService,
    public cookieNoticeService: CookieNoticeService,
    private componentRef: ChangeDetectorRef,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super()
  }

  ngOnInit () {
    this.determineScreenSize()

    //if dev
    if (!environment.production) {
      this.version = this.helperService.version
    }

    this.subs.sink = this.store.statusObservable.subscribe((status) => {
      if (status) {
        this.setupVariables()
        this.componentRef.detectChanges()
      } else {
        this.demoMode = false
      }
    })

    this.subs.sink = this.userStore.userObservable.subscribe((uuo: UserUpdateObject) => {
      this.setupVariables()
      this.componentRef.detectChanges()
    }, err => {
      console.error(err)
    })

    if (typeof window !== 'undefined' && window.location.pathname === '/not-found') {
      this.targetBlank = false
    }

    // if home or search result page open search bar with no focus. (Placeholder needed for UX)
    if (window.location.pathname === '/') this.toggleSearch({ focus: false })
    this.subs.sink = this.route.queryParams.subscribe((params: Params) => {
      if (params['q']) this.toggleSearch({ focus: false })
    })
  }

  setupVariables () {
    if (this.store.memorial) {
      this.demoMode = (this.store.memorial.status === 'demo')
    } else {
      this.demoMode = false
    }
  }

  signOut () {
    this.subs.sink = this.signInService.logout().subscribe(resp => {
    }, err => {
      console.error(err)
    })
  }

  determineScreenSize () {
    let w = 766
    if (typeof window !== 'undefined') w = window.innerWidth
    if ((w > 766) && (w < 1199)) {
      this.screenSize = 'tablet'
    } else if (w < 767) {
      this.screenSize = 'phone'
      this.personSize = 'medium'
    }
  }

  preventDefault ($event) {
    $event.preventDefault()
  }

  toggleSearch (options: { focus: boolean }) {
    this.searchAutoFocus = options.focus
    this.showSearch = !this.showSearch
    this.helperService.scrollTop(0)
  }

  trackMyPages () {
    this.analyticsService.sendTrackingEvent(TrackingName.Click, {
      uiName: 'My Pages',
      buttonLocation: 'Header'
    })
  }

  trackEditProfile () {
    this.analyticsService.sendTrackingEvent(TrackingName.Click, {
      uiName: 'Edit Profile',
      buttonLocation: 'Header'
    })
  }
}
