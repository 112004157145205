import { A11yModule } from '@angular/cdk/a11y'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ButtonModule } from './../buttons/button.module'
import { NamedStackStepComponent, SectionStackComponent, SectionStackHeaderComponent, StackStepComponent } from './section-stack.component'

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    A11yModule
  ],
  declarations: [
    SectionStackComponent,
    SectionStackHeaderComponent,
    StackStepComponent,
    NamedStackStepComponent
  ],
  exports: [
    SectionStackComponent,
    SectionStackHeaderComponent,
    StackStepComponent,
    NamedStackStepComponent
  ],
  providers: [
  ]
})

export class SectionStackModule {
}
