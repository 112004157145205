import { coerceNumberProperty } from '@angular/cdk/coercion'
import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, ContentChildren, EventEmitter, forwardRef, HostBinding, Inject, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ElementRef } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'
import { BehaviorSubject } from 'rxjs'
import { CustomOverlayService } from './../../../routes/memorial/services/custom-overlay.service'
import { HelperService } from './../../../services/helper/helper.service'
import { ConfirmationSectionStackComponent } from './../confirmation/confirmation.component'

@Component({
  selector: 'mem-section-stack-header',
  templateUrl: './header/section-stack-header.component.html',
  styleUrls: ['./header/section-stack-header.component.scss']
})
export class SectionStackHeaderComponent {

  @Input() title: string
  @Input() hideClose: boolean = false;
  @Input() hideBack: boolean = false;
  @Input() forceCancel: boolean = false;
  @Input('form') form: FormGroup = new FormGroup({});
  @Input() parentOwnsBack: boolean = false;
  @Output() headerBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.active') active: boolean = false;
  @ViewChild(TemplateRef) content: TemplateRef<any>

  constructor (@Inject(forwardRef(() => SectionStackComponent)) private sectionStack: SectionStackComponent,
    private customOverlayService: CustomOverlayService) {
  }

  back () {
    if (this.parentOwnsBack) {
      this.headerBack.emit(true)
    } else {
      if (this.sectionStack.history.length > 1) {
        if (this.sectionStack.namedActive) {
          let prevstep = this.sectionStack.history[this.sectionStack.history.length - 2]
          if (prevstep.length > 1) {
            this.sectionStack.openNamed(this.sectionStack.history[this.sectionStack.history.length - 2])
            this.sectionStack.history.pop()
          } else {
            this.sectionStack.previous()
          }
          this.sectionStack.namedActive = ''
        } else {
          this.sectionStack.previous()
        }
      } else {
        this.sectionStack.deactivate()
      }
    }
  }

  close () {
    this.sectionStack.deactivate()
  }
}

@Component({
  selector: 'mem-stack-step',
  templateUrl: './step/section-stack-step.component.html',
  styleUrls: ['./step/section-stack-step.component.scss']
})

export class StackStepComponent {
  interacted = false;
  @ViewChild(TemplateRef) content: TemplateRef<any>
  @ContentChild(SectionStackHeaderComponent, { static: false }) _header: QueryList<SectionStackHeaderComponent>

  @Output('close') close = new EventEmitter();

  get header (): QueryList<SectionStackHeaderComponent> {
    return this._header
  }

  @Input('noPadding') noPadding: boolean
}

@Component({
  selector: 'mem-named-stack-step',
  templateUrl: './step/section-stack-step.component.html',
  styleUrls: ['./step/section-stack-step.component.scss']
})

export class NamedStackStepComponent {
  interacted = false;
  @ViewChild(TemplateRef) content: TemplateRef<any>
  @ContentChild(SectionStackHeaderComponent, { static: false }) _header: QueryList<SectionStackHeaderComponent>

  get header (): QueryList<SectionStackHeaderComponent> {
    return this._header
  }

  @Input() name: string

  @Input('noPadding') noPadding: boolean

  @Input() confirmationTitle: string
  @Input() confirmationDescription: string

}


@Component({
  selector: 'mem-section-stack',
  templateUrl: './section-stack.component.html',
  styleUrls: ['./section-stack.component.scss'],
  exportAs: 'sectionStack',
})
export class SectionStackComponent implements OnInit, AfterViewInit {

  @ContentChildren(StackStepComponent) _steps: QueryList<StackStepComponent>
  @ContentChildren(NamedStackStepComponent) _namedSteps: QueryList<NamedStackStepComponent>
  @ContentChildren(ConfirmationSectionStackComponent) _confirmations: QueryList<ConfirmationSectionStackComponent>

  @Input('form') form: FormGroup
  @Input('modal') modal: boolean
  @Input('modalId') modalId: string
  @Input('closeConfirmation') closeConfirmation: boolean = true;

  get confirmations (): QueryList<ConfirmationSectionStackComponent> {
    return this._confirmations
  }

  get steps (): QueryList<StackStepComponent> {
    return this._steps
  }

  get namedSteps (): QueryList<NamedStackStepComponent> {
    return this._namedSteps
  }

  @Input()
  get selectedIndex () { return this._selectedIndex }
  set selectedIndex (index: number) {
    const newIndex = coerceNumberProperty(index)
    this._selectedIndex = newIndex
  }

  private _selectedIndex = 0;

  public _active = false;
  public activeObservable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get active (): boolean {
    return this._active
  }

  set active (active: boolean) {
    this.activeObservable.next(active)
    this._active = active
  }

  history: Array<any> = [];
  activeIndex: number = 0;
  namedActive: string = '';
  confirmationActive: boolean = false;
  confirmationContext: any = {
    descriptionOverwrite: '',
    titleOverwrite: ''
  };
  submitting: boolean = false;

  constructor (
    private helperService: HelperService,
    private cdr: ChangeDetectorRef,
    private customOverlayService: CustomOverlayService,
    private el: ElementRef) {
  }

  ngOnInit () {
    if (this.modal) {
      this.activate()
      this.active = true
    }

    if (!this.namedActive) {
      this.history.push(0)
    }
  }

  ngAfterViewInit () {
    this.cdr.detectChanges()
  }

  activate () {
    this.active = true
  }

  deactivate (force?: boolean) {
    if (this.closeConfirmation) {
      if (this.form) {
        if (this.form.dirty) {
          if (force) {
            this.closeNamed()
            this.active = false
            this.activeObservable.next(false)
            this.closeConfirm()
            this.resetForm()
            // also close the dialog
            if (this.modal && this.modalId) {
              this.customOverlayService.closeEvent.next(null)
            } else {
              this.customOverlayService.closeEvent.next(null)
            }
          } else {
            this.openConfirm()
          }
        } else {
          // also close the dialog
          if (this.modal && this.modalId) {
            this.customOverlayService.closeEvent.next(null)
          } else {
            this.customOverlayService.closeEvent.next(null)
          }
          this.active = false
          this.activeObservable.next(false)
        }
      }
    } else {
      // if the confirmation has been overridden. ie.
      if (this.modal && this.modalId) {
        this.customOverlayService.closeEvent.next(null)
      } else {
        this.customOverlayService.closeEvent.next(null)
      }
    }
    this.cdr.detectChanges()
  }

  resetForm () {
    this.form.reset()
    this.cleanFormArrays(this.form)
  }

  closeNamed () {
    this.history.pop()
    this.namedActive = ''
  }

  openNamed (name: string) {
    this.history.push(name)
    this.namedActive = name
    this.active = true
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        if (!this.el.nativeElement.contains(document.activeElement)) {
          this.el.nativeElement.querySelectorAll('[aria-expanded=true]')[0].querySelectorAll('button')[0].focus()
        }
      })
    }
  }

  confirm () {
    this.restart()
    this.deactivate(true)
  }

  openConfirm () {
    if (this.namedActive) {
      //check if the active step has overwritten confirmation
      let activeStep = this.namedSteps.filter((item) => {
        return item.name == this.namedActive
      })[0]
      this.confirmationContext.titleOverwrite = activeStep.confirmationTitle
      this.confirmationContext.descriptionOverwrite = (activeStep.confirmationDescription ? activeStep.confirmationDescription : '')
      this.cdr.detectChanges()
    }
    this.confirmationActive = true
  }

  closeConfirm () {
    this.confirmationActive = false
  }

  restart () {
    this.submitting = false
    this.selectedIndex = 0
  }

  next () {
    this.selectedIndex = Math.min(this._selectedIndex + 1, this.steps.length - 1)
    this.history.push(this.selectedIndex)
  }

  previous () {
    this.selectedIndex = Math.max(this._selectedIndex - 1, 0)
    this.history.pop()
  }

  cleanFormArrays (group: FormGroup) {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key]
      if (abstractControl instanceof FormGroup) {
        this.cleanFormArrays(abstractControl)
      } else if (abstractControl instanceof FormArray) {
        while (abstractControl.length) {
          abstractControl.removeAt(0)
        }
      }
    })
  }

}
