import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShareModule } from './components/share/share.module';
import { AnalyticsResolver } from './resolvers/analytics.resolver';


const routes: Routes = [
  {
    path: 'manage-cookies',
    loadChildren: () => import('../routes/manage-cookies/manage-cookies.module').then(m => m.ManageCookiesModule),
    outlet: 'modal',
    resolve: {
      analytics: AnalyticsResolver,
    },
    data: {
      name: 'Manage Cookies',
      title: 'Manage Cookies',
      description: 'Manage your cookie preferences for WeRemember.com',
      nofollow: true
    }
  }
];

@NgModule({
  imports: [
    ShareModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class SharedRoutesModule { }
