import { BaseDialogComponent } from './../dialog/base-dialog.component';
import { CUSTOM_OVERLAY_DATA } from './../../../routes/memorial/services/custom-overlay.service';
import { TrackingName } from './../../../models/tracking/trackingEvent.model';
import { TrackingOptions } from './../../../models/tracking/trackingOptions.interface';
import { tap } from 'rxjs/operators';
import { ShareService } from './../../../services/share/share.service';
import { ToastService } from './../../../services/toast/toast.service';
import { FormGroup, FormControl } from '@angular/forms';
import { SectionStackComponent } from './../section-stack/section-stack.component';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { forEach as _forEach } from 'lodash-es';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { EmailService } from "../../../services/email/email.service";
import { MemorialStore } from './../../../services/memorial.store';
import { UserStore, UserUpdateObject } from './../../../services/user.store';

@Component({
  selector: 'mem-share',
  templateUrl: './share.dialog.component.html',
  styleUrls: ['./share.dialog.component.scss']
})
export class ShareDialogComponent extends BaseDialogComponent implements OnInit {

  @Input() type: string = 'default'; // can be either ne "inline" or null
  @Input() modal: boolean = false;
  @Input() text: string;
  @Input() headerText: string;
  @Input() demoMode: boolean;
  @Input() shareUrl: string = '';

  @ViewChild('stackRef', { static: true }) public stackRef: SectionStackComponent;

  shareForm: FormGroup = new FormGroup({
    shareUrl: new FormControl(''),
    forwardEmail: new FormControl('')
  })


  isCopied: boolean = false;
  user: any = {};
  loggedIn: boolean = false;
  forwardEmail: string = '';
  contentId: string = '';
  showEmail: boolean = false;
  sending: boolean = false;
  memorialId: string = '';

  tracking: TrackingOptions = {
    uiType: 'dialog',
    parentComponentType: 'share'
  }

  constructor (
    private emailService: EmailService,
    private analyticsService: AnalyticsService,
    private store: MemorialStore,
    public userStore: UserStore,
    private toastService: ToastService,
    public shareService: ShareService,
    @Inject(CUSTOM_OVERLAY_DATA) public data: any
  ) {
    super();
  }

  ngOnInit () {

    this.subs.sink = this.userStore.userObservable.subscribe((uuo: UserUpdateObject) => {
      if (uuo.loggedIn) {
        this.user = uuo.user;
        this.loggedIn = true;
        this.shareForm.controls.forwardEmail.setValue(this.user.email);
      } else {
        this.loggedIn = false;
        this.user = null;
      }
    })

    if (this.data && this.data.step) {
      this.stackRef.openNamed(this.data.step)
    }

    this.shareForm.controls.shareUrl.setValue(this.data.url || null);

    this.showEmail = this.data.showEmail || false;


    this.memorialId = this.store.memorial.id;

    this.subs.sink = this.store.statusObservable.subscribe((status) => {
      if (status) {
        if (!this.shareForm.controls.shareUrl.value && typeof window !== 'undefined') {
          this.shareForm.controls.shareUrl.setValue(window.location.hostname.toString() + window.location.pathname.toString());
        }
        if (status.memorialStatus == 'demo') {
          this.demoMode = true;
        }
      }
    }, err => {
      console.error(err);
    });
  }

  ngOnDestroy () {
    super.ngOnDestroy();
    this.shareForm.controls.shareUrl.setValue('');
  }

  shareViaEmail () {
    if (!this.demoMode && !this.sending) {
      this.sending = true;

      if (this.shareForm.controls.forwardEmail.value) {
        this.subs.sink = this.emailService.shareMemorial(this.modal, this.shareForm.controls.forwardEmail.value)
          .subscribe((result) => {
            this.toastService.openToast('Email sent! Check your inbox.', ['green']);
            this.sending = false;
            this.close.next(null);
          }, err => {
            console.error(err);
          }, () => {
            this.analyticsService.sendTrackingEvent(TrackingName.Share, {
              ...this.tracking,
              shareMedium: 'email'
            });
          });
      }
    }
  }

  copyTextToClipboard () {
    if (typeof window !== 'undefined') {
      let input = <HTMLInputElement>document.getElementById('shareUrl');
      input.focus();
      input.select();
      try {
        let successful = document.execCommand('copy');
        let msg = successful ? 'successful' : 'unsuccessful';
        this.isCopied = true;
        console.log('Fallback: Copying text command was ' + msg);
        this.analyticsService.sendTrackingEvent(TrackingName.Share, {
          ...this.tracking,
          shareMedium: 'copyLink'
        })
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
    }
  }

  trackFlyer () {
    this.analyticsService.sendTrackingEvent(TrackingName.Share, {
      ...this.tracking,
      shareMedium: 'flyer'
    })
  }
}
