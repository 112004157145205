import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PersonComponent } from './person.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PersonComponent
  ],
  exports: [
    PersonComponent
  ],
  providers: []
})

export class PersonModule {
}
