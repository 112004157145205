import { Component, Inject, OnInit } from '@angular/core';
import { TrackingName } from './../../../models/tracking/trackingEvent.model';
import { CUSTOM_OVERLAY_DATA } from './../../../routes/memorial/services/custom-overlay.service';
import { AnalyticsService } from './../../../services/analytics/analytics.service';
import { BaseDialogComponent } from './../dialog/base-dialog.component';

@Component({
  selector: 'mat-message-dialog',
  templateUrl: 'message-dialog.component.html',
  styleUrls: ['message-dialog.component.scss']
})
export class MessageDialogComponent extends BaseDialogComponent implements OnInit {

  constructor (
    private analyticsService: AnalyticsService,
    @Inject(CUSTOM_OVERLAY_DATA) public data: any) {
    super();
  }

  ngOnInit () {
    this.analyticsService.sendTrackingEvent(TrackingName.ViewUI, {
      uiType: 'message',
      uiName: this.data.ref,
    });
  }

  closePortal (action: boolean = true) {
    if (action && this.data?.externalUrl) {
      this.analyticsService.sendTrackingEvent(TrackingName.NavigateExternal, {
        externalUrl: this.data.externalUrl
      })
    } else {
      this.analyticsService.sendTrackingEvent(TrackingName.Click, {
        uiType: 'message',
        uiName: this.data.ref
      })
    }

    this.close.next(action ? true : null);
  }
}
