import { UserStore } from './../../../../services/user.store'
import { MemorialStore } from './../../../../services/memorial.store'
import { Component, Input } from '@angular/core'

@Component({
  selector: '[mem-input-button]',
  template: `
      <ng-container *ngIf="attribution">
        <div class="no-signature" *ngIf="!userStore.user"><span class="icon icon-person"></span></div>
        <mem-person *ngIf="userStore.user" [signature]="userStore.user" size="small" text="false"></mem-person>
      </ng-container>
      <span [class.attribution]="attribution" [style.height]="height + 'px'">{{placeholder}}</span>
      <span class="hover-outline" [style.height]="height + 'px'"></span>
  `,
  styleUrls: [`./input-button.component.scss`]
})
export class InputButtonComponent {
  @Input('placeholder') placeholder: string = '';
  @Input('attribution') attribution: boolean = false;
  @Input() height: number = 40;


  constructor (public userStore: UserStore) {

  }

}
