import { Injectable } from '@angular/core';
import { from, of, Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { TrackingName } from './../../models/tracking/trackingEvent.model';
import { ContentType, TrackingOptions } from './../../models/tracking/trackingOptions.interface';
import { CustomOverlayRef, CustomOverlayService } from './../../routes/memorial/services/custom-overlay.service';
import { ShareDialogComponent } from './../../shared/components/share/share.dialog.component';
import { AnalyticsService } from './../analytics/analytics.service';
import { MemorialStore } from './../memorial.store';
import { RaygunService } from './../raygun/raygun.service';

export interface ShareObject {
  title?: string;
  text?: string;
  url?: string;
  files?: any[];
  tracking?: TrackingOptions;
  dialogStep?: 'link' | 'email'
}

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  demoMode: boolean = false;
  webNavigator: any;

  constructor (private analyticsService: AnalyticsService,
    private store: MemorialStore,
    private overlay: CustomOverlayService,
    private raygun: RaygunService) {
    this.demoMode = this.store?.memorial?.status === 'demo';
    if (typeof window !== 'undefined') {
      this.webNavigator = window.navigator as any;
    }
  }

  share (options: ShareObject): Observable<any> {
    this.analyticsService.sendTrackingEvent(TrackingName.ViewUI, {
      contentType: ContentType.memory,
      uiName: 'share',
      uiType: 'dialog',
    });
    if (!options.url) {
      let url = window.location.href;
      if (url.indexOf('?') > -1) {
        url = url.substring(0, url.indexOf('?'));
      }
      options.url = url + '?utm_campaign=memorial_share';
    }
    if (this.canNavigatorShare()) {
      return this.navigatorShare(options)
    } else {
      return this.overlay.open(ShareDialogComponent, {
        data: {
          url: options.url,
          step: (options.dialogStep ? options.dialogStep : 'landing')
        }
      }).pipe(
        tap(ref => {
          if (ref instanceof CustomOverlayRef) {
            ref.close();
          }
        })
      )
    }
  }

  canNavigatorShare () {
    if (typeof window !== 'undefined') {
      return this.webNavigator !== null && this.webNavigator.share !== undefined;
    }
    return false;
  }

  navigatorShare (options: ShareObject) {
    return from(this.webNavigator.share(options)).pipe(
      tap((res) => {
        this.analyticsService.sendTrackingEvent(TrackingName.Share, {
          shareNavigator: true,
          ...options.tracking
        })
      }),
      catchError((err: Error) => {
        switch (err.name) {
          case 'AbortError':
            this.analyticsService.sendTrackingEvent(TrackingName.AbandonShare, {
              shareNavigator: true,
              ...options.tracking
            })
            return of(err);
          default:
            this.raygun.sendError(new Error('error with navigator share'), err);
            throw 'unexpected error when sharing, please refresh and try again';
        }
      }));
  }

  //TODO we need to make sure these are hooked up properly later with the right credentials.
  shareViaFacebook (url?: string, tracking?: TrackingOptions) {
    if (!this.demoMode) {
      this.analyticsService.sendTrackingEvent(TrackingName.Share, {
        shareMedium: 'facebook',
        ...tracking
      })
      if (!url) {
        url = window?.location.href;
      }
      this.PopupCenter('https://www.facebook.com/sharer/sharer.php?s=100&u=' + url + '?utm_campaign=facebook&utm_source=facebook', 'Share Memorial', 555, 640);

    }
  }

  // always pop up facebook popup in center of window, even with multiple monitors.
  PopupCenter (url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox
    let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    let dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

    let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    let left = ((width / 2) - (w / 2)) + dualScreenLeft;
    let top = ((height / 2) - (h / 2)) + dualScreenTop;
    let newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) {
      if (newWindow) {
        newWindow.focus();
      }
    }
  }
}
