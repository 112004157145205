import { AdminGuard } from './services/admin.guard';
import { AnalyticsResolver } from './shared/resolvers/analytics.resolver';
import { AuthGuard } from './services/auth.guard';
import { CanonicalResolver } from './shared/resolvers/canonical.resolver';
import { SeoResolver } from './shared/resolvers/seo.resolver';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth/auth.service';

@Injectable()
export class AuthResolver implements Resolve<any> {

  constructor (private authService: AuthService) { }

  resolve (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.authService.checkToken();
  }
}

export const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./routes/landing-page/landing-page.module').then(m => m.LandingPageModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver,
      canonical: CanonicalResolver
    },
    data: {
      name: 'Landing Page',
      title: 'We Remember : Free online memorials and obituaries',
      description: 'Remember and celebrate loved ones by bringing together friends and family.  Collect memories and photos to paint a rich picture of their life.'
    }
  },
  {
    path: 'pages',
    loadChildren: () => import('./routes/browse/browse.module').then(m => m.BrowseModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver
    },
    data: {
      name: 'Browse',
      title: 'We Remember : Browse Obituaries',
      description: 'Remember and celebrate loved ones by bringing together friends and family.  Collect memories and photos to paint a rich picture of their life.'
    }
  },
  {
    path: 'create-page',
    loadChildren: () => import('./routes/create-page/create-page.module').then(m => m.CreatePageModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver,
      canonical: CanonicalResolver
    },
    data: {
      name: 'Create Page',
      title: 'We Remember: Create',
      description: 'Create a free online obituary or memorial'
    }
  },
  {
    path: 'communication-preferences',
    loadChildren: () => import('./routes/communication/communication.module').then(m => m.CommunicationModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver,
      canonical: CanonicalResolver
    },
    data: {
      name: 'Communication Preferences',
      nofollow: true,
      title: 'We Remember : Communication Preferences',
      description: 'Modify your preferences here'
    }
  },
  {
    path: 'search',
    loadChildren: () => import('./routes/search/search.module').then(m => m.SearchModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver,
      canonical: CanonicalResolver
    },
    data: {
      name: 'Search | Memorial Pages',
      title: 'We Remember : Free online Memorials and obituaries',
      description: 'Remember and celebrate loved ones by bringing together friends and family.  Collect memories and photos to paint a rich picture of their life.'
    }
  },
  {
    path: 'my-pages',
    loadChildren: () => import('./routes/profile/profile.module').then(m => m.ProfileModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver,
      canonical: CanonicalResolver
    },
    data: {
      name: 'My Pages',
      title: 'Memorials | Obituaries',
      description: 'Memorials and obituaries you have created or contributed to.'
    }
  },
  {
    path: 'profile',
    loadChildren: () => import('./routes/profile/profile.module').then(m => m.ProfileModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver,
    },
    data: {
      name: 'Profile',
      title: 'We Remember : Profile',
      description: 'Remember and celebrate loved ones by bringing together friends and family.  Collect memories and photos to paint a rich picture of their life.'
    }
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./routes/edit-profile/edit.profile.module').then(m => m.EditProfileModule)
  },
  {
    path: 'help-center',
    loadChildren: () => import('./routes/help-center/help-center.module').then(m => m.HelpCenterModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver
    },
    data: {
      name: 'Help Center',
      title: 'Help Center | We Remember',
      description: 'Need help with We Remember? Our Help Center has answers to your top questions and easy guides to getting started, and managing your memorials and guests.'
    }
  },
  {
    path: 'contact',
    loadChildren: () => import('./routes/contact/contact.module').then(m => m.ContactModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver,
      canonical: CanonicalResolver
    },
    data: {
      name: 'Contact Us',
      title: 'Contact Us | We Remember',
      description: 'Contact We Remember.  Let us know what we can do to help.'
    }
  },
  {
    path: 'cookies-table',
    loadChildren: () => import('./routes/cookies-table/cookies-table.module').then(m => m.CookiesTableModule),
    resolve: {
      seo: SeoResolver,
    },
    data: {
      name: 'Cookies Table',
      title: 'Cookies Table | We Remember',
    }
  },
  {
    path: 'cookies',
    loadChildren: () => import('./routes/cookie-policy/cookie-policy.module').then(m => m.CookiePolicyModule),
    data: {
      name: 'Cookies',
      title: 'Cookie Policy | We Remember'
    },
    resolve: {
      seo: SeoResolver
    }
  },
  {
    path: 'health-check',
    loadChildren: () => import('./routes/health-check/health-check.module').then(m => m.HealthCheckModule),
    resolve: {
      seo: SeoResolver
    },
    data: {
      name: 'Health Check',
      nofollow: true,
      title: 'Health Check | We Remember'
    }
  },
  {
    path: 'verification/:memorialId',
    loadChildren: () => import('./routes/verification/verification.module').then(m => m.VerificationModule),
    resolve: {
      seo: SeoResolver
    },
    data: {
      name: 'Verification',
      nofollow: true,
      title: 'Verify | We Remember'
    }
  },
  {
    path: 'privacy',
    loadChildren: () => import('./routes/acom-iframe/acom-iframe.module').then(m => m.AcomIframeModule),
    data: {
      name: 'Privacy'
    },
    resolve: {
      canonical: CanonicalResolver
    }
  },
  {
    path: 'terms',
    loadChildren: () => import('./routes/acom-iframe/acom-iframe.module').then(m => m.AcomIframeModule),
    data: {
      name: 'Terms'
    },
    resolve: {
      canonical: CanonicalResolver
    }
  },
  {
    path: 'flyer',
    loadChildren: () => import('./routes/flyer/flyer.module').then(m => m.FlyerModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver
    },
    data: {
      name: 'Flyer',
      title: 'We Remember | Flyer',
      description: 'Print a Flyer.',
      nofollow: true
    }
  },
  {
    path: 'external',
    loadChildren: () => import('./routes/external/external.module').then(m => m.ExternalModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver
    },
    data: {
      name: 'Search Widget',
      title: 'We Remember',
      description: 'Remember and celebrate loved ones by bringing together friends and family.  Collect memories and photos to paint a rich picture of their life.'
    }
  },
  {
    path: 'unsubscribe/:type/:id/:email',
    redirectTo: 'communication-preferences',
    resolve: {
      seo: SeoResolver
    },
    data: {
      nofollow: true,
      title: 'Unsubscribe from We Remember',
      description: 'Unsubscribe from all We Remember communications'
    }
  },
  {
    path: 'unsubscribe',
    redirectTo: 'communication-preferences',
    resolve: {
      seo: SeoResolver
    },
    data: {
      nofollow: true,
      title: 'Unsubscribe from We Remember',
      description: 'Unsubscribe from all We Remember communications'
    }
  },
  {
    path: ':memorial/:shortId',
    loadChildren: () => import('./routes/memorial/memorial.module').then(m => m.MemorialModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver
    },
    data: {
      name: 'Memorial',
      title: 'We Remember | Memorials',
      description: 'Remember and celebrate loved ones by bringing together friends and family.  Collect memories and photos to paint a rich picture of their life.'
    }
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./routes/sign-in/sign-in.module').then(m => m.SignInModule),
  },
  {
    path: 'callback',
    loadChildren: () => import('./routes/callback/callback.module').then(m => m.CallbackModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver
    },
    data: {
      nofollow: true
    }
  },
  {
    path: 'example',
    redirectTo: '/david-salmon/ehk-a93/memories',
    resolve: {
      seo: SeoResolver
    },
    data: {
      name: 'Example',
      title: 'We Remember | Memorial',
      description: 'Remember and celebrate loved ones by bringing together friends and family.  Collect memories and photos to paint a rich picture of their life.'
    }
  },
  {
    path: 'admin',
    loadChildren: () => import('./routes/admin/admin.module').then(m => m.AdminModule),
    resolve: {
      userToken: AuthResolver
    },
    canActivate: [AdminGuard],
    data: {
      name: 'AdminTool',
      nofolow: true,
      title: 'We Remember | Memorial'
    }
  },
  {
    path: 'not-found',
    loadChildren: () => import('./routes/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    resolve: {
      userToken: AuthResolver,
      seo: SeoResolver
    },
    data: {
      name: 'Not Found',
      title: 'Page not found',
      description: 'Sorry, page wasn\'t found.  Remember and celebrate loved ones by bringing together friends and family.  Collect memories and photos to paint a rich picture of their life.',
      nofollow: true
    }
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];
