import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';


@Injectable()
export class ThemeService {

  public renderer: Renderer2;
  public gold: boolean = true;

  constructor(@Inject(DOCUMENT) private document, private _renderer: RendererFactory2) {
    this.renderer = _renderer.createRenderer(null, null);
  }

  setPrimary(color: string) {
    if (color !== '#D6B66D') {
      this.gold = false;
    }
    this.renderer.setProperty(this.document.body, 'style', '--primary:' + color + ';');
  }

  clear() {
    this.gold = true;
    this.renderer.setProperty(this.document.body, 'style', '--primary: #D6B66D;');
  }
}
