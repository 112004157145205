import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { assignIn, clone, filter, find, findIndex, forEach, pull } from 'lodash-es';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { finalize, tap, mergeMap } from 'rxjs/operators';
import { User } from '../../../models/user.model';

import { Signature } from '../../../models/signature.model';
import { HttpService } from '../../../services/http/http.service';
import { MemorialStore } from '../../../services/memorial.store';
import { UserStore, UserUpdateObject } from '../../../services/user.store';

@Injectable()
export class GuestbookService {

  private guestbookUrl = '/v1/memorial/:memorialId/guestbook';
  private singleSignatureFromUserIdUrl = '/v1/memorial/:memorialId/user/:userId/signature';
  private removeSignatureUrl: string = '/v1/memorial/:memorialId/user/:userId/signature';
  private deleteSignatureUrl: string = '/v1/admin/signature'

  guestbook: Signature[] = [];
  count: number = 0;
  displayCount: number = 24;
  groups: any = {};
  guestbookLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  guestbookUpdated: EventEmitter<Signature> = new EventEmitter<Signature>();
  guestbookChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  guestbookElement: any;
  guestbookOverflow: string;
  userSignatureSubject: ReplaySubject<any> = new ReplaySubject(1);
  fetchingSignature: boolean = false;
  isLoaded: boolean = false;
  memorialId: string;
  guest: any = {};
  guestbookSigned: boolean = false;

  observables: any = {};

  constructor (
    private httpService: HttpService,
    private store: MemorialStore,
    private userStore: UserStore,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.userStore.userObservable
      .pipe(
        mergeMap((uuo: UserUpdateObject) => {
          if (uuo && uuo.user && uuo.loggedIn) {
            if (this.store.memorial && !this.store.currentUserSignature) {
              //check for a user signature
              return this.getOne(this.userStore.user.id)
                .pipe(
                  tap(signature => {
                    if (signature) {
                      this.store.setUserSignature(signature);
                    }
                  })
                )
            } else {
              return of(false);
            }
          } else {
            return of(false);
          }
        })
      )
      .subscribe({
        next: (uuo: UserUpdateObject) => { },
        error: (err) => {
          console.log(err)
        }
      });

    this.store.memorialObservable
      .pipe(
        mergeMap(() => {
          if (this.userStore.loggedIn && !this.store.currentUserSignature) {
            return this.getOne(this.userStore.user.id)
              .pipe(
                mergeMap((signature) => {
                  return this.guestbookLoaded.pipe(
                    tap(() => {
                      if (signature) {
                        this.store.setUserSignature(signature);
                      }
                    })
                  )
                })
              )
          } else {
            return of(false);
          }
        })
      )
      .subscribe({
        next: () => { },
        error: (err) => {
          console.error(err);
        }
      });

    this.store.refresh.pipe(
      mergeMap(refresh => {
        if (refresh && this.store.memorial && this.store.memorial.id) {
          return this.get(this.store.memorial.id);
        } else {
          return of(false);
        }
      })
    ).subscribe({
      next: () => { },
      error: err => {
        console.error(err);
      }
    });
  }

  get (memorialId: any) {
    if (memorialId) {
      let url = this.guestbookUrl.replace(':memorialId', memorialId);
      return this.httpService
        .get(url)
        .pipe(
          tap(res => {
            this.store.setGuestbook(res);
          })
        );
    } else {
      return of(null);
    }
  }

  setUserSignature (user: User) {
    if (user && user.id) {
      this.guest = find(this.guestbook, guest => {
        return guest.userId === user.id
      });
      if (this.guest) {
        this.guestbookSigned = true;
        // keep store updated
        this.store.setUserSignature(this.guest);
        if (user.image) {
          this.guest.image = user.image;
        } else {
          this.guest.image = null;
        }
      }
    }
  }

  getOne (userId: string, dontAddToLocalGuestbook?: boolean) {
    if (!userId || !this.store?.memorial?.id) {
      return of(null);
    }

    let url = this.singleSignatureFromUserIdUrl
      .replace(':memorialId', this.store.memorial.id)
      .replace(':userId', userId);

    return this.httpService
      .get(url)
      .pipe(
        tap(signature => {
          if (!dontAddToLocalGuestbook) {
            if (findIndex(this.store.guestbook, { 'userId': userId }) < 0 && signature['status'] === 'active') {
              this.append(new Signature(signature));
            }
          }
        })
      );

  }

  getSignatureFromLocalGuestbook (userId: string): Observable<Signature> {
    let signature = find(this.store.guestbook, { 'userId': userId });
    return of(signature);
  }

  getSignatureByUserId (userId: string, memorialId: any) {

    if (!this.fetchingSignature) {
      this.fetchingSignature = true;
      this.fetchSignature(userId, memorialId);
    }

    return this.userSignatureSubject;
  }

  fetchSignature (userId: string, memorialId: any) {
    if (!userId || !memorialId) {
      return of(null);
    }

    let url = this.singleSignatureFromUserIdUrl
      .replace(':memorialId', memorialId)
      .replace(':userId', userId);
    this.httpService
      .get(url)
      .pipe(
        finalize(() => {
          this.fetchingSignature = false;
        })
      )
      .subscribe(signature => {
        this.userSignatureSubject.next(signature);
      }, error => {
        this.userSignatureSubject.next(null);
      });
  }

  append (signature: Signature) {
    this.guestbookUpdated.emit(signature);
    this.guestbook.push(signature);
  }

  prepend (signature: Signature) {
    if (findIndex(this.store.guestbook, (guest) => guest.userId === signature.userId) < 0) {
      if (this.store.guestbook) {
        this.store.guestbook.unshift(signature);
      } else {
        this.store.guestbook = [];
        this.store.guestbook.unshift(signature);
      }
    }
    this.guestbookUpdated.emit(signature);
  }

  clear () {
    this.guestbook = []; // cannot be null, if we try to push items to it after null it will fail
    this.guest = {};
    this.guestbookSigned = false;
    this.count = 0;
    this.guestbookChange.next(null);
  }

  // Remove own signature
  removeSignature () {
    let url = this.removeSignatureUrl.replace(':memorialId', this.store.memorial.id).replace(':userId', this.userStore.user.id);
    this.httpService
      .delete(url)
      .pipe(
        tap(res => {
          window.location.reload();
        })
      ).subscribe();
  }

  // Site admin delete signature
  deleteSignature (memorialId: string , userId: string) {
    return this.httpService.delete(this.deleteSignatureUrl, {memorialId, userId});
  }
}
