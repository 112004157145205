import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, makeStateKey, SafeResourceUrl, TransferState } from '@angular/platform-browser';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { youtubeRegex } from '../../models/helper/cloudinaryUrl';
import { HttpService } from './../http/http.service';

const VERSION_KEY = makeStateKey('version');

@Injectable()
export class HelperService {

  deleteFromCloudinaryUrl = '/v1/content/delete-from-cloudinary';

  cloudinaryUrl = environment.cloudinaryUrl;
  cloudinaryFolder = environment.cloudinaryFolder;

  _version: any;

  youtubeRe = youtubeRegex;
  constructor (
    private httpService: HttpService,
    private injector: Injector,
    private cache: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
    private sanitizer: DomSanitizer,
  ) { }

  getCSRF () {
    this.httpService.get('/v1/csrf').subscribe();
  }

  getCloudinaryUrl (parameters: string[], filename: string, url?: string) {
    let cloudinaryBase = environment.cloudinaryUrl + parameters.join('/');
    if (url) {
      if (url.indexOf('youtu.be') > -1) {
        return cloudinaryBase + '/youtube/' + this.getYoutubeId(url) + '/hqdefault.jpg'
      }
      //check if facebook
      if (url.indexOf('facebook') > -1) {
        return url;
      }
      if (url.indexOf('googleusercontent') > -1) {
        if (url.match(/photo.jpg$/)) {
          return null;
        } else {
          return url.replace(/s[0-9]{2}-c$/, 's250-c');
        }
      }
      if (url.indexOf('widget/') > -1) {
        return cloudinaryBase + '/' + url.substring(url.indexOf('widget/'));
      }
      if (url.indexOf('fdmemoriams/') > -1) {
        return cloudinaryBase + '/fdmemoriams/' + this.getFilename(url);
      }
      if (url.indexOf('memoriams/') > -1) {
        return cloudinaryBase + '/memoriams/' + this.getFilename(url);
      }
      if (url.indexOf('/otfiles/funerallogos') > -1) {
        // memoriams funeral logos check
        return cloudinaryBase + '/funerallogos/' + this.getFilename(url);
      }
      if (url.indexOf('/otfiles/paperlogos') > -1) {
        // memoriams paper logos check
        return cloudinaryBase + '/paperlogos/' + this.getFilename(url);
      }
      if (url.indexOf('fd.memoriams.com/otfiles/webimages') > -1) {
        return cloudinaryBase + '/fdmemoriams/' + this.getFilename(url);
      }
      if (url.indexOf('fd.memoriams.com/otfiles/') > -1) {
        //generic memoriams otfiles folder in cloudinary
        return cloudinaryBase + '/otfiles/' + this.getMemoriamsLocation(url);
      }
      url = url.toString().replace(/\.[^/.]+$/, '.jpg');
      return cloudinaryBase + this.cloudinaryFolder + 'uploads/' + this.getFilename(url);
    } else {
      if (filename) {
        // remove second 'uploads'
        if (filename.indexOf('uploads') > -1) {
          return cloudinaryBase + this.cloudinaryFolder + filename;
        } else {
          return cloudinaryBase + this.cloudinaryFolder + 'uploads/' + filename;
        }
      }

    }
  }

  deleteFromCloudinary (publicIds: string[]) {
    return this.httpService.post(this.deleteFromCloudinaryUrl, { 'public_ids': publicIds });
  }

  getFilename (url: string) {
    let filename = /[^/]*$/.exec(url)
    return encodeURIComponent(filename[0]);
  }

  getMemoriamsLocation (url: string) {
    // keeps memoriams folder structure in place
    let location = url.slice(url.indexOf('/otfiles/') + 9);
    return encodeURIComponent(location);
  }

  getPersonImage (parameters: string[], filename: string, url?: string) {
    const photo = this.getCloudinaryUrl(parameters, filename, url);
    if (photo) {
      return photo.toString().replace(/\.[^/.]+$/, '.png')
    } else {
      return null;
    }
  }

  parseYoutubeUrl (url: string) {
    let youtube = url.match(this.youtubeRe);
    return youtube;
  }

  getYoutubeId (url) {
    let youtube = this.parseYoutubeUrl(url);
    return youtube[5];
  }

  getYoutubeEmbedUrl (id): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + id + '?rel=0&showinfo=0&modestbranding=0');
  }

  setAppVersion () {
    if (this.isServer()) {
      this._version = this.injector.get('version');
      this.cache.set(VERSION_KEY, this.injector.get('version'));
    } else {
      if (this.cache.hasKey(VERSION_KEY)) {
        let cachedVersion = this.cache.get(VERSION_KEY, null as any);
        cachedVersion.timestamp = new Date(cachedVersion.timestamp);
        if (cachedVersion.timestamp) {
          cachedVersion.string = '' + cachedVersion.timestamp.getHours() + ':' + (cachedVersion.timestamp.getMinutes().length < 2 ? '0' + cachedVersion.timestamp.getMinutes() : cachedVersion.timestamp.getMinutes()) + ' | ' + cachedVersion.timestamp.getMonth() + '/' + cachedVersion.timestamp.getDate() + '/' + cachedVersion.timestamp.getFullYear();
        }
        this._version = cachedVersion;
        return this._version;
      }
    }
  }

  get version () {
    if (this._version) {
      return this._version;
    } else {
      return this.setAppVersion();
    }
  }

  scrollTop (scrollDuration) {
    if (this.isBrowser()) {
      let cosParameter = window.scrollY / 2,
        scrollCount = 0,
        oldTimestamp = performance.now();
      let step = (newTimestamp) => {
        scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
        if (scrollCount >= Math.PI) window.scrollTo(0, 0);
        if (window.scrollY === 0) return;
        window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
      }
      window.requestAnimationFrame(step);
    }
  }

  isBrowser () {
    return isPlatformBrowser(this.platformId);
  }

  isServer () {
    return isPlatformServer(this.platformId);
  }

  removeSpecialChars (str) {
    if (str) {
      return str.replace(/(?!\w|\s)./g, '')
        .replace(/\s+/g, ' ')
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
    } else {
      return '';
    }
  }

  stop (event) {
    event.preventDefault(); // stop any links
    event.stopPropagation(); // stop propagation
  }

  checkBadUrl (checkUrl) {
    let url = '/v1/memorial/link/verify';
    let obj = {
      url: checkUrl
    }
    return this.httpService
      .post(url, obj)
      .pipe(
        tap(body => {
          return body.message;
        }),
        catchError(error => {
          return error;
        })
      )
  }
}

