import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import pjson from '../../../../package.json';

@Injectable()
export class RaygunService {
  rg4js: any;
  loaded: boolean = false;

  init () {
    if (!this.loaded) {
      this.loaded = true;
      const $script = document.createElement('script');
      $script.type = 'text/javascript';
      $script.innerHTML = `!function(a,b,c,d,e,f,g,h){a.RaygunObject=e,a[e]=a[e]||function(){
    (a[e].o=a[e].o||[]).push(arguments)},f=b.createElement(c),g=b.getElementsByTagName(c)[0],
    f.async=1,f.src=d,g.parentNode.insertBefore(f,g),h=a.onerror,a.onerror=function(b,c,d,f,g){
    h&&h(b,c,d,f,g),g||(g=new Error(b)),a[e].q=a[e].q||[],a[e].q.push({
    e:g})}}(window,document,"script","//cdn.raygun.io/raygun4js/raygun.min.js","rg4js");`  ;
      $script.id = 'raygunScript';
      return of(document.body.append($script)).pipe(
        tap(() => {
          this.rg4js = window['rg4js'];
          window['rg4js']('apiKey', environment.raygunKey);
          window['rg4js']('withTags', ['we-remember-frontend', (environment.production ? 'we-remember-frontend-prod' : 'we-remember-frontend-dev')])
          window['rg4js']('enableCrashReporting', true);
          window['rg4js']('setVersion', pjson.version);
        })
      );
    } else {
      return of(null);
    }
  }

  stringifyError (thrownError) {
    const err = {};

    Object.getOwnPropertyNames(thrownError).forEach((key) => {
      err[key] = thrownError[key];
    });

    return err;
  }

  sendError (error, customData?) {
    if (typeof window !== 'undefined' && window['rg4js']) {
      try {
        throw error;
      } catch (error) {
        window['rg4js']('send', {
          error,
          customData
        })
      }
    }
  }

}
