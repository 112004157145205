import { Title, Meta } from '@angular/platform-browser';
import { Memorial } from './../../models/memorial.model';
import { Injectable } from "@angular/core";
import { find, findIndex } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { Comment } from './../../models/comment.model';
import { Memory } from './../../models/memory.model';
import { MemorialStore } from './../../services/memorial.store';
import { UnsubscribeOnDestroyAdapter } from './../../shared/adapters/unsubscribe-adapter';

export class MemoryDetails {
  comments: Comment[] = [];
  list: any[] = [];
  hasLiked: boolean = false;
  isAdmin: boolean = false;
  isEditor: boolean = false;
  isOwner: boolean = false;
  likes: any[] = [];
  likesArray: any[] = [];
}

@Injectable()
export class MemoryStore extends UnsubscribeOnDestroyAdapter {
  facebookShareImage: string = 'https://assets.weremember.com/image/upload/l_text:Source%20Sans%20Pro_54_bold_center_letter_spacing_-1_line_spacing_-10:{{fullName}},co_rgb:3E3E3E/fl_layer_apply,y_-70,x_0/c_fill,w_1032,h_538,g_north/f_auto,q_auto/v1630096212/assets/facebookDefault.png';

  memory: Memory;
  memoryObservable: BehaviorSubject<Memory> = new BehaviorSubject<Memory>(null);
  mediaItem: any;
  mediaItemObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  details: MemoryDetails;
  detailsObservable: BehaviorSubject<MemoryDetails> = new BehaviorSubject<MemoryDetails>(null);
  tempMemory: Memory;

  constructor (
    private memorialStore: MemorialStore,
    private meta: Meta,
    private title: Title) {
    super();
  }

  clear () {
    this.memory = null;
    this.memoryObservable.next(null);
    this.details = null;
    this.detailsObservable.next(null);
  }

  setMemory (memory: Memory) {
    this.memory = memory;
    this.tempMemory = null; // clear temp if we set a new memory
    if (this.memory.questionId) {
      this.setupQuestion();
    }
    this.memoryObservable.next(this.memory);
  }

  setTempMemory (memory: Memory) {
    // shadow memory used for transitioning between viewing and editing
    this.tempMemory = memory;
  }

  clearTempMemory () {
    this.tempMemory = null;
  }

  upsertComment (comment: Comment) {
    let oldIndex = -1;
    let commentContentId = comment.contentId || comment.id; //TODO cleanup after migration
    if (this.details && this.details.comments) {
      oldIndex = this.details.comments.findIndex((item) => item.contentId === commentContentId);
    }
    if (oldIndex > -1) {
      // already exists, update
      if (comment.status !== 'active') {
        // remove deleted/reported comments
        this.details.comments.splice(oldIndex, 1);
      } else {
        this.details.comments[oldIndex] = comment;
      }
    } else {
      if (!this.details) {
        this.details = new MemoryDetails();
      }
      this.details.comments.push(comment);
    }
    this.detailsObservable.next(this.details);
  }

  setAnsweredQuestion (question: any, signature: any = null) {
    this.memory.answeredQuestion = {
      question: question,
      signature: signature
    };
    this.memoryObservable.next(this.memory);
  }

  updateMemory (memory: Memory) {
    this.setMemory(memory);
    this.memorialStore.updateMemory(memory);
  }

  setDetails (details: MemoryDetails) {
    if (details) {
      this.details = details;
      this.createLikesList();
      this.detailsObservable.next(this.details);
    }
  }

  likeMemory (memoryId: string, userId: string, liked: boolean) {
    if (this.memory) {
      if (this.memory.contentId === memoryId) {
        this.details.hasLiked = liked;
        this.detailsObservable.next(this.details);
      }
      const idx = findIndex(this.memorialStore.memories, ['id', memoryId]);
      this.memorialStore.memories[idx]['hasLiked'] = liked;
      if (liked) {
        if (!this.memorialStore.memories[idx]['likes']) {
          this.memorialStore.memories[idx]['likes'] = [];
        }
        this.memorialStore.memories[idx]['likes'].push({ userId: userId });
      } else {
        if (this.memorialStore.memories[idx]['likes'].length === 1) {
          this.memorialStore.memories[idx]['likes'] = [];
        } else {
          const likeIdx = findIndex(this.memorialStore.memories[idx]['likes'], ['userId', userId]);
          this.memorialStore.memories[idx]['likes'].splice(likeIdx, 1);
        }
      }
    }
  }

  createLikesList () {
    this.details.likesArray = [];

    this.subs.sink = this.memorialStore.guestbookObservable.subscribe((guestbook) => {
      if (this.details && guestbook) {
        for (let i = 0; i < this.details.likes.length; i++) {
          let tempGuest = find(guestbook.guests, ['userId', this.details.likes[i].userId]);
          this.details.likesArray.push(tempGuest);
        }
      }
    })
    this.detailsObservable.next(this.details);
  }

  setupQuestion () {
    this.subs.sink = this.memorialStore.questionsObservable.subscribe(questions => {
      if (this.memory && this.memory.questionId) {
        const answeredQuestion: any = find(questions, ['id', this.memory.questionId]);
        if (answeredQuestion) {
          if (answeredQuestion.userId) {
            this.memorialStore.guestbookObservable.subscribe(guestbook => {
              if (guestbook) {
                const signature = find(guestbook.guests, ['userId', answeredQuestion.userId]);
                if (signature) {
                  this.setAnsweredQuestion(answeredQuestion, signature);
                } else {
                  this.setAnsweredQuestion(answeredQuestion);
                }
              }
            }, error => {
              this.setAnsweredQuestion(answeredQuestion);
            })
          } else {
            this.setAnsweredQuestion(answeredQuestion);
          }
        }
      }
    }, err => {
      console.log(err);
    });
  }

  tagMemory (memory: Memory) {
    let image = this.getFacebookShareImage(this.memorialStore.memorial);

    if (memory.media && memory.media[0].type === 'photo') {
      image = memory.media[0].url;
    } else if (memory.media && memory.media[1].type === 'photo') {
      image = memory.media[1].url;
    }

    if (this.memorialStore.memorial.test) {
      this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    }
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ property: 'og:title', content: `A memory of ${this.memorialStore.memorial.getName()} was shared` });
    this.meta.updateTag({ property: 'og:description', content: `Visit We Remember to see this memory and add your own` })
    this.meta.updateTag({ name: 'description', content: `Celebrate the life of ${this.memorialStore.memorial.getName()}. Collect memories, photos, and more. Remember, together.` })
    this.title.setTitle(`Memory of ${this.memorialStore.memorial.firstName} ${this.memorialStore.memorial.lastName}`);
  }

  getFacebookShareImage (memorial: Memorial) {
    return this.facebookShareImage.replace('{{fullName}}', encodeURI(memorial.getName()));
  }
}
