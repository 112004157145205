import { SignInService } from './../../../../services/sign-in/sign-in.service'
import { Directive, EventEmitter, Host, HostListener, Input, OnInit, Optional, Output } from '@angular/core'
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router'
import { tap } from 'rxjs'

import { EditRelationshipDialogComponent } from '../../../../routes/memorial/components/guestbook/edit-relationship/edit-relationship.dialog.component'
import { CustomOverlayRef, CustomOverlayService } from '../../../../routes/memorial/services/custom-overlay.service'
import { MemorialStore } from '../../../../services/memorial.store'
import { UserStore } from '../../../../services/user.store'
import { UnsubscribeOnDestroyAdapter } from '../../../adapters/unsubscribe-adapter'
import { InputButtonComponent } from '../../inputs/input-button/input-button.component'

@Directive({
  selector: '[mem-sign-in-button]'
})
export class SignInButtonComponent extends UnsubscribeOnDestroyAdapter{

  @Output() finished = new EventEmitter();
  @Input() step: string
  @Input() title: string
  @Input() guest: boolean = true;

  constructor (
    @Optional() private store: MemorialStore,
    private userStore: UserStore,
    private router: Router,
    private route: ActivatedRoute,
    private overlay: CustomOverlayService,
    private signInService: SignInService,
    @Optional() @Host() public inputButton: InputButtonComponent

  ) {
    super()
  }

  @HostListener('click', ['$event']) clickEvent ($event) {
    this.signIn($event)
  }
  @HostListener('keydown.enter', ['$event']) keydownEnter ($event) {
    this.signIn($event)
  }

  @HostListener('keydown', ['$event']) keydown ($event) {
    if(this.inputButton) {
      // also open question dialog on alphanumeric events if input button directive is present
      if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 65 && $event.keyCode <= 90)) {
        this.signIn($event)
      }
      
    }
  }

  signIn ($event) {
    if (this.store.memorial?.guestmodeDisabled) {
      this.guest = false
    }
    if (!this.guest) {
      this.userStore.clearGuest()
    }
    if (this.userStore.hasGuestUser && this.userStore.guestUser.relationGroup) {
      this.finished.emit('signedIn')
      return
    }
    // don't do anything if there is no step and they are logged in
    if (!this.step && this.userStore.loggedIn) {
      this.finished.emit('signedIn')
      return
    }
    // don't do anything if they are logged in and in the guestbook
    if (this.store.isLoggedInAndGuestbookSign()) {
      if (this.step === 'moderator' && !this.store.isModerator()) {
        $event.stopPropagation()
        $event.preventDefault()
        return this.signInService.becomeModerator(this.store.memorial.id, this.userStore.user.id).subscribe({
          next: () => {
            this.finished.emit('signedIn')
          }
        })
      }
      this.finished.emit('signedIn')
      return
    }
    if (this.step === 'moderator' && this.userStore.loggedIn) {
      const config = {
        data: {
          relationshipTitle: 'Join the page to become a moderator. Select a relationship to display in the guestbook.'
        }
      }
      return this.openGuestbookDialog(config, $event)
    }
    if (this.step === 'relationship' && (this.userStore.loggedIn || (this.userStore.hasGuestUser && !this.userStore.guestUser?.relationGroup))) {
      
      return this.openGuestbookDialog({}, $event)
    }
    const data = {
      step: this.step || null,
      title: this.title || null,
      guest: this.guest
    }
    this.router.navigate(['sign-in'], { queryParams: { 'redirectUrl': this.route.snapshot['_routerState'].url, 'guest': this.guest, 'title': this.title } } as NavigationExtras)
  }

  openGuestbookDialog (config, $event) {
    // when opening modal stop propagation because close button gets triggered by keydown.enter 
    $event.stopPropagation()
    $event.preventDefault()
    this.overlay.open(EditRelationshipDialogComponent, config)
      .pipe(
        tap(ref => {
          if (ref instanceof CustomOverlayRef) {
            this.finished.emit('signedIn')
            ref.close()
          }
        })
      )
      .subscribe()
  }

}
