const unescapeHtml = (text: string) => {
  const unescapedText: {[k: string]: string} = {
    '&a;': '&',
    '&amp;': '&',
    '&q;': '"',
    '&quot':'"',
    '&s;': '\'',
    '&apos;': '\'',
    '&l;': '<',
    '&g;': '>',
    '&lt;': '<',
    '&gt;': '>'
  };
  return text.replace(/&[^;]+;/g, s => unescapedText[s]);
}

export { unescapeHtml };
