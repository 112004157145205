import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup } from '@angular/forms';

export interface ButtonBoxOptions {
  value: string | number;
  label: string;
  icon: string;
  check: boolean;
  selected: boolean;
  vertical: boolean;
  small: boolean;
}

@Component({
  selector: 'mem-button-box-group',
  template: `
    <div class="button-box-group">
      <mem-button-box *ngFor="let item of options" [icon]="item.icon" [selected]="item.selected" [check]="item.check" [vertical]="item.vertical" [small]="item.small" (click)="setValue(item)" (keydown.enter)="setValue(item)">{{ item.label }}</mem-button-box>
    </div>
  `,
  styleUrls: [`./button-box-group.component.scss`],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonBoxGroupComponent),
      multi: true
    }
  ]
})
export class ButtonBoxGroupComponent implements ControlValueAccessor {

  @Input() options: ButtonBoxOptions[];
  @Input() initialValue: any;
  value: any;
  selectedOption: ButtonBoxOptions;
  disabled: boolean = false;

  ngAfterViewInit() {
    if (this.initialValue) {
      this.setValue(this.initialValue);
    }
  }

  onChange = (_: any) => { };

  setValue (item: ButtonBoxOptions) {
    if (this.selectedOption) {
      this.selectedOption.selected = false;
      this.selectedOption.check = false;
    }
    this.selectedOption = item;
    this.value = item.value;
    item.selected = true;
    item.check = true;
    this.emitChanges();
  }

  emitChanges () {
    this.onChange(this.value);
  }

  writeValue (val: string): void {
    this.value = val;
  }

  registerOnChange (fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched (fn: any): void { }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
