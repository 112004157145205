<div>
  <div *ngFor="let step of steps; let i = index" class="step-content"
    [attr.aria-expanded]="(selectedIndex === i && !namedActive) && !confirmationActive">
    <ng-container [ngTemplateOutlet]="step.content"></ng-container>
  </div>
  <div *ngFor="let step of namedSteps; let i = index" class="named-step-content"
    [attr.aria-expanded]="(namedActive == step.name) && !confirmationActive">
    <ng-container [ngTemplateOutlet]="step.content"></ng-container>
  </div>
  <div *ngFor="let step of confirmations; let i = index" class="confirmation" [attr.aria-expanded]="confirmationActive">
    <ng-container [ngTemplateOutlet]="step.content" [ngTemplateOutletContext]="confirmationContext"></ng-container>
  </div>
</div>