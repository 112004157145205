<div class="loadingContainer" [class.done]="doneLoading">
<svg width="70" height="20" class="animated" [class.fadeOut]="timeout" transform="scale(.5)">
  <circle cx="10" cy="10" r="0">
    <animate attributeName="r" from="0" to="10" values="0;10;10;10;0" dur="1000ms" repeatCount="indefinite"/>
  </circle>
  <circle cx="35" cy="10" r="0">
    <animate attributeName="r" from="0" to="10" values="0;10;10;10;0" begin="200ms" dur="1000ms" repeatCount="indefinite"/>
  </circle>
  <circle cx="60" cy="10" r="0">
    <animate attributeName="r" from="0" to="10" values="0;10;10;10;0" begin="400ms" dur="1000ms" repeatCount="indefinite"/>
  </circle>
</svg>

  <div class="timeout animated" [class.fadeIn]="timeout">
    <p>Looks like something went wrong.</p>
    <p>Reload the page and try again.</p>
    <button (click)="refresh()" class="button-default button-small">RELOAD</button>
  </div>
</div>