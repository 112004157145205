import { AnalyticsService } from './../analytics/analytics.service';
import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from './../http/http.service';
import { TrackingName } from './../../models/tracking/trackingEvent.model';


@Injectable()
export class PartnerService {

  private getPartnerUrl = '/v1/partner/';
  private partnerLinkUrl = '/v1/partner/request'

  partnerSet: EventEmitter<any> = new EventEmitter<any>(null);

  partner: any = {};
  isSponsor: boolean = false;

  constructor (private httpService: HttpService,
    private analyticsService: AnalyticsService) {

  }

  getPartner (sponsor: string, partner?: string, doNotSet?: boolean) {
    let url = `${this.getPartnerUrl}${sponsor}`;
    if (partner) {
      url += `/${partner}`
    }

    return this.httpService
      .get(url)
      .pipe(
        map(res => {
          if (!doNotSet) {
            this.setPartner(res);
          }
          return res;
        })
      );
  }

  setPartner (partner: any) {
    this.partner = partner;
    if (partner.sponsor && partner.sponsor.name) {
      this.isSponsor = true;
    }
    this.partnerSet.next(true);
  }

  createPartnerLink (partner: any) {
    return this.httpService
      .post(this.partnerLinkUrl, partner)
      .pipe(
        map(res => {
          this.analyticsService.sendTrackingEvent(TrackingName.CreatePartner, {})
          return res;
        })
      );
  }

  clear () {
    this.partner = {};
  }
}
