import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { Observable, of, ReplaySubject, tap } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ExternalService {
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};
  private SITELINK_SCRIPT_ID = 'googleSitelinkScript';
  private adsLoaded = false;

  constructor (@Inject(DOCUMENT) private readonly document: any) { }

  /**
   * Used to load an external script from a URL, tracks loaded scripts to short circuit
   * @param url URL of the script to load
   * @param id unique id for the script so we can manage it if needed
   * @returns
   */
  loadScript (url: string, id: string, force: boolean = false): Observable<any> {
    if (!force && this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable()
    }

    this._loadedLibraries[url] = new ReplaySubject()

    const script = this.document.createElement('script')
    script.id = id
    script.type = 'text/javascript'
    script.async = true
    script.src = url
    script.onload = () => {
      this._loadedLibraries[url].next(true)
      this._loadedLibraries[url].complete()
    }

    this.document.body.appendChild(script)

    return this._loadedLibraries[url].asObservable()
  }

  removeScript (id: string) {
    try {
      let item = this.document.getElementById(id)
      if (item) {
        item.remove()
      }
    } catch (error) {
      console.error('error removing script', error)
      //not all that important
    }
  }

  addSitelinksScript () {
    try {
      let script = this.document.createElement('script')
      script.id = this.SITELINK_SCRIPT_ID
      script.type = 'application/ld+json'
      script.async = false
      script.text = `{
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://www.weremember.com/",
        "potentialAction": {
          "@type": "SearchAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://www.weremember.com/search/results?q={search_term_string}"
          },
          "query-input": "required name=search_term_string"
        }
      }`

      this.document.body.appendChild(script)
    }
    catch (error) {
      //not all that important...
    }
  }

  removeSitelinksScript () {
    this.removeScript(this.SITELINK_SCRIPT_ID)
  }

}
