import { environment } from '../../../environments/environment';

//! regex duplicated src/app/services/helper/helper.service
export const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/|shorts\/)?)([\w\-]+)(\S+)?$/;

export const getCloudinaryUrl = (parameters: string[], filename: string, url?: string) => {
  let cloudinaryBase = environment.cloudinaryUrl + parameters.join('/');
  if (url) {
    if (url.indexOf('youtu.be') > -1) {
      return cloudinaryBase + '/youtube/' + getYoutubeId(url) + '/hqdefault.jpg'
    }
    //check if facebook
    if (url.indexOf('facebook') > -1) {
      return url;
    }
    if (url.indexOf('widget/') > -1) {
      return cloudinaryBase + '/' + url.substring(url.indexOf('widget/'));
    }
    if (url.indexOf('memoriams/') > -1) {
      return cloudinaryBase + '/memoriams/' + getFilename(url);
    }
    if (url.indexOf('fd.memoriams.com') > -1) {
      return cloudinaryBase + '/fdmemoriams/' + getFilename(url);
    }
    url = url.toString().replace(/\.[^/.]+$/, '.jpg');
    return cloudinaryBase + environment.cloudinaryFolder + 'uploads/' + getFilename(url);
  } else {
    if (filename) {
      // remove second 'uploads'
      if (filename.indexOf('uploads') > -1) {
        return cloudinaryBase + environment.cloudinaryFolder + filename;
      } else {
        return cloudinaryBase + environment.cloudinaryFolder + 'uploads/' + filename;
      }
    }

  }
}

export const getFilename = (url: string) => {
  let filename = /[^/]*$/.exec(url)
  return encodeURIComponent(filename[0]);
}

export const parseYoutubeUrl = (url: string) => {
  let youtube = url.match(youtubeRegex);
  return youtube;
}

export const getYoutubeId = (url) => {
  let youtube = parseYoutubeUrl(url);
  return youtube[5];
}
