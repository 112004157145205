import { LazyImgDirective } from './lazy-image.directive'
import { FocusElementDirective } from './focusElement.directive'
import { DeviceDetectorService } from 'ngx-device-detector'
import { ScreenSizeService } from './screenSize/screen-size.service'
import { ScreenSizeDirective } from './screenSize/screen-size.directive'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ButtonSpinnerDirective } from './button-spinner.directive'
import { DisableControlDirective } from './disable-control.directive'
import { SyncControlDirective } from './sync-control.directive'

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ButtonSpinnerDirective,
    SyncControlDirective,
    DisableControlDirective,
    ScreenSizeDirective,
    FocusElementDirective,
    LazyImgDirective
  ],
  exports: [
    ButtonSpinnerDirective,
    SyncControlDirective,
    DisableControlDirective,
    ScreenSizeDirective,
    FocusElementDirective,
    LazyImgDirective
  ],
  providers: [
    ScreenSizeService,
    DeviceDetectorService
  ]
})

export class DirectivesModule {
}
