import { isPlatformBrowser } from '@angular/common';
import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from "../../models/user.model";
import { HttpService } from '../http/http.service';
import { StorageService } from '../storage/storage.service';
import { TrackingName } from './../../models/tracking/trackingEvent.model';
import { TrackingOptions } from './../../models/tracking/trackingOptions.interface';
import { AnalyticsService } from './../analytics/analytics.service';
import { UserPermissionsObject, UserStore } from './../user.store';
import { AuthStatusEvent } from './auth-status-event';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private communicationPreferencesV3Url: string = '/v3/user/:userId/communication';
  private addMemorialNofitificationUrl: string = '/v3/user/:userId/memorial-subscription/:memorialId';
  private addNewsUrl: string = '/v3/user/:userId/news-subscription';
  private permissionsUrl: string = '/v1/user/permissions/:userId';
  private userUrl: string = '/v1/user';
  private myMemorialsUrl = '/v1/user/:userId/memorials';
  private getUserMemorialsForIndividualProfileUrl = '/v1/profile/:userId/memorials';
  private bulkUnsubscribeUrl = '/v3/user/unsubscribe/:email';

  STATUSES: any = {
    'LOGIN': 'login',
    'LOGOUT': 'logout',
    'GUESTBOOK': 'guestbook',
    'UPDATE': 'update'
  };

  status: string;
  permissions: any = {};
  checkingPermissions: boolean = false;
  userUpdate: BehaviorSubject<any> = new BehaviorSubject<any>({});
  signatureUpdate: EventEmitter<any> = new EventEmitter<any>();
  permissionsUpdate: EventEmitter<any> = new EventEmitter<any>();
  loginStatusChange: Subject<AuthStatusEvent> = new Subject();

  editor: boolean = false;
  admin: boolean = false;

  constructor (private httpService: HttpService,
    private storageService: StorageService,
    private cookieService: CookieService,
    private analyticsService: AnalyticsService,
    private store: UserStore,
    private jwtHelper: JwtHelperService,
    @Inject(PLATFORM_ID) private platformId: Object) {
  }

  getUser () {
    return this.httpService
      .get(this.userUrl)
      .subscribe((user: User) => {
        this.setUser(Object.assign({}, this.store.user, user)).subscribe();
      }, err => {
        console.log(err);
      });
  }

  getUserFromToken () {
    const jwt = this.cookieService.get('id_token');
    if (jwt) {
      const token = this.jwtHelper.decodeToken(this.cookieService.get('id_token'));
      if (token && token.user) {
        return token.user;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  setUser (user: any, doNotUpdate?: boolean) {
    return new Observable((observer) => {
      this.store.setUser(user);
      this.store.storeUser();
      if (!doNotUpdate) {
        if (user && !user.new) {
          this.permissions = {
            admin: [],
            editor: []
          };
          this.getPermissions();
        }
        this.emitLoginStatusChange(this.STATUSES.LOGIN, true, user, this.status);
      }
      this.userUpdate.next(user);
    });
  }

  emitLoginStatusChange (currentStatus: string, loggedIn: boolean, user: User, previousStatus?: string) {
    this.loginStatusChange.next({
      previousStatus: previousStatus,
      currentStatus: currentStatus,
      loggedIn: loggedIn,
      user: user
    });
    this.status = currentStatus;
  }

  // ensures the checkToken is only called once every 10 seconds
  doCheckToken () {
    if (!this.checkingPermissions) {
      this.checkingPermissions = true;
      this.checkToken();
      setTimeout(() => {
        this.checkingPermissions = false;
      }, 10000)
    }
  }

  checkToken () {
    let jwt = this.cookieService.get('id_token');
    if (jwt) {
      this.store.setUser(this.jwtHelper.decodeToken(this.cookieService.get('id_token')).user);
    }
    if (isPlatformBrowser(this.platformId)) {
      this.storageService.getObject('user').subscribe(currentUser => {
        if (jwt && currentUser) {
          currentUser.new = false;
          this.store.setUser(currentUser);
          this.getUser();
          this.getPermissions();
        } else if (jwt) {
          this.getUser();
        } else {
          this.store.setUser(null);
          this.userUpdate.next(currentUser);
          return of(currentUser);
        }
      })
    }
  }

  getV3CommunicationPreferences () {
    if (this.store.loggedIn) {
      let url = this.communicationPreferencesV3Url.replace(':userId', this.store.user.id);
      return this.httpService
        .get(url)
        .pipe(
          tap(res => {
            if (res) this.store.updateUser({ communicationPreferences: res });
          })
        );
    } else {
      return of(false);
    }
  }

  updateV3CommunicationPreferences (preferences, trackingOptions?: TrackingOptions) {
    if (this.store.loggedIn) {
      let url = this.communicationPreferencesV3Url.replace(':userId', this.store.user.id);

      return this.httpService
        .put(url, preferences)
        .pipe(
          tap(res => {
            if (res && res.preferences) this.store.updateUser({ communicationPreferences: res.preferences });
            this.analyticsService.sendTrackingEvent(TrackingName.Unsubscribe, {
              ...trackingOptions
            })
          })
        );
    }
  }

  addMemorialNotification (memorialId) {
    if (this.store.loggedIn) {
      let url = this.addMemorialNofitificationUrl.replace(':userId', this.store.user.id).replace(':memorialId', memorialId);

      return this.httpService
        .post(url, {});
    }
  }

  addNewsNotification () {
    if (this.store.loggedIn) {
      let url = this.addNewsUrl.replace(':userId', this.store.user.id);

      return this.httpService
        .post(url, {});
    }
  }

  bulkUnsubscribe (emailAddress) {
    let url = this.bulkUnsubscribeUrl.replace(':email', emailAddress);

    return this.httpService
      .put(url)
  }

  getPermissions () {
    let url = this.permissionsUrl.replace(':userId', this.store.user.id);
    return this.httpService
      .get(url)
      .subscribe((permisions: UserPermissionsObject) => {
        if (permisions) {
          this.store.setUserPermissions(permisions);
        }
      }, (err) => {
        console.error(err);
      });
  }

  getPermissionsById (userId: string) {
    let url = this.permissionsUrl.replace(':userId', userId);
    return this.httpService
      .get(url)
      .pipe(
        tap(permissions => {
          if (permissions) {
            this.store.setUserPermissions(permissions);
          }
        })
      )
  }
  //###############
  //End User Permissions and Toggles
  //###############

  //####### INDIVIDUAL USER APIS ##########
  getUserMemorials (userId?: string) {

    userId = userId || 'my';
    let url = this.myMemorialsUrl.replace(':userId', userId);
    return this.httpService
      .get(url);
  }

  getUserMemorialsForIndividualProfile (userId: string) {
    let url = this.getUserMemorialsForIndividualProfileUrl.replace(':userId', userId);
    return this.httpService
      .get(url);
  }
}
