import { Injectable } from "@angular/core"

@Injectable()
export class AdService {
  private adsLoaded: boolean = false

  reInitAdvertisements () {
    if (this.adsLoaded) {
      window['AdBridg'].cmd.push([{
        "action": "spa_pv_shutdown",         // This shutdowns the ads pageview
        "config": {
          "site": "weremember"
        }
      },
      {
        "action": "spa_pv_initialize",       // This re-initializes the ads pageview
        "config": {
          "site": "weremember"
        }
      }])
      return
    }
  }

  initAdvertisements () {
    if (!this.adsLoaded) {
      this.adsLoaded = true

      window['AdBridg'] = window['AdBridg'] || { cmd: [] }
      window['googletag'] = window['googletag'] || { cmd: [] }

      window['AdBridg'].cmd.push({
        'action': 'spa_pv_initialize',
        'config': {
          'site': 'weremember'
        }
      })
    }
  }
}