import { AutoTracking } from './tracking/autoTracking.interface';
import {
  forEach as _forEach,
  isString as _isString
} from 'lodash-es';

import { unescapeHtml } from './helper/unescapeHtml';

export class Signature {
  image: any;
  firstName: string;
  lastName: string;
  id: string;
  relation: string;
  relationGroup: string;
  userId: string;
  memorialId: string;
  createdAt: string;
  status: string;
  type: string;

  constructor (data) {
    _forEach(data, (value, key) => {
      if (_isString(value)) {
        value = unescapeHtml(value);
      }
      this[key] = value;
    });
  }

  getTracking (): AutoTracking {
    return new AutoTracking({
      userRelation: this.relation,
      userRelationGroup: this.relationGroup
    })
  }
}
