import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ReplaySubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CustomOverlayRef, CustomOverlayService } from './../../../routes/memorial/services/custom-overlay.service';
import { StorageService } from './../../../services/storage/storage.service';
import { CookieNoticeComponent } from './cookie-notice.component';

export class CookieOptions {
  timestamp: number;
  essentialCookies: boolean = true;
  analyticsCookies: boolean = false;
  facebookAllowed: boolean = false;
  googleAllowed: boolean = false;


  constructor (analyticsCookies?: boolean, facebookAllowed?: boolean, googleAllowed?: boolean) {
    this.timestamp = moment().unix();
    this.analyticsCookies = analyticsCookies || false;
    this.facebookAllowed = facebookAllowed || false;
    this.googleAllowed = googleAllowed || false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CookieNoticeService {
  private storageSubscription: Subscription;
  private initialized: boolean = false;

  public cookiesAllowed$: ReplaySubject<CookieOptions> = new ReplaySubject(1);
  public analyticsCookiesAllowed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public facebookLoginAllowed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public googleLoginAllowed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public cookieOptions: CookieOptions = new CookieOptions();

  public inEU: boolean = false;
  formGroup: FormGroup;

  constructor (
    private overlay: CustomOverlayService,
    private storage: StorageService,
    private formBuilder: FormBuilder) {
      this.updateFormValues();
  }

  initialize () {
    if (!this.initialized) {
      this.initialized = true;
      this.inEU = Intl.DateTimeFormat().resolvedOptions().timeZone.startsWith('Europe')? true : false
      this.storageSubscription = this.storage.getObject('cookieOptions').subscribe((storedCookieOptions: CookieOptions) => {
        if (storedCookieOptions) {
          this.cookieOptions = storedCookieOptions;
          this.cookiesAllowed$.next(this.cookieOptions);
          this.analyticsCookiesAllowed$.next(this.cookieOptions.analyticsCookies);
          this.facebookLoginAllowed$.next(this.cookieOptions.facebookAllowed);
          this.googleLoginAllowed$.next(this.cookieOptions.googleAllowed);
        } else {
          //need to init cookie options
          this.cookieOptions = new CookieOptions();
          // this.open();
          this.cookiesAllowed$.next(this.cookieOptions);
        }
        if(!this.inEU){
          // Enable cookies by default for non-EU
          this.cookieOptions.analyticsCookies = true;
          this.cookiesAllowed$.next(this.cookieOptions);
          this.analyticsCookiesAllowed$.next(this.cookieOptions.analyticsCookies);
          this.saveToStorage();
        }
        this.updateFormValues();
      })
    }
  }

  updateFormValues () {
    this.formGroup = this.formBuilder.group({
      essentialCookies: true,
      analyticsCookies: this.cookieOptions.analyticsCookies,
      facebookAllowed: this.cookieOptions.facebookAllowed,
      googleAllowed: this.cookieOptions.googleAllowed
    })
  }

  open () {
    this.overlay.open(CookieNoticeComponent, {
      hasBackdrop: this.inEU,
      disableClose: this.inEU,
    }).pipe(
      tap((ref) => {
        if (ref instanceof CustomOverlayRef) {
          ref.close();
        }
      })
    ).subscribe();
  }

  submit () {
    const options = this.formGroup.value;
    this.updateCookieOptions(new CookieOptions(options['analyticsCookies'], options['facebookAllowed'], options['googleAllowed']));
  }

  accept () {
    this.cookieOptions = new CookieOptions(true, true, true);
    this.saveSettings();
  }

  reject () {
    this.cookieOptions = new CookieOptions(false, false, false);
    this.saveSettings();
  }

  updateCookieOptions (cookieOptions: CookieOptions) {
    this.cookieOptions = cookieOptions;
    this.saveSettings();
  }

  saveSettings () {
    this.saveToStorage()
    this.cookiesAllowed$.next(this.cookieOptions);
    this.analyticsCookiesAllowed$.next(this.cookieOptions.analyticsCookies);
    this.facebookLoginAllowed$.next(this.cookieOptions.facebookAllowed);
    this.googleLoginAllowed$.next(this.cookieOptions.googleAllowed);
    this.updateFormValues();
  }

  saveToStorage() {
    this.storage.setObject('cookieOptions', this.cookieOptions);
  }

  ngOnDestroy () {
    if (this.storageSubscription?.unsubscribe) {
      this.storageSubscription.unsubscribe();
    }
  }
}
