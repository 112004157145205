import { Injectable } from '@angular/core';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Subject } from 'rxjs';

import { User } from './../models/user.model';
import { StorageService } from './storage/storage.service';

export class UserUpdateObject {
  user: User;
  permissions: any;
  loggedIn: boolean;
  communicationPreferences: CommunicationPreferencesObject;
  constructor (data) {
    Object.assign(this, data);
  }
}

export class GuestUserObject {
  firstName: string;
  lastName: string;
  name: string
  relationGroup?: string
  relationship?: string
}

export class CommunicationPreferencesObject {
  guestMemorials: MemorialCommunicationPreferencesObject[];
  moderatorMemorials: MemorialCommunicationPreferencesObject[];
  other: {
    news: boolean;
  }
}

export class MemorialCommunicationPreferencesObject {
  id: string;
  name: string;
  subscribed: boolean;
}

export class UserPermissionsObject {
  siteAdmin: boolean;
  admin: MemorialPermissionObject[];
  editor: MemorialPermissionObject[];
  constructor (data) {
    Object.assign(this, data);
  }
}

export class MemorialPermissionObject {
  createdAt: number;
  memorialId: string;
}

@Injectable()
export class UserStore {

  loggedIn: boolean = false;

  user: User;
  userPermissions: UserPermissionsObject;
  userObservable: BehaviorSubject<UserUpdateObject> = new BehaviorSubject<UserUpdateObject>(null);
  guestUserObservable: BehaviorSubject<GuestUserObject> = new BehaviorSubject<GuestUserObject>(null);
  loginStatusChange: Subject<any> = new Subject<any>();
  permissionsChange: Subject<UserPermissionsObject> = new Subject<UserPermissionsObject>();
  communicationPreferencesChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  siteAdmin: boolean = false;

  facebookCreds: any = {};
  fbLoginOptions = {
    scope: 'public_profile,email',
    return_scopes: true,
    auth_type: ''
  };

  guestUser: GuestUserObject;
  hasGuestUser: boolean = false;

  constructor (
    private storageService: StorageService) {

  }

  storeUser () {
    this.storageService.setObject('user', this.user);
  }

  setUser (user: User) {
    if (user) {
      let loggingIn = false;
      if (this.loggedIn === false) {
        loggingIn = true;
      }
      this.loggedIn = true;
      this.user = new User(user);
      if (loggingIn) {
        // ! need to wait to emit change so we don't get race conditions
        this.loginStatusChange.next(true);
      }
    } else {
      if (this.loggedIn === true) {
        this.loginStatusChange.next(true);
      }
      this.loggedIn = false;
      this.user = null;
    }
    this.emitUserChange(user);
    this.storeUser();
  }

  updateUser (update) {
    this.setUser(Object.assign({}, this.user, update));
    this.emitUserChange();
  }

  setUserPermissions (permissions: UserPermissionsObject) {
    if (!isEqual(this.userPermissions, permissions)) {
      this.permissionsChange.next(permissions);
    }
    this.userPermissions = permissions;
    this.emitUserChange();
  }

  setCommunicationPreferences (preferences: any) {
    this.communicationPreferencesChange.next(preferences);
  }

  emitUserChange (user?) {
    if ((user && user !== this.user) || !user) {
      this.userObservable.next(new UserUpdateObject({
        user: this.user,
        permissions: this.userPermissions,
        loggedIn: this.loggedIn
      }))
    }
  }

  clearGuest () {
    this.hasGuestUser = false;
    this.guestUser = new GuestUserObject;
    this.guestUserObservable.next(null);
  }

  
}
