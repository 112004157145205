<ng-template let-descriptionOverwrite="descriptionOverwrite" let-titleOverwrite="titleOverwrite">
  <div class="confirmation-wrapper" cdkTrapFocus cdkTrapFocusAutoCapture>
    <div class="content">
      <h3>{{titleOverwrite? titleOverwrite: title}}</h3>
      <p>{{descriptionOverwrite? descriptionOverwrite: description}}</p>
      <ng-content></ng-content>
    </div>
    <div class="buttons">
      <mem-button-cta class="button" (click)="action.emit();">
        {{actionText}}
      </mem-button-cta>
      <mem-button-cta class="button" (click)="cancel.emit();" cdkFocusInitial>
        {{cancelText}}
      </mem-button-cta>
    </div>
  </div>
</ng-template>