import { HelperService } from './../helper/helper.service';
import { RaygunService } from './../raygun/raygun.service';
import { MemoryStore } from './../../routes/memorial/memory.store';
import { AutoTracking, UserType } from './../../models/tracking/autoTracking.interface';
import { UserStore } from './../user.store';
import { MemorialStore } from './../memorial.store';
import { Injectable, Optional } from "@angular/core";

@Injectable()
export class AutoPropsService {
  props: AutoTracking;
  constructor (
    @Optional() private memoryStore: MemoryStore,
    private memorialStore: MemorialStore,
    private userStore: UserStore,
    private raygun: RaygunService,
    private helperService: HelperService
  ) {

  }

  getAutoProps () {
    try {
      if (this.memorialStore?.memorial) {
        this.props = { ...this.props, ...this.memorialStore.memorial.getTracking() }
        if (this.memorialStore.currentUserSignature) {
          this.props = { ...this.props, ...this.memorialStore.currentUserSignature.getTracking() }
        }
      }
    } catch (error) {
      this.raygun.sendError(new Error('problem auto tracking from memorialStore'), { error: this.raygun.stringifyError(error) })
    }
    try {
      if (this.userStore?.user) {
        this.props = { ...this.props, ...this.userStore.user.getTracking() }
        this.setUserType();
      }
    } catch (error) {
      this.raygun.sendError(new Error('problem auto tracking from userStore'), { error: this.raygun.stringifyError(error) })
    }

    try {
      if (this.memoryStore?.memory) {
        this.props = { ...this.props, ...this.memoryStore.memory.getTracking() }
      }
    } catch (error) {
      this.raygun.sendError(new Error('problem auto tracking from memoryStore'), { error: this.raygun.stringifyError(error) })
    }

    try {
      this.props = { ...this.props, appVersion: this.helperService.version.version }
    } catch (error) {
      this.raygun.sendError(new Error('problem auto tracking app version'), { error: this.raygun.stringifyError(error) })
    }

    return this.props;
  }

  setUserType () {
    this.props.userType = UserType.visitor;
    if (this.memorialStore.currentUserSignature) {
      this.props.userType = UserType.member;
    }
    if (this.userStore.guestUser) {
      this.props.userType = UserType.guest;
    }
    if (this.userStore.user) {
      let user = this.userStore.user;
      if (user.permissions && user.permissions.siteAdmin) {
        this.props.siteAdmin = UserType.siteAdmin;
      }
      if (user.permissions && user.permissions.admin) {
        this.props.userType = UserType.moderator;
      }
      if (user.permissions && user.permissions.editor) {
        this.props.editor = true;
      }
    }
  }
}
