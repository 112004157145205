<div class="confirmation" [attr.aria-expanded]="active" cdkTrapFocus cdkTrapFocusAutoCapture>
  <div class="confirmation-wrapper">
    <div class="content">
      <h3>{{title}}</h3>
      <p>{{description}}</p>
      <ng-content></ng-content>
    </div>
    <div class="buttons">
      <mem-button-cta class="button" (click)="action.emit();">
        {{actionText}}
      </mem-button-cta>
      <mem-button-cta class="button" (click)="cancel.emit();" cdkFocusInitial>
        {{cancelText}}
      </mem-button-cta>
    </div>
  </div>
</div>