<header class="main-header">
  <div class="header-content">
    <a tabindex="0" href="#main-content" class="visually-hidden skip2main" style="display: block;">Skip To Main Content</a>
    <div class="header-inner" role="banner">
      <div class="button-group" role="menubar">
        <button class="circle-container" [matMenuTriggerFor]="hamMenu" tabindex="0" role="menuitem"
          aria-label="Main Menu">
          <div id="headerHamburgerMenu" class="circle-button" role="button" aria-label="menu">
            <span class="icon icon-menu" role="img" aria-hidden="true"></span>
            <span class="visually-hidden">Menu</span>
          </div>
          <mat-menu #hamMenu="matMenu" focusFirstItem>
            <ng-container>
              <div class="menu-sub" role="note">
                <span role="note">WE REMEMBER</span>
              </div>
              <a routerLink="/search">
                <button class="header-menu-button" mat-menu-item aria-label="Find Memorials">
                  <div id="th-searchOut" class="list-item" style="min-width: 300px;" role="menuItem">
                    <span class="icon gold med icon-search" aria-hidden="true"></span> Find Memorials
                  </div>
                </button>
              </a>
              <a routerLink="/create-page">
                <button class="header-menu-button" mat-menu-item aria-label="Start a New Page">
                  <div class="list-item" role="menuItem">
                    <span class="icon gold med icon-plus-alt" aria-hidden="true"></span> Start a New Page
                  </div>
                </button>
              </a>
              <a routerLink="/">
                <button class="header-menu-button" mat-menu-item aria-label="We Remember Home">
                  <div class="list-item no-border" id="th-visitMainOut" role="menuItem">
                    <span class="icon gold med icon-link" aria-hidden="true"></span> We Remember Home
                  </div>
                </button>
              </a>
            </ng-container>
            <div class="menu-sub" role="note">
              <span role="note">GET HELP</span>
            </div>
            <a routerLink="/help-center">
              <button class="header-menu-button" mat-menu-item aria-label="Help Center">
                <div id="th-faqOut" class="list-item" role="menuItem">
                  <span class="icon gold med icon-help" aria-hidden="true"></span> Help Center
                </div>
              </button>
            </a>
            <a routerLink="/contact">
              <button class="header-menu-button" mat-menu-item aria-label="Contact Us">
                <div class="list-item" id="th-visitMainOut" role="menuItem">
                  <span class="icon gold med icon-email" aria-hidden="true"></span> Contact Us
                </div>
              </button>
            </a>
              <button class="header-menu-button" mat-menu-item aria-label="Manage Cookies" onclick="_sp_.gdpr.loadPrivacyManagerModal('738538')">
                <div class="list-item" id="th-visitMainOut" role="menuItem">
                  <span class="icon gold med icon-settings" aria-hidden="true"></span> Manage Cookies
                </div>
              </button>
          </mat-menu>
        </button>
        <button class="circle-container" [class.demo]="demoMode" tabindex="0"
          (keyup.enter)="toggleSearch({focus: true})" role="menuitem" aria-label="Show Search" *ngIf="searchButton">
          <div class="circle-button" (click)="toggleSearch({focus: true})" role="button" aria-label="search">
            <span class="icon icon-search" aria-hidden="true"></span>
          </div>
        </button>
      </div>
      <div id="headerLogo" class="logoWrapper" role="img">
        <a routerLink="/">
          <img src="https://assets.weremember.com/image/upload/f_auto/v1573676759/logoCombined_skssfy.png"
            alt="We Remember Logo" class="wrLogo">
        </a>
      </div>
      <div class="button-group" [class.end]="!store.memorial" *ngIf="!demoMode">
        <div id="notifications-portal" style="width: 100px"></div>
        <div class="circle-container">
          <span id="signin-button" *ngIf="!userStore.user && !demoMode">
            <button id="headerSignInButton" class="circle-button" location="header" [guest]="false" mem-sign-in-button
              title="Sign in or register">
              <span class="icon icon-person" aria-hidden="true"></span>
            </button>
          </span>
          <button id="headerProfileMenuButton" class="logged-in circle-button" *ngIf="userStore.user"
            [matMenuTriggerFor]="profileMenu" aria-label="Profile Menu">
            <mem-person [signature]="userStore.user" [size]="personSize" [text]="false"></mem-person>
          </button>
          <mat-menu id="headerPofileMenu" #profileMenu="matMenu" focusFirstItem>
            <div class="menu-sub" role="note">
              <span>My Memorials</span>
            </div>
            <a [routerLink]="['/my-pages']" (keydown.enter)="router.navigate(['/my-pages'], {relativeTo: route})">
              <button id="th-myMemorials" class="header-menu-button" (click)="trackMyPages()" mat-menu-item
                aria-label="My Pages">
                <div class="list-item no-border" role="menuitem">
                  <span class="icon gold med icon-page" aria-hidden="true"></span> My Pages
                </div>
              </button>
            </a>
            <div class="menu-sub" role="note">
              <span>My Account</span>
            </div>
            <a [routerLink]="['/edit-profile']" (keydown.enter)="router.navigate(['/edit-profile'], {relativeTo: route})">
              <button id="th-editProfile" class="header-menu-button" (click)="trackEditProfile()" mat-menu-item
                aria-label="Edit Profile">
                <div class="list-item" role="menuitem">
                  <span class="icon gold med icon-avatar" aria-hidden="true"></span> Profile
                </div>
              </button>
            </a>
            <a [routerLink]="['communication-preferences']" (keydown.enter)="router.navigate(['communication-preferences'], {relativeTo: route})">
              <button id="th-communicationPreferences" class="header-menu-button" mat-menu-item
                aria-label="Communication Settings">
                <div class="list-item" role="menuitem">
                  <span class="icon gold med icon-email" aria-hidden="true"></span> Communication Settings
                </div>
              </button>
            </a>
            <!-- <button id="th-cookieSettings" class="header-menu-button" (click)="cookieNoticeService.reopen()" (keyup.enter)="cookieNoticeService.reopen()" mat-menu-item>
              <div class="list-item" role="menuitem">
                <span class="icon gold med icon-exit" aria-hidden="true"></span> Review Cookie Settings
              </div>
            </button> -->
            <button id="th-signOut" class="header-menu-button" (click)="signOut()" mat-menu-item aria-label="Sign Out">
              <div class="list-item" role="menuitem">
                <span class="icon gold med icon-exit" aria-hidden="true"></span> Sign Out
              </div>
            </button>
          </mat-menu>
        </div>
      </div>
      <ng-container *ngIf="demoMode">
        <a [routerLink]="['/create-page']">
          <mem-button-cta iconLeft="icon-plus-alt" *screenSize="'notMobile'">CREATE A PAGE
          </mem-button-cta>
        </a>
        <a [routerLink]="['/create-page']">
          <mem-button-cta iconLeft="icon-plus-alt" *screenSize="'mobile'" [small]="true" mobileWidth="88"
            style="margin-left: -48px;">CREATE</mem-button-cta>
        </a>
      </ng-container>
    </div>
  </div>
</header>
<mem-search-bar *ngIf="showSearch">
  <mem-search-input [whiteBackground]="true" [autoFocus]="searchAutoFocus"></mem-search-input>
</mem-search-bar>