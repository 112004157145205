import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { mergeMap, delay, tap, filter } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Resolve, Router, RouterStateSnapshot, CanActivate } from '@angular/router';
import { map, Observable, of, take } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { UserStore, UserPermissionsObject } from './user.store';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { User } from '../models/user.model';

@Injectable()
export class AuthGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  navigationExtras: NavigationExtras = {
    queryParams: {}
  };
  guest: boolean = false;
  user: User;

  constructor (
    private userStore: UserStore,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService,
    private injector: Injector,
    private jwtHelper: JwtHelperService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  canActivate (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (isPlatformServer(this.platformId)) {
      //ToDo - once at Angular@13 ngx-cookie-service has SSR support and this.authService.getUserFromToken() can be relied on here instead
      let req = this.injector.get(REQUEST);
      if (req.cookies.id_token) {
        const token = this.jwtHelper.decodeToken(req.cookies.id_token);
        if (token?.user?.id) {
          this.user = token.user;
          return true
        }
      }
      this.router.navigate(['sign-in'], { queryParams: { 'redirectUrl': state.url, 'guest': this.guest } } as NavigationExtras);
      return false
    }
    if (isPlatformBrowser(this.platformId)) {
      this.authService.checkToken()
      let jwt = this.cookieService.get('id_token');
      if (jwt) {
        this.user = this.jwtHelper.decodeToken(this.cookieService.get('id_token')).user;
        if (this.user) {
          return this.userStore.userObservable.pipe(
            map((user) => {
              return user?.permissions
            }),
            filter(permissions => permissions),
            take(1),
            map((permissions: UserPermissionsObject) => {
              if (permissions) {
                return true
              } else {
                this.router.navigate(['sign-in'], { queryParams: { 'redirectUrl': state.url, 'guest': false } } as NavigationExtras);
                return false
              }
            }))
        } else {
          this.router.navigate(['sign-in'], { queryParams: { 'redirectUrl': state.url, 'guest': false } } as NavigationExtras);
          return false
        }
      } else {
        this.router.navigate(['sign-in'], { queryParams: { 'redirectUrl': state.url, 'guest': false } } as NavigationExtras);
        return false
      }

    }
  }

}
