import { ListItemComponent } from './../../memory-tile/memory-list/list-item/list-item.component'
import { MemorialPreviewComponent } from './../../memorial-preview/memorial-preview.component'
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, ViewChildren, QueryList, ViewContainerRef, ElementRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router'
import { of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators'
import { SearchResult } from './../../../../models/search/search-result.model'
import { SearchService } from './../../../../services/search/search.service'
import { UnsubscribeOnDestroyAdapter } from './../../../adapters/unsubscribe-adapter'
import { ListKeyManager, FocusKeyManager } from '@angular/cdk/a11y'
import { FocusElementDirective } from '../../../directives/focusElement.directive'


@Component({
  selector: 'mem-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  searchQuery: FormControl
  searchSubject: Subject<string> = new Subject<string>();
  searchFocused: boolean = false;
  resultsFocusedIndex: number = -1;
  searchPreview: any[]
  searchCount: number = null;
  searchResult: SearchResult
  doneLoading: boolean = false;

  @Input() placeholder: string = 'Find pages (John Smith Utah)...';
  @Input() whiteBackground: boolean = false;
  @Input() org: any
  @Input() location: any
  @Input() iframeOptions: any
  @Input() prefetch: boolean = true;
  @Input() pageSelect: boolean = false;
  @Input() autoFocus: boolean = false;

  @Output() selectedPage = new EventEmitter();

  @ViewChild('searchInput', { read: ElementRef }) searchInput: ElementRef
  @ViewChildren('result', { read: FocusElementDirective }) focusItems: QueryList<FocusElementDirective>
  resultsFocusIndex: number = -1;

  constructor (
    private route: ActivatedRoute,
    private router: Router,
    private componentRef: ChangeDetectorRef,
    private searchService: SearchService
  ) {
    super()
    this.searchQuery = new FormControl('')

    this.subs.sink = this.searchQuery.valueChanges
      .pipe(
        tap(() => {
          this.searchResult = null
          this.doneLoading = false
          this.removePreviewFocus()
        }),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(terms => {
          if (!terms || this.org) { //skip on org search
            return of(null)
          } else if (this.prefetch) {
            return this.searchService.getPreview(terms).pipe(
              // catch errors and handle them here
              catchError((err) => {
                console.log('error from search', err)
                return of(null)
              })
            )
          } else { // safety fallback
            return of(null)
          }
        })
      ).subscribe((results) => {
        if (!results) {
          this.searchResult = new SearchResult(null)
          if (this.searchQuery.value === '') {
            // if query is nothing, remove results altogether
            this.searchResult = null
            this.doneLoading = true
          }
        } else if (this.prefetch) {
          this.searchResult = new SearchResult(results)
          this.doneLoading = true
        }
        this.componentRef.detectChanges()
      }, err => {
        console.error(err)
      })
  }

  ngOnInit () {
    if (this.org || this.iframeOptions) {
      if (this.org && this.org.name) {
        this.placeholder = `Search ${this.org.name}...`
      }
      if (this.iframeOptions && this.iframeOptions.name) {
        let characterization = 'Obituaries'
        if (this.iframeOptions.characterization) {
          characterization = this.iframeOptions.characterization
        }
        this.placeholder = `Search ${this.iframeOptions.name} ${characterization}...`
      }
    }

    // blur if on results page
    this.subs.sink = this.route.queryParams.subscribe((params: Params) => {
      if (params['q']) {
        this.searchBlur()
      }
    }, (err) => {
      console.error(err)
    })
  }

  ngAfterViewInit () {
    if (this.autoFocus) {
      this.searchFocus()
    }
  }

  onKeydown (event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      this.focusItems?.get(this.resultsFocusIndex)?.blur()
      this.resultsFocusIndex++
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      this.focusItems?.get(this.resultsFocusIndex)?.blur()
      this.resultsFocusIndex--
    } else if (event.key === 'Enter' && this.resultsFocusIndex !== -1) {
      // Enter while focused on a result, close preview
      this.searchInput.nativeElement.blur()
      this.searchFocused = false
      event.preventDefault()
      this.focusItems.get(this.resultsFocusIndex).el.nativeElement.click()
    } else if (event.key === 'Enter') {
      // Enter while focused on search input
      this.goToResults()
    } else {
      return
    }
    // prevent focus from leaving search input
    if (this.resultsFocusIndex < 0) {
      this.resultsFocusIndex = -1
    }
    // prevent focus from going past last result
    if (this.focusItems.get(this.resultsFocusIndex) === undefined && this.resultsFocusIndex !== -1) {
      this.resultsFocusIndex = 0
    }
    this.focusItems?.get(this.resultsFocusIndex)?.focus()
  }

  removePreviewFocus () {
    this.resultsFocusIndex = -1
    this.focusItems?.get(this.resultsFocusIndex)?.blur()
  }

  goToResults () {
    // if the query has a length and not just hitting enter on null
    if (this.searchQuery.value.length > 0) {
      if (document && document.activeElement) {
        // try to deselect the search input
        try {
          (document.activeElement as HTMLElement).blur()
        } catch (error) {
          console.error('search tried to blur invalid element')
        }
      }
      let navigationExtras: NavigationExtras = {
        queryParams: { q: this.searchQuery.value }
      }
      if (this.org) { // contain org insanity
        // check for special types partner/group
        if (this.org.type && this.org.type === 'partner') {
          this.router.navigate([`/search/partner/${this.org.id}`], navigationExtras)
        } else if (this.org.code) {
          this.router.navigate([`/search/group/${this.org.code}`], navigationExtras)
        } else {
          this.router.navigate([`/search/${this.org.type}/${this.org.id}/${this.org.name}`], navigationExtras)
        }
      } else if (this.location) {
        this.router.navigate([`/search/location/US/${this.location}`], navigationExtras)
      } else if (this.iframeOptions) {
        navigationExtras.queryParams['id'] = this.iframeOptions.id
        navigationExtras.queryParams['t'] = this.iframeOptions.type
        navigationExtras.queryParams['s'] = this.iframeOptions.size
        // route to correct spot
        this.router.navigate([`/external/search`], navigationExtras)
      } else {
        this.router.navigate(['/search/results'], navigationExtras)
      }
      this.searchQuery.reset('')
      this.searchQuery.updateValueAndValidity()
      this.searchFocused = false
      this.componentRef.detectChanges()
    }
  }

  createPage () {
    this.router.navigate(['/create-page'])
    this.searchQuery.reset('')
    this.searchQuery.updateValueAndValidity()
  }

  searchBlur () {
    // blur will happen before click and then the click doesn't hit the correct target...
    if (this.org && this.org.name) {
      this.placeholder = `Search ${this.org.name}`
    } else if (this.iframeOptions && this.iframeOptions.name) {
      this.placeholder = `Search ${this.iframeOptions.name} Obituaries...`
    } else {
      this.placeholder = 'Find pages (John Smith Utah)...'
    }
    setTimeout(() => {
      this.componentRef.detectChanges()
    }, 150)
  }

  searchFocus () {
    this.resultsFocusedIndex = -1
    this.searchFocused = true
    this.searchInput.nativeElement.focus()
    this.placeholder = ''
  }

  searchClear () {
    this.searchQuery.reset('')
    if (this.org) {
      if (this.org.type && this.org.type === 'partner') {
        this.router.navigate([`/search/partner/${this.org.id}`])
      } else if (this.org.code) {
        this.router.navigate([`/search/group/${this.org.code}`])
      } else {
        this.router.navigate([`/search/${this.org.type}/${this.org.id}/${this.org.name}`])
      }
    } else if (this.location) {
      this.router.navigate([`/search/location/US/${this.location}`])
    } else if (this.iframeOptions) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          id: this.iframeOptions.id,
          t: this.iframeOptions.type,
          s: this.iframeOptions.size
        }
      }
      this.router.navigate(['/external/search'], navigationExtras)
    }
    this.searchFocus()
  }

  pageClick (page) {
    this.searchFocused = false
    if (this.pageSelect) {
      this.selectedPage.emit(page)
    } else {
      this.router.navigate([`/${page.seoName}`])
    }
  }
}
