import { Component, EventEmitter, Inject, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { CustomOverlayRef, CUSTOM_OVERLAY_DATA } from './../../../routes/memorial/services/custom-overlay.service';
import { AnalyticsService } from './../../../services/analytics/analytics.service';
import { BaseDialogComponent } from './../dialog/base-dialog.component';

let noScroll = require('no-scroll');

@Component({
  selector: 'mem-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {

  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Input('actionText') actionText: string = 'CONFIRM';
  @Input('cancelText') cancelText: string = 'CANCEL';
  @Input('title') title: string = 'Are you sure?';
  @Input('description') description: string = 'Are you sure you want to discard your changes?';

  _active: boolean = false;
  @Input()
  set active (active: boolean) {
    this._active = active;
    if (active && typeof document !== 'undefined') {
      noScroll.on();
    } else {
      noScroll.off();
    }
  }
  get active () { return this._active; }

  constructor (private analyticsService: AnalyticsService) {

  }

}

@Component({
  selector: 'mem-confirmation-section-stack',
  templateUrl: './confirmation.section-stack.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationSectionStackComponent extends ConfirmationComponent {

  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;

  _title: string = 'Are you sure?';
  _description: string = 'Are you sure you want to discard your changes?';

}

@Component({
  selector: 'mem-confirmation-dialog',
  templateUrl: './confirmation.dialog.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationDialogComponent extends BaseDialogComponent {
  submitting: boolean = false;
  actionText: string;
  cancelText: string;
  title: string;
  description: string;
  ref: CustomOverlayRef;

  constructor (@Inject(CUSTOM_OVERLAY_DATA) private data: any) {
    super();
    this.title = this.data?.title || 'Are you sure?';
    this.description = this.data?.description || 'Are you sure you want to discard your changes?';
    this.actionText = this.data?.actionText || 'CONFIRM';
    this.cancelText = this.data?.cancelText || 'CANCEL';
  }

  buttonClick (event: boolean) {
    if (event) {
      this.submitting = true;
    }
    this.close.next(event);
    this.close.complete();
  }
}
