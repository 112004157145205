import { RaygunStubService } from './../services/raygun/raygun.service.stub';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { ExternalService } from '../services/external/external.service';
import { MemorialStore } from '../services/memorial.store';
import { UserStore } from '../services/user.store';
import { GuestbookService } from './../routes/memorial/services/guestbook.service';
import { AnalyticsService } from './../services/analytics/analytics.service';
import { AutoPropsService } from './../services/analytics/autoProps.service';
import { AuthService } from './../services/auth/auth.service';
import { CanDeactivateGuard } from './../services/canDeactivate.guard';
import { HelperService } from './../services/helper/helper.service';
import { HttpService } from './../services/http/http.service';
import { LoaderService } from './../services/loader/loader.service';
import { NotificationService } from './../services/notification/notification.service';
import { PartnerService } from './../services/partner/partner.service';
import { RaygunService } from './../services/raygun/raygun.service';
import { SignInService } from './../services/sign-in/sign-in.service';
import { StorageService } from './../services/storage/storage.service';
import { ToastService } from './../services/toast/toast.service';
import { LocalStorageAdapter } from './adapters/local-storage';
import { AnalyticsResolver } from './resolvers/analytics.resolver';
import { CanonicalResolver } from './resolvers/canonical.resolver';

export const jwtOptionsFactory = (cookieService) => ({
  tokenGetter: () => {
    return cookieService.get('id_token')
  },
  whitelistedDomains: ['werememberdev.com:4200']
});

@NgModule({
  imports: [
    MatSnackBarModule,
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [CookieService]
      }
    })
  ],
  providers: [
    AnalyticsService,
    AutoPropsService,
    AuthService,
    HttpService,
    LoaderService,
    LocalStorageAdapter,
    SignInService,
    StorageService,
    (environment.isServer ? RaygunStubService : RaygunService),
    HelperService,
    PartnerService,
    CookieService,
    AnalyticsResolver,
    ToastService,
    MemorialStore,
    UserStore,
    ExternalService,
    GuestbookService,
    CanDeactivateGuard,
    CanonicalResolver,
    NotificationService
  ]
})
export class StartingServicesModule {
}
