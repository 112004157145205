import { AdService } from './ad.service'
import { ButtonModule } from './../buttons/button.module'
import { MatRadioModule } from '@angular/material/radio'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AdComponent } from './ad.component'
import { AncestryQueryDirective } from './ancestry.ad.directive'
import { StickyAdComponent } from './sticky-ad.component'
import { InputModule } from '../inputs/input.module'
import { TipModule } from '../tip/tip.module'
import { AdSidebarComponent } from './ad-sidebar/ad-sidebar.component'


@NgModule({
  imports: [CommonModule, MatRadioModule, ButtonModule, InputModule, FormsModule, TipModule],
  declarations: [AdComponent, AncestryQueryDirective, StickyAdComponent, AdSidebarComponent],
  exports: [AdComponent, AncestryQueryDirective, StickyAdComponent, AdSidebarComponent],
  providers: [
    AdService
  ]
})
export class AdModule { }
