import { TransferState, makeStateKey } from '@angular/platform-browser'
import { Injectable } from '@angular/core'

import { TrackingName } from './../../models/tracking/trackingEvent.model'
import { TrackingOptions } from './../../models/tracking/trackingOptions.interface'

@Injectable()
export class AnalyticsService {

  previous: any = { referrer: '', path: [] }

  constructor (
    private cache: TransferState,
  ) {
    if (this.cache.get<string>(makeStateKey('referrer'), null)) {
      this.previous.referrer = this.cache.get(makeStateKey('referrer'), null)
    }
  }


  register (properties: Object) {
    // stub for future tracking event registration
  }

  sendTrackingEvent (name: TrackingName, options: TrackingOptions) {
    // stub for future tracking events
  }

  updatePrevious (path: string) {
    try {
      if(this.previous.path.length >= 5) {
        // keep the last 5 paths
        this.previous.path.shift()
      }
      this.previous.path.push(path)
    } catch (error) { }
  }
}

