import { BaseDialogComponent } from './base-dialog.component'
import { BaseDialogWrapperComponent } from './base-dialog-wrapper.component'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ButtonModule } from './../buttons/button.module'
import { DialogFooterComponent } from './dialog-footer/dialog-footer.component'
import { DialogHeaderComponent } from './dialog-header/dialog-header.component'
import { A11yModule } from '@angular/cdk/a11y'

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    A11yModule
  ],
  declarations: [
    DialogHeaderComponent,
    DialogFooterComponent,
    BaseDialogWrapperComponent,
    BaseDialogComponent
  ],
  exports: [
    DialogHeaderComponent,
    DialogFooterComponent,
    BaseDialogWrapperComponent,
    BaseDialogComponent
  ],
  providers: []
})

export class DialogComponentsModule {
}
