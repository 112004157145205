import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { RaygunService } from '../../../../services/raygun/raygun.service';

@Component({
  selector: 'mem-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements AfterViewInit, OnChanges {

  @Input('doneLoading') doneLoading: boolean = false;
  @Input('allowTimeout') allowTimeout: boolean = false;
  timeout: boolean = false;
  timeoutRef;

  constructor (private raygunService: RaygunService) {

  }

  ngOnChanges () {
    if (this.doneLoading = false) {
      this.timeout = false;
      this.startTimer();
    }
  }

  ngAfterViewInit () {
    this.startTimer();
  }
  ngOnDestroy () {
    clearTimeout(this.timeoutRef)
  }

  startTimer () {
    if (typeof window !== 'undefined' && this.allowTimeout && !this.doneLoading) {
      this.timeoutRef = setTimeout(() => {
        this.timeout = true;
      }, 30000);
    }
  }

  refresh () {
    if (typeof window !== 'undefined') {
      this.raygunService.rg4js('send', new Error('LOADING TIMEOUT: user refreshed after loading timeout'));
      window.location.reload();
    }
  }
}
