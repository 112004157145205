import { DirectivesModule } from './../../directives/directives.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { ButtonModule } from './../buttons/button.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { PersonModule } from './../person/person.module';
import { SearchBarModule } from './../search-bar/search-bar.module';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    PersonModule,
    SearchBarModule,
    ButtonModule,
    NotificationsModule,
    DirectivesModule,
    NotificationsModule
  ],
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ],
  providers: []
})

export class HeaderModule {
}
