import { Component, AfterViewInit } from '@angular/core'

@Component({
  selector: 'mem-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
  landingPage: boolean = true;
  resultsPage: boolean = false;

}
