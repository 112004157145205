import { EventEmitter, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { filter, find, findIndex, forEach, isEmpty, isEqual, pull, uniqBy } from 'lodash-es';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { MemorialStatus } from '../models/memorial-status.model';
import { Memorial } from '../models/memorial.model';
import { Memory } from '../models/memory.model';
import { Signature } from '../models/signature.model';
import { Comment } from './../models/comment.model';
import { MemoryDetails } from './../routes/memorial/memory.store';
import { UnsubscribeOnDestroyAdapter } from './../shared/adapters/unsubscribe-adapter';
import { UserStore, UserUpdateObject } from './user.store';

@Injectable()
export class MemorialStore extends UnsubscribeOnDestroyAdapter {
  facebookShareImage: string = 'https://assets.weremember.com/image/upload/l_text:Source%20Sans%20Pro_54_bold_center_letter_spacing_-1_line_spacing_-10:{{fullName}},co_rgb:3E3E3E/fl_layer_apply,y_-70,x_0/c_fill,w_1032,h_538,g_north/f_auto,q_auto/v1630096212/assets/facebookDefault.png';

  status: MemorialStatus;
  statusObservable: BehaviorSubject<MemorialStatus> = new BehaviorSubject<MemorialStatus>(null);

  memorial: Memorial;
  memorialObservable: BehaviorSubject<Memorial> = new BehaviorSubject<Memorial>(null);

  memories: Memory[] = [];
  memoriesObservable: ReplaySubject<Memory[]> = new ReplaySubject();
  memoryCount: number = 0;
  memoryList: any[] = [];
  memoryListObservable: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

  mediaObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  media: any = [];
  mediaMap: any;

  guestbook: Signature[] = [];
  guestbookObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  guestbookCount: number = 0;
  guestbookGroups: any = {};
  guestbookPendingSignatures: Signature[] = [];

  blockedContent: any;
  reportedContent: any[] = [];
  pendingContent: any[] = [];

  refresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  doAdminStuff: EventEmitter<boolean> = new EventEmitter<boolean>();
  likeRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentUserSignature: Signature;
  userSignatureObservable: BehaviorSubject<Signature> = new BehaviorSubject<Signature>(null);


  adminPrivileges: boolean = false;
  moderator: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  questions: any;
  questionsObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  memorialRoute: any = {};

  constructor (
    private userStore: UserStore,
    private route: ActivatedRoute,
    private meta: Meta,
    private title: Title,
  ) {
    super();
    this.subs.sink = this.userStore.userObservable.subscribe(
      (uuo: UserUpdateObject) => {
        this.setMemorialStatus();
        this.checkSignatureUpdateNecessary();
        this.checkMemoryOwnership();
        if (this.userStore.userPermissions) {
          this.checkAdminPrivileges();
        }
      },
      err => {
        console.error(err);
      }
    );
    this.subs.sink = this.userStore.loginStatusChange.subscribe(
      event => {
        if (event && this.memorial && this.memorial.id) {
          this.refresh.next(true);
        }
      },
      err => {
        console.error(err);
      }
    );
  }

  checkMemoryOwnership () {
    //for ssr pages, we might have got the memories before we had the user, so update ownership
    if(this.userStore.user && this.userStore.user.id) {
      this.memories.forEach(memory => {
        if (memory.userId === this.userStore.user.id && !memory.isOwner) {
          memory.isOwner = true;
          this.updateMemory(memory);
        }
      })
    }
  }

  checkAdminPrivileges () {
    let previous = this.adminPrivileges;
    if (this.userStore.userPermissions && this.memorial) {
      if (this.userStore.userPermissions) {
        if (this.userStore.userPermissions.siteAdmin === true) {
          this.adminPrivileges = true;
        } else if (this.userStore.userPermissions.editor || this.userStore.userPermissions.admin) {
          if (
            find(this.userStore.userPermissions.editor, { memorialId: this.memorial.id }) ||
            find(this.userStore.userPermissions.admin, { memorialId: this.memorial.id })
          ) {
            this.adminPrivileges = true;
          } else {
            this.adminPrivileges = false;
          }
        } else {
          this.adminPrivileges = false;
        }
      } else {
        this.adminPrivileges = false;
      }
      // set isModerator true/false
      // if moderator then
      if (previous !== this.adminPrivileges) {
        if (this.adminPrivileges) {
          this.moderator.next(true);
          this.doAdminStuff.next(true);
        } else {
          this.moderator.next(false);
        }
      }
    }
  }

  setMemorial (memorial: Memorial) {
    this.memorial = memorial;
    this.memorialObservable.next(memorial);
    this.tagMemorial(this.memorial);
    this.setMemorialStatus();
    this.setMemorialRoute();
  }

  setQuestions (questions: any) {
    this.questions = questions;
    this.questionsObservable.next(this.questions);
  }

  setMemories (response: any) {
    const memories = uniqBy(response['memories'], (memory: Memory) => {
      return memory.contentId;
    });
    if (!isEqual(JSON.stringify(this.memories), JSON.stringify(memories))) {
      this.memories = memories;
      this.memoryCount = response['count'];
      this.memoryList = response['memoryList'];
      this.memoryListObservable.next(response['memoryList']);
    }
    this.memoriesObservable.next(Object.assign([], this.memories));
  }

  setMedia (response: any) {
    const media = response.media;

    if (media.length === 0) {
      this.mediaObservable.next([]);
    } else {
      this.media = media;
      this.mediaMap = response.mediaMap;
      this.mediaObservable.next(this.media);
    }
  }

  clearMemories () {
    this.memories = []; // should be re-initialized to original state not null
    this.media = []; // should be re-initialized to original state not null
    this.memoriesObservable.next(Object.assign([], this.memories));
    this.mediaObservable.next(this.media);
  }

  addMemory (memory: Memory) {
    memory['prepend'] = true;
    if (!this.memories) {
      this.memories = [];
    }
    if (memory.media && memory.media.length > 0) {
      forEach(memory.media, media => {
        this.media.unshift(media);
      })
    }
    this.memories.unshift(memory);
    this.memoryCount++;
    this.memoriesObservable.next(Object.assign([], this.memories));
  }

  updateMemory (memory: Memory) {
    const index = findIndex(this.memories, mem => {
      return mem.id === memory.contentId || mem.contentId === memory.contentId;
    });
    if (index > -1) {
      //update the memory
      this.memories[index] = memory;
    }
    this.memoriesObservable.next(Object.assign([], this.memories));
  }

  updateMemoryComments (contentId: string, details: MemoryDetails) {
    const index = findIndex(this.memories, mem => {
      return mem.id === contentId || mem.contentId === contentId;
    });
    if (index > -1) {
      //update the memory details
      let memory = this.memories[index];
      memory.comments = details.comments
      this.memories[index] = memory;
    }
    this.memoriesObservable.next(Object.assign([], this.memories));
  }

  removeMemory (memory: Memory) {
    const index = findIndex(this.memories, mem => {
      return mem.id === memory.contentId || mem.contentId === memory.contentId;
    });
    if (index > -1) {
      //remove the memory
      this.memories.splice(index, 1);
      this.memoryCount--;
    }
    setTimeout(() => {
      this.memoriesObservable.next(Object.assign([], this.memories));
    })
  }

  upsertComment (comment: Comment) {
    const index = findIndex(this.memories, mem => {
      return mem.contentId === comment.parentId;
    });
    if (index > -1) {
      let memory = this.memories[index];
      memory.children = memory.children || []; //init comments array if needed
      let comments = memory.children;
      let commentIndex = findIndex(memory.children, child => {
        return (
          (child.contentId && comment.contentId && child.contentId === comment.contentId) ||
          (child.id && comment.contentId && child.id === comment.contentId)
        );
      });
      if (commentIndex > -1) {
        // Comment exists
        if (comment.status !== 'active') {
          // remove comment
          comments.splice(commentIndex, 1);
          //remove child comments...
          memory.children = comments.filter(item => item.parentCommentId !== comment.contentId);
        } else {
          comments[commentIndex] = comment;
        }
      } else {
        comments.push(comment);
      }
      this.memoriesObservable.next(Object.assign([], this.memories));
    }
  }

  likeMemory (memoryId: string, userId: string, liked: boolean) {
    const index = findIndex(this.memories, mem => mem.contentId === memoryId);
    if (index > -1) {
      let memory = this.memories[index];
      if (liked) {
        if (!memory.likes) {
          memory.likes = [];
        }
        memory['hasLiked'] = true;
        memory.likes.push({ userId });
      } else {
        if (memory.likes) {
          let likeIndex = findIndex(memory.likes, like => like.userId === userId);
          if (likeIndex > -1) {
            memory['hasLiked'] = false;
            memory.likes.splice(likeIndex, 1);
          }
        }
      }
      this.likeRefresh.next(true)
    }
  }

  likeComment (memoryId: string, commentId: string, userId: string, like: any) {
    const index = findIndex(this.memories, mem => mem.id === memoryId);
    if (index > -1) {
      let memory = this.memories[index];
      let commentIndex = findIndex(memory.children, com => com.id === commentId);
      if (commentIndex > -1) {
        let comment = memory.children[commentIndex];
        if (like.userId !== undefined) {
          if (!comment.likes) {
            comment.likes = [];
          }
          comment['hasLiked'] = true;
          comment.likes.push(like);
        } else {
          if (comment.likes) {
            let likeIndex = findIndex(comment.likes, like => like['userId'] === userId);
            if (likeIndex > -1) {
              comment['hasLiked'] = false;
              comment.likes.splice(likeIndex, 1);
            }
          }
        }
      }
    }
  }

  updateChildren (memoryId: string, child: any, remove?: boolean) {
    const index = findIndex(this.memories, mem => mem.id === memoryId);
    if (index > -1) {
      let memory = this.memories[index];
      if (!remove) {
        if (!memory.children) {
          memory.children = [];
        }
        memory.children.push(child);
      } else {
        if (memory.children) {
          let childIndex = findIndex(memory.children, item => item.id === child.id);
          console.log('index', childIndex);
          if (childIndex > -1) {
            memory.children.splice(childIndex, 1);
          }
        }
      }
    }
  }

  setGuestbook (response: any) {
    const { count, guestbook, guestbookGroups } = response;
    this.guestbookCount = count || 0;
    this.guestbook = guestbook || [];
    this.guestbookGroups = guestbookGroups || {};
    this.guestbookPendingSignatures = []; //reset to be re-done

    // setup pending signatures
    forEach(this.guestbook, guest => {
      if (guest && guest.status === 'pending') {
        this.guestbookPendingSignatures.push(guest);
      }
    });

    this.sendGuestbookUpdate();
  }

  groupHasGuests (name: string) {
    name = name.toLowerCase()
    return this.guestbookGroups[name] > 0;
  }

  getGuestsForGroup (group: string) {
    return filter(this.guestbook, guest => {
      return guest?.relationGroup?.toLowerCase() === group.toLowerCase();
    });
  }

  checkSignatureUpdateNecessary () {
    // update the guestbook if needed
    if (this.currentUserSignature && this.currentUserSignature.userId) {
      let idx = findIndex(this.guestbook, guest => {
        return guest.userId === this.currentUserSignature.userId;
      });
      if (idx > -1) {
        this.guestbook[idx] = this.currentUserSignature;
      } else {
        this.guestbook.unshift(this.currentUserSignature);
        const group = this.currentUserSignature.relationGroup;
        if(!this.guestbookGroups[group]) {
          this.guestbookGroups[group] = 1; //init group so it displays
        }
      }
      this.sendGuestbookUpdate();
    }
  }

  sendGuestbookUpdate () {
    this.setMemorialStatus();
    this.guestbookObservable.next({
      count: this.guestbookCount,
      guests: this.guestbook,
      pending: this.guestbookPendingSignatures
    });
  }

  setUserSignature (signature: Signature) {
    // if null, or a valid signature, set it.
    if (!signature || (signature && signature.userId)) {
      this.currentUserSignature = new Signature(signature);
      this.checkSignatureUpdateNecessary();
      this.sendSignatureUpdate();
    } else {
      // todo investigate any other cases
      // console.log('tried to set signature:', signature);
    }
  }

  sendSignatureUpdate () {
    this.userSignatureObservable.next(this.currentUserSignature);
  }

  setMemorialStatus () {
    //****** MEMORIAL STATUS OPTIONS ******
    //active, blocked, pending, demo.
    //Additionally, if the signed in user is blocked or reported we set the permission on the memorial to limit functionality as well. This means that sometimes the memorial status is changed based on the user status.

    //****** CONTRIBUTION RIGHTS OPTIONS ******
    //open, review, closed

    //****** GUEST STATUS OPTIONS ******
    //active, blocked, reported

    if (this.memorial) {
      let userStatus;

      if (this.userStore.user) {
        userStatus = 'guest';

        if (
          find(this.memorial.blocked, b => {
            return b.userId === this.userStore.user.id;
          })
        ) {
          userStatus = 'blocked';
        }
        if (
          this.currentUserSignature &&
          this.currentUserSignature.status &&
          userStatus == 'guest'
        ) {
          userStatus = this.currentUserSignature.status;
        }
        if (this.isEditor()) {
          userStatus = 'editor';
        }
      } else {
        userStatus = '';
      }

      this.status = {
        userStatus: userStatus,
        memorialStatus: this.memorial.status,
        contributionStatus: this.memorial.contributionRights,
        privateMemories: this.memorial.privateMemories
      };

      this.statusObservable.next(this.status);
    }
  }

  setBlockedContent (blockedContent) {
    this.blockedContent = blockedContent;
  }

  setReportedContent (reportedContent) {
    this.reportedContent = reportedContent;
  }

  setPendingContent (pendingContent) {
    this.pendingContent = pendingContent;
  }

  updatePendingOrReportedContent (content) {
    if (content.id) {
      let index = this.reportedContent.findIndex(item => item.id === content.id);
      if (index > -1) {
        this.reportedContent.splice(index, 1); // remove from reported
      } else {
        // check pending
        index = this.pendingContent.findIndex(item => item.id === content.id);
        if (index > -1) {
          this.pendingContent.splice(index, 1); // remove from pending
        }
      }
    }
  }

  clear () {
    this.status = null;
    this.statusObservable.next(null);
    this.memorial = null;
    this.memorialObservable.next(null);
    this.memoryCount = 0;
    this.guestbook = [];
    this.guestbookObservable.next(null);
    this.guestbookCount = 0;
    this.guestbookGroups = {};
    this.guestbookPendingSignatures = [];
    this.pendingContent = [];
    this.reportedContent = [];
    this.blockedContent = {};
    this.currentUserSignature = null;
    this.adminPrivileges = false;
    this.clearMemories();
  }

  //This function is intended to return true if the current user has signed the guestbook of the current memorial.
  guestbookSigned () {
    if ((this.currentUserSignature && !isEmpty(this.currentUserSignature)) || this.isModerator(false)) { //exclude site admins
      return true;
    } else {
      return false;
    }
  }

  //This function is intended to return true if the current user has signed the guestbook of the current memorial and is not pending.
  guestbookSignedNotPending () {
    if (
      (this.currentUserSignature &&
        !isEmpty(this.currentUserSignature) &&
        this.currentUserSignature.status === 'active') ||
      this.isModerator(false) // exclude site admins
    ) {
      return true;
    } else {
      return false;
    }
  }

  //This function is intended to return true if the current user is logged in and has signed the guestbook of the current memorial.
  isLoggedInAndGuestbookSign () {
    return this.userStore.loggedIn && this.guestbookSigned();
  }

  //This function is intended to return true if the current user is an editor on the current memorial
  isEditor (includeSiteAdmin = true): boolean {
    if (this.userStore.userPermissions) {
      if (includeSiteAdmin && this.userStore.userPermissions.siteAdmin === true) {
        return true;
      }
      if (this.userStore.userPermissions.editor || this.userStore.userPermissions.admin) {
        if (
          find(this.userStore.userPermissions.editor, { memorialId: this.memorial.id }) ||
          find(this.userStore.userPermissions.admin, { memorialId: this.memorial.id })
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isModerator (includeSiteAdmin = true) {
    if (!this.userStore.user || !this.memorial) return false; //removes race conditions
    return this.isEditor(includeSiteAdmin);
  }

  setMemorialRoute () {
    this.subs.sink = this.route.firstChild.params.subscribe(params => {
      const { memorial, shortId } = params;
      if (memorial && shortId) {
        this.memorialRoute = {
          memorial: memorial,
          shortId: shortId
        };
      }
    });
  }

  getTracking () {
    let tracking = {};
    if (this.memorial) {
      tracking = { ...tracking, ...this.memorial.getTracking() }
    }
    if (this.currentUserSignature) {
      tracking = { ...tracking, ...this.currentUserSignature.getTracking() }
    }
    return tracking;
  }

  tagMemorial (memorial: Memorial) {
    let image = this.getFacebookShareImage(memorial);
    let lifeRange;

    if (memorial.media[0]?.url) {
      image = memorial.getMemorialImage();
    }
    if (memorial.birthYear || memorial.deathYear) {
      lifeRange = ' (' + (memorial.birthYear ? memorial.birthYear : '') + '-' + (memorial.deathYear ? memorial.deathYear : '') + ') ';
    }
    if (memorial.living) {
      lifeRange = ''
    }
    if (memorial.test) {
      this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    }
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ property: 'og:title', content: 'Visit ' + memorial.getPossesiveName() + ' memorial page on We Remember' });
    this.meta.updateTag({ property: 'og:description', content: 'View and share memories about ' + memorial.getName() + '. ' + memorial.headline });
    this.meta.updateTag({ name: 'description', content: (memorial.provider ? 'Provided by ' + memorial.provider + ', ' : '') + (memorial.obituary ? memorial.obituary.substring(0, 180) : 'Celebrate the life of ' + memorial.getName() + '. Collect memories, photos, and more. Remember, together.') });

    if (!memorial.living) {
      this.title.setTitle(memorial.firstName + ' ' + memorial.lastName + (lifeRange ? lifeRange : '') + ' | Obituary');
    } else {
      this.title.setTitle(memorial.getName());
    }
  }

  getFacebookShareImage (memorial: Memorial) {
    return this.facebookShareImage.replace('{{fullName}}', encodeURI(memorial.getName()));
  }
}
