import { tap } from 'rxjs/operators';
import { UnsubscribeOnDestroyAdapter } from './../../shared/adapters/unsubscribe-adapter';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Notification {
  title: string;
  info: string;
  icon: string;
  complete: boolean
}

@Injectable()
export class NotificationService extends UnsubscribeOnDestroyAdapter {

  notificationCount: number = 0;
  contentNotification: boolean = false;
  notificationObservable: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([
    {
      title: 'Add Memories',
      info: 'Share a few memories—add a story, answer questions, or upload a photo',
      icon: 'icon-avatar',
      complete: false
    },
    {
      title: 'Join the Page',
      info: 'Add yourself to “Friends & Family” to show your support',
      icon: 'icon-speech',
      complete: false
    },
    {
      title: 'Invite More People',
      info: 'Who’s missing? Invite more friends, family, schoolmates, and coworkers',
      icon: 'icon-email-add',
      complete: false
    }
  ]);

  constructor () {
    super();
  }

  addNotification (notification: Notification) {
    let exists = false;
    let notifications = this.notificationObservable.getValue();
    notifications.forEach((item) => {
      if (item.title == notification.title) {
        exists = true;
      }
    })
    if (!exists) {
      this.notificationObservable.next(this.notificationObservable.getValue().concat([notification]))
    }
  }

  removeNotification (title: string) {
    let notifications = this.notificationObservable.getValue();
    notifications.forEach((item, index) => {
      if (item.title === title) {
        notifications.splice(index, 1);
      }
      this.notificationObservable.next(notifications);
    })
  }
}