import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qurl'
})
export class QUrlPipe implements PipeTransform {
  transform (value: string, args: string[]): any {
    if (value === null || value === undefined) {
      return "";
    } else {
      if (!/^https?:\/\//i.test(value)) {
        return 'http://' + value;
      } else {
        return value;
      }
    }

  }
}
