import { Injectable } from "@angular/core";
@Injectable()
export class LocalStorageAdapter {
  localStorageToken: any;
  sessionStorageToken: any;

  constructor () {
    if (typeof window === 'undefined') {
      this.localStorageToken = {
        getItem: () => {
          return false;
        },
        setItem: () => {
          return false;
        },
        removeItem: () => {
          return false;
        }
      }
      this.sessionStorageToken = {
        getItem: () => {
          return false;
        },
        setItem: () => {
          return false;
        },
        removeItem: () => {
          return false;
        }
      }
    } else {
      this.localStorageToken = window.localStorage;
      this.sessionStorageToken = window.sessionStorage;

    }
  }
}
