<div class="notice">
  <div class="close-button" *ngIf="!cookieNoticeService.inEU">
    <button buttonIcon iconLeft="icon-close" (click)="close.next(true)"
      type="button">CLOSE</button>
  </div>
  <div class="message">
    <p>We Remember uses cookies to create a better experience for you. We use cookies and similar technologies to
      enhance and
      personalise your browsing experience, analyse your use of our site, and to create a secure and effective site and
      manage your data across different devices.</p>
      <p>Click "Accept" or "Necessary" to go directly to the site or click "Manage cookies" to choose which types of cookies to accept while on the site.</p>
      <p> You can withdraw your consent for cookies at any time. Learn more in
        our <a target="_blank" rel="noopener" routerLink="/cookies">Cookie Policy</a>.</p>
  </div>
  <div class="buttons">
    <mem-button-cta style="margin-right: 24px;" mobileWidth="100"
      (click)="cookieNoticeService.accept(); close.next(true);" mobile="ACCEPT">ACCEPT ALL</mem-button-cta>
    <mem-button-cta style="margin-right: 24px;" mobileWidth="100"
      (click)="cookieNoticeService.reject(); close.next(true);" mobile="NECESSARY">NECESSARY ONLY</mem-button-cta>
    <mem-button-cta style="margin-right: 24px;" mobileWidth="100" (click)="openManage();" mobile="MANAGE">MANAGE COOKIES
    </mem-button-cta>
  </div>
</div>
