import { Directive, ElementRef, Input, OnChanges, Renderer2, HostListener, ChangeDetectorRef, SimpleChange, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[spinner]'
})
export class ButtonSpinnerDirective implements OnChanges {
  @Input('spinner') submitting: boolean;

  spinnerRef;

  constructor(private el: ElementRef, private renderer: Renderer2, private cd: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.submitting) {
      if (changes.submitting.currentValue) {
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'disabled');
        this.spinnerRef = this.renderer.createElement('i');
        this.renderer.appendChild(this.el.nativeElement, this.spinnerRef);
        this.renderer.addClass(this.spinnerRef, 'icon');
        setTimeout(() => {
          this.renderer.addClass(this.spinnerRef, 'icon-loading');
        }, 0);
        this.renderer.addClass(this.spinnerRef, 'spinner');
        this.renderer.addClass(this.el.nativeElement, 'submitting');
      } else if (changes.submitting.previousValue && !changes.submitting.currentValue) {
        this.renderer.removeClass(this.el.nativeElement, 'submitting');
        this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        this.renderer.removeChild('icon', this.spinnerRef);
      }
    }
  }
}
