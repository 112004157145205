import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from './../../shared/components/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor (private snackBar: MatSnackBar) { }

  public openToast(message: string, flavor?: any, action?: string, icon?: string) {
    return this.snackBar.openFromComponent(ToastComponent, {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: flavor,
      data: { message: message, action: action, icon: icon }
    });
  }

  public openWithDuration(message: string, duration: number, action?: string, icon?: string, flavor?: any) {
    return this.snackBar.openFromComponent(ToastComponent, {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: flavor,
      data: { message: message, action: action, icon: icon }
    });
  }
}
