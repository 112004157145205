import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mem-button-box',
  template: `
    <button type="button" [class.disabled]="disabled" [class.selected]="selected" [class.vertical]="vertical" [class.small-box]="small">
      <div class="check" [class.checked]="selected" *ngIf="check">
        <span class="icon icon-check" style="font-size: 12px; margin: 0px 0px 0px 2px;" *ngIf="selected"></span>
      </div>
      <span class="icon {{icon}}" *ngIf="icon" aria-hidden="true"></span>
      <ng-content></ng-content>
    </button>
  `,
  styleUrls: [`./button-box.component.scss`],
})
export class ButtonBoxComponent implements OnInit {
  @Input() icon: string = '';
  @Input() check: boolean = false;
  @Input() selected: boolean = false;
  @Input() vertical: boolean = false;
  @Input() small: boolean = false;
  @Input() disabled: boolean = false;

  ngOnInit() {

  }

}
