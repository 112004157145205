import { MatMenuModule } from '@angular/material/menu';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NotificationComponent } from './notification/notification.component';
import { NotificationsComponent } from './notifications.component';
import { DialogComponentsModule } from '../dialog/dialog-components.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    DialogComponentsModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatMenuModule
  ],
  declarations: [
    NotificationsComponent,
    NotificationComponent
  ],
  exports: [
    NotificationsComponent
  ],
  providers: []
})

export class NotificationsModule {
}
