import { forEach as _forEach, isString as _isString, startsWith as _startsWith, trim as _trim, uniqBy as _uniqBy } from 'lodash-es';

import { getCloudinaryUrl } from './helper/cloudinaryUrl';
import { dateToPieces, dateToYear } from './helper/dates';
import { unescapeHtml } from './helper/unescapeHtml';
import { AutoTracking } from './tracking/autoTracking.interface';

export class Memorial {
  arborDisabled: boolean;
  autoHeadline: string;
  autoObit: string;
  badges: string[] = [];
  blocked: any[] = [];
  birth: any = {};
  birthDate: any;
  birthYear: string;
  city: string;
  claimable: boolean = false;
  contributionRights: string;
  country: string;
  createdAt: string;
  creator: any;
  death: any = {};
  deathDate: any;
  deathYear: string;
  editors: any = {};
  externalId: string;
  fgLink: string = '';
  firstName: string;
  flowersDisabled: boolean = true;
  for: string;
  fullName: string;
  funeralHome: any = {};
  guestbook: any[] = [];
  guestbookCount: any = {};
  guestbookGroups: any = {};
  guestmodeDisabled: boolean;
  hasMemories: boolean = false;
  headline: string;
  id: string;
  image: string;
  imageExists: boolean = false;
  lastName: string;
  autoLinks: any[] = []
  relatedLinks: any[] = [];
  externalLinks: any[] = [];
  links: any[] = [];
  liveEvent: any;
  living: boolean = false;
  location: any;
  media: any = [];
  memoryCount: number = 0;
  middleName: string;
  modCount: number = 0;
  newspapers: any = {};
  nickname: string = '';
  obitOrHeadlineExists: boolean = false;
  obitAndHeadlineExists: boolean = false;
  obitHeight: string = '75px';
  obituary: string;
  obituaryAdded: boolean = false;
  overflow: boolean = false;
  owner: any = {};
  ownerless: boolean;
  partnerId: string;
  partner: any = {};
  postalCode: string;
  preferredName: string;
  prefix: string = '';
  privateMemories: boolean = false;
  provider: string;
  redirectUrl: string = '';
  region: string;
  selfOwned: boolean = false;
  seoName: string;
  showDates: boolean = true;
  showSponsor: boolean = false;
  status: string;
  suffix: string = '';
  test: boolean = false;
  theme: string = '#D6B66D';
  type: string = '';

  constructor (data?: any) {
    //Values from server
    if (data) {
      _forEach(data, (value, key) => {
        if (_isString(value)) {
          value = unescapeHtml(value);
        }
        this[key] = value;
      });

      if (data['editors']) {
        this.editors = _uniqBy(data.editors, editor => {
          return editor['userId'];
        })
      }

      //Add computed values
      this.fullName = [data.firstName, data.middleName, data.lastName].join(' ');
      // check for old dates first
      if (!data.birth || (data.birth && !data.birth.year && data.birthDate)) {
        // there is a birthDate, that hasn't been moved to the new format, let's do that
        this.birth = dateToPieces(data.birthDate);
      }
      if (!data.death || (data.death && !data.death.year && data.deathDate)) {
        // there is a deathDate, that hasn't been moved to the new format, let's do that
        this.death = dateToPieces(data.deathDate)
      }
      if (data.birth && data.birth.year) {
        // todo do we need this anymore?
        this.birthYear = data.birth.year;
      } else if (data.birthDate) {
        this.birthYear = dateToYear(data.birthDate);
      }
      if (data.death && data.death.year) {
        // todo do we need this anymore?
        this.deathYear = data.death.year;
      } else if (data.deathDate) {
        this.deathYear = dateToYear(data.deathDate);
      }
      if (data.externalId && _startsWith(data.externalId, 'fg:')) {
        let fgLink = {
          name: 'Find A Grave',
          url: `https://www.findagrave.com/memorial/${_trim(data.externalId, 'fg:')}`,
          image: 'https://assets.weremember.com/image/upload/f_auto/v1540915659/funerallogos/fg-logo.png'
        }
        this.autoLinks.unshift({ link: fgLink });
      }
      if (data.newspapers) {
        this.arborDisabled = true;
        _forEach(data.newspapers, newspaper => {
          let paper = { name: '', url: '', image: '' };
          if (newspaper.name) {
            paper.name = newspaper.name;
            if (newspaper.url) {
              paper.url = newspaper.url;
            }
            if (newspaper.image && !newspaper.image.url) {
              paper.image = newspaper.image;
            } else if (newspaper.image && newspaper.image.url) {
              paper.image = newspaper.image.url;
            }
            this.autoLinks.unshift({ link: paper });
          }
        })
      }
      if (data.funeralHome) {
        let home = { name: '', url: '', image: '' };
        home.name = data.funeralHome.name;
        if (data.funeralHome.url) {
          home.url = data.funeralHome.url;
        } else if (data.funeralHome.website) {
          home.url = data.funeralHome.website;
        }
        if (data.funeralHome.image && data.funeralHome.image.url) {
          home.image = data.funeralHome.image.url;
        } else if (data.funeralHome.image) {
          home.image = data.funeralHome.image;
        }
        this.autoLinks.unshift({ link: home });
        this.arborDisabled = true;

        if (!data.flowersDisabled) {
          if (data.funeralHome.flowersLink) {
            this.flowersDisabled = false;
          } else {
            this.flowersDisabled = true;
          }
        }
      }
      if (data.nickname) {
        this.preferredName = data.nickname;
      } else {
        this.preferredName = data.firstName;
      }

      if ((data.owner && data.owner.firstName) || (data.editors && data.editors.length > 0)) {
        if (data.owner && data.owner.firstName) {
          this.modCount = 1;
        }
        if (data.editors && data.editors.length > 0) {
          this.modCount = this.modCount + this.editors.length;
        }
      } else {
        this.modCount = 0;
        this.guestmodeDisabled = true;
      }

      if ((!data.owner || !data.owner.firstName)) {
        this.ownerless = true;
        if (data.externalId) {
          if (_startsWith(data.externalId, 'fg:')) {
            this.claimable = true; // claimable now applies to ownerless find a grave pages
          } else if (_startsWith(data.externalId, 'legacy:')) {
            this.claimable = true; // claimabale also applies to ownerless Legacy pages
          }
        }
      }

      if (this.media && this.media[0]) {
        this.imageExists = true;
      } else {
        this.imageExists = false;
      }

      if ((this.birthYear || this.deathYear) && !this.living) {
        this.showDates = true;
      } else {
        this.showDates = false;
      }

      this.image = this.getMemorialImage();

      this.obitOrHeadlineExists = (!!this.obituary || !!this.headline);

      this.obitAndHeadlineExists = !!this.obituary && !!this.headline;

    }

    if (this.partner) {
      if (this.partner.name) {
        this.provider = this.partner.name;
      }
      if (!this.partner.name && this.partner.sponsor && this.partner.sponsor.name && this.partner.sponsor.visibleAsSponsor) {
        this.showSponsor = true;
        this.provider = this.partner.sponsor.name;
      }
    }

    if (this.links && this.links.length > 0) {
      this.buildLinks();
    }
  }

  getName (part?: string) {
    switch (part) {
      case 'first':
        return this.firstName;
      case 'middle':
        return this.middleName;
      case 'last':
        return this.lastName;
      default:
        if (this.nickname) {
          if (this.middleName) {
            return [this.firstName, '"' + this.nickname + '"', this.middleName, this.lastName].join(' ');
          }
          return [this.firstName, '"' + this.nickname + '"', this.lastName].join(' ');
        } else if (this.middleName) {
          return [this.firstName, this.middleName, this.lastName].join(' ');
        } else {
          return [this.firstName, this.lastName].join(' ');
        }
    }
  }

  getMemorialImage () {
    if (this.media && this.media[0]) {
      return getCloudinaryUrl(['e_trim', 'c_fill,g_auto,w_400,h_400,f_auto', 'c_crop,g_custom', 'h_400,w_400'], this.media[0].filename, this.media[0].url);
    } else if (this.image) {
      return getCloudinaryUrl(['e_trim', 'c_fill,g_auto,w_400,h_400,f_auto', 'c_crop,g_custom', 'h_400,w_400'], this.image, this.image);
    } else {
      const initials = this.removeSpecialChars(this.firstName).charAt(0) + this.removeSpecialChars(this.lastName).charAt(0);
      return this.getMonogram(initials || 'WR');
    }
  }

  removeSpecialChars (str) {
    if (str) {
      return str.replace(/(?!\w|\s)./g, '')
        .replace(/\s+/g, ' ')
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
    } else {
      return '';
    }
  }

  getMonogram (initials) {
    initials = initials.toUpperCase();
    if (initials.match('[A-Z]{2}')) {
      //get monogram image if valid characters
      return `https://assets.weremember.com/image/upload/b_rgb:${this.theme.replace('#', '')},o_0/w_250,h_250,f_auto/l_text:Source%20Serif%20Pro_80:${initials},co_rgb:${this.theme === '#D6B66D' ? '474747': 'ffffff'}/sample.png`;
    } else {
      //default monogram
      return `https://assets.weremember.com/image/upload/b_rgb:${this.theme.replace('#', '')},o_0/w_250,h_250,f_auto/l_text:Source%20Serif%20Pro_80:WR,co_rgb:${this.theme === '#D6B66D' ? '474747': 'ffffff'}/sample.png`;
    }
  }

  buildLinks () {
    this.links.forEach((item, i) => {
      if (item.type && item.type.name == 'page') {
        this.relatedLinks.push({ link: item, index: i });
      } else {
        this.externalLinks.push({ link: item, index: i });
      }
    })
  }

  getPossesiveName () {
    let name = this.getName();
    return (name.toLowerCase().slice(-1) === 's') ? name + "'" : name + "'s";
  }

  getTracking (): AutoTracking {
    return new AutoTracking({
      memorialClaimable: this.claimable,
      memorialId: this.id,
      memorialLiving: this.living,
      memorialSeoName: this.seoName,
      memorialGuestbookCount: this.guestbookCount,
      memorialBadges: this.badges?.length,
      memorialTheme: this.theme,
      memorialMemoryCount: this.memoryCount,
      memorialModCount: this.modCount,
      memorialPrivateMemories: this.privateMemories,
      memorialGuestmodeDisabled: this.guestmodeDisabled,
      memorialLinkCount: this.links?.length,
      memorialObitHeight: this.obitHeight,
      memorialExternalId: this.externalId
    });
  }
}
