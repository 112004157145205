import { ButtonBoxOptions } from '../buttons/button-box-group/button-box-group.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, ChangeDetectorRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { MemorialStore } from '../../../services/memorial.store';

@Component({
  selector: 'mem-relationship-section',
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'ease-in', opacity: 0 }),
        animate('500ms', style({ transform: 'ease-in', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'ease-out', opacity: 1 }),
        animate('100ms', style({ transform: 'ease-out', opacity: 0 }))
      ])
    ]
    )
  ],
  templateUrl: './relationship-section.component.html',
  styleUrls: ['./relationship-section.component.scss'],
})
export class RelationshipSectionComponent implements OnInit {

  @Output() finished = new EventEmitter();
  @Input() pending: boolean = false;
  @Input() relationshipTitle: string = 'How do you know :name? Select a relationship to display with your memory.';
  @Input('self') self: boolean = false;
  @Input('form') form: FormGroup;
  @Input() stackRef: any;
  @Input() firstName: string;
  submitting: boolean = false;
  group: string = '';
  relationship: string = '';
  prompt: string = ''
  placeholder: string = '';
  groupIndex: number;
  groupStack: any = [
    {
      label: 'Family',
      icon: 'icon-house',
      value: 'family'
    },
    {
      label: 'Friend',
      icon: 'icon-coffee',
      value: 'friend'
    },
    {
      label: 'Work',
      icon: 'icon-office',
      value: 'work'
    },
    {
      label: 'School',
      icon: 'icon-book',
      value: 'school'
    },
    {
      label: 'Other',
      icon: 'icon-person',
      value: 'other'
    }
  ];
  buttonGroupOptions: ButtonBoxOptions[] = [
    {
      value: 'family',
      label: 'FAMILY',
      icon: 'icon-house',
      check: false,
      selected: false,
      vertical: false,
      small: false
    },
    {
      value: 'friend',
      label: 'FRIEND',
      icon: 'icon-coffee',
      check: false,
      selected: false,
      vertical: false,
      small: false
    },
    {
      value: 'work',
      label: 'WORK',
      icon: 'icon-office',
      check: false,
      selected: false,
      vertical: false,
      small: false
    },
    {
      value: 'school',
      label: 'SCHOOL',
      icon: 'icon-book',
      check: false,
      selected: false,
      vertical: false,
      small: false
    },
    {
      value: 'other',
      label: 'OTHER',
      icon: 'icon-person',
      check: false,
      selected: false,
      vertical: false,
      small: false
    }
  ];

  constructor (
    @Optional() private memorialStore: MemorialStore,
    private cd: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit () {
    this.setGroup(this.form.controls['relationGroup'].value, true)
    this.form.controls['relationGroup'].valueChanges.subscribe((value: string) => {
      this.setGroup(value);
    });
    if (this.memorialStore && this.memorialStore.memorial) {
      this.form.get('memorialId').setValue(this.memorialStore.memorial.id);
    }
    if (this.self) {
      this.groupStack.unshift({
        label: 'That\'s Me',
        icon: 'icon-person-alt',
        value: 'self'
      });
      this.buttonGroupOptions.unshift({
        value: 'self',
        label: 'THAT\'S ME',
        icon: 'icon-person-alt',
        check: false,
        selected: false,
        vertical: false,
        small: false
      });
    }
    if (this.data && this.data.relationshipTitle) {
      this.relationshipTitle = this.data.relationshipTitle
    }
    this.relationshipTitle = this.relationshipTitle.replace(':name', this.memorialStore?.memorial?.firstName || this.firstName);
  }

  ngOnDestroy () {

  }

  setGroup (group, keepClean?: boolean) {
    this.form.controls['relationship'].setValidators(null);
    switch (group) {
      case 'family':
        this.prompt = `I am ${this.memorialStore?.memorial?.firstName || this.firstName}’s... (OPTIONAL)`;
        this.placeholder = 'Son, Daughter, Grandson, Sister-in-law, etc...';
        this.groupIndex = 0;
        break;
      case 'friend':
        this.prompt = `I know ${this.memorialStore?.memorial?.firstName || this.firstName} from... (OPTIONAL)`;
        this.placeholder = 'Neighbor, Childhood Friend, Church, etc...';
        this.groupIndex = 1;
        break;
      case 'work':
        this.prompt = 'We worked at... (OPTIONAL)';
        this.placeholder = 'Name of Company...';
        this.groupIndex = 2;
        break;
      case 'school':
        this.prompt = 'We went to school at... (OPTIONAL)';
        this.placeholder = 'Name of School...';
        this.groupIndex = 3;
        break;
      case 'other':
        this.prompt = 'How were you connected?';
        this.placeholder = 'Friend of Family, Fan, etc...*';
        this.form.controls['relationship'].setValidators([Validators.required]);
        this.groupIndex = 4;
        break;
    }
    this.form.controls['relationship'].updateValueAndValidity();
    this.cd.detectChanges();

    if (!keepClean) {
      this.form.markAsDirty();
    }
    this.cd.markForCheck();
  }

  onSubmit () {
    if (this.stackRef && !this.stackRef.submitting) {
      this.finished.emit(null);
    }
  }
}
