import { ParseUrlPipe } from './parse-url.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { NewLineCommentsPipe } from './new-line-comments.pipe';
import { NewLinePipe } from './new-line.pipe';
import { QUrlPipe } from './qurl.pipe';
import { TimeSincePipe } from './time-since.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FilterPipe,
    NewLineCommentsPipe,
    NewLinePipe,
    QUrlPipe,
    TimeSincePipe,
    ParseUrlPipe
  ],
  exports: [
    FilterPipe,
    NewLineCommentsPipe,
    NewLinePipe,
    QUrlPipe,
    TimeSincePipe,
    ParseUrlPipe
  ],
  providers: []
})

export class PipesModule {
}
