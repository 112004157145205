import { AutoTracking } from './autoTracking.interface';
import { TrackingOptions } from './trackingOptions.interface';

export enum TrackingName {
  // Sign In
  LoginStart = 'Login Start',
  Login = 'Login',
  LoginAbandon = 'Login Abandon',
  Logout = 'Logout',
  EditProfile = 'Edit Profile',
  Unsubscribe = 'Unsubscribe',
  AddContent = 'Add Content',
  AddContentStart = 'Add Content Start',
  EditContent = 'Edit Content',
  ReportContent = 'Report Content',
  DeleteContent = 'Delete Content',
  BlockContent = 'Block Content',
  AbandonContent = 'Abandon Content',
  AbandonEditContent = 'Abandon Edit Content',
  ViewContent = 'View Content',
  ApproveContent = 'Approve Content',
  ViewUI = 'View UI',
  CreatePage = 'Create Page',
  EditPage = 'Edit Page',
  ReportPage = 'Report Page',
  DeletePage = 'Delete Page',
  Share = 'Share',
  AbandonShare = 'Abandon Share',
  JoinPage = 'Join Page',
  LeavePage = 'Leave Page',
  AddRelationship = 'Add Relationship',
  BecomeModerator = 'Become Moderator',
  Search = 'Search',
  SearchResults = 'Search Results',
  PageView = 'Page View',
  NextStepFlow = 'Next Step In Flow',
  EmailEvent = 'Email Event',
  NavigateExternal = 'Navigate External',
  ModeratorPageAction = 'Moderator Page Action',
  CreatePartner = 'Create Partner',
  ContactSubmission = 'Contact Page Submission',
  Navigate = 'Navigate',
  CloudinaryDelete = 'Cloudinary Delete',
  CloudinaryAdd = 'Cloudinary Add',
  Click = 'Click'
}

export class TrackingEvent {

  /**
   * Name  of tracking event
   */
  name: string;
  /**
   * Options  of tracking event
   */
  options: TrackingOptions;
  autoProps: AutoTracking;

  /**
   * Creates an instance of tracking event.
   * @param options
   */
  constructor (name: TrackingName, options: TrackingOptions, autoTrackingFactory: AutoTracking) {
    this.name = name;
    this.options = options;
    this.autoProps = autoTrackingFactory;
  }
}
