import { ThemeService } from './../../../../services/theme/theme.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: '[buttonIcon]',
  template: `
    <span [class.white]="white" [class.dark]="dark" [class.gold]="themeService.gold" [class.bold]="bold" [class.small]="small" [class.large]="large" #buttonContent>
      <span class="icon left {{iconLeft}}" [class.small]="small" *ngIf="iconLeft" aria-hidden="true"></span>
      <ng-content *ngIf="!showMobile"></ng-content>
      <ng-container *ngIf="showMobile">{{mobile}}</ng-container>
      <span class="icon right {{iconRight}}" [class.small]="small" *ngIf="iconRight" aria-hidden="true"></span>
    </span>
  `,
  styleUrls: [`./button-icon.component.scss`],
})
export class ButtonIconComponent implements OnInit {
  @Input('iconLeft') iconLeft: string = '';
  @Input('iconRight') iconRight: string = '';
  @Input('dark') dark: boolean = false;
  @Input('small') small: boolean = false;
  @Input('large') large: boolean = false;
  @Input('white') white: boolean = false;
  @Input() bold: boolean = true;
  @Input() mobile: string = '';
  @Input() tracking: object;
  gold: boolean = false;

  @ViewChild('buttonContent') buttonContent: ElementRef;

  showMobile = false;

  constructor (private breakpointObserver: BreakpointObserver,
    public themeService: ThemeService) { }

  ngOnInit () {


    // only run this breakpoint observer if mobile text override is included
    this.breakpointObserver.observe(['(max-width: 767px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          if (this.mobile) {
            this.showMobile = state.matches;
          }
        }
      });


  }
}
