import { HelperService } from './../../services/helper/helper.service';
import { TrackingOptions } from './../../models/tracking/trackingOptions.interface';
import { TrackingName } from './../../models/tracking/trackingEvent.model';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AnalyticsService } from './../../services/analytics/analytics.service';

@Injectable()
export class AnalyticsResolver implements Resolve<any> {
  constructor (private analyticsService: AnalyticsService, private helperService: HelperService) { }

  resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.helperService.isBrowser) {
      let routeOptions = {};
      routeOptions = route.data.tracking;
      let trackingOptions: TrackingOptions = {
        ...routeOptions
      }
      this.analyticsService.sendTrackingEvent(TrackingName.Navigate, trackingOptions);
    }
  }
}
