import { MatSelectModule } from '@angular/material/select'
import { DirectivesModule } from './../../directives/directives.module'
import { RouterModule } from '@angular/router'
import { SearchService } from './../../../services/search/search.service'
import { ButtonModule } from './../buttons/button.module'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LoadingModule } from '../loading/loading.module'
import { MemorialPreviewModule } from './../memorial-preview/memorial-preview.module'
import { SearchBarComponent } from './search-bar.component'
import { SearchInputComponent } from './search-input/search-input.component'
import { TipModule } from '../tip/tip.module'
import { A11yModule } from '@angular/cdk/a11y'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MemorialPreviewModule,
    LoadingModule,
    TipModule,
    ButtonModule,
    DirectivesModule
  ],
  declarations: [
    SearchBarComponent,
    SearchInputComponent
  ],
  exports: [
    SearchBarComponent,
    SearchInputComponent
  ],
  providers: [
    SearchService
  ]
})

export class SearchBarModule {
}
