import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PersonModule } from './../person/person.module';
import { MemorialPreviewComponent } from './memorial-preview.component';

@NgModule({
  imports: [
    CommonModule,
    PersonModule,
    RouterModule
  ],
  declarations: [
    MemorialPreviewComponent
  ],
  exports: [
    MemorialPreviewComponent
  ],
  providers: []
})

export class MemorialPreviewModule {
}
