import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable()
export class HttpService {
  constructor (
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  addApiToUrl (url) {
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
      url = environment.apiUrl + url;
    }
    return url;
  }

  get (url): Observable<any> {
    url = this.addApiToUrl(url);
    let request = this.getObservable(url);
    return request;
  }

  getObservable (url) {
    return new Observable((observer) => {
      this.http.get(url, { withCredentials: true }).pipe(
        distinctUntilChanged()
      ).subscribe((result) => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  post (url, data) {
    url = this.addApiToUrl(url);

    return this.http.post<any>(url, data, {
      observe: 'body',
      responseType: 'json',
      withCredentials: true,
    });
  }

  postWithProgress (url, data) {
    url = this.addApiToUrl(url);

    return this.http.post<any>(url, data, {
      observe: 'body',
      responseType: 'json',
      withCredentials: false,
      reportProgress: false
    })
  }

  put (url, data?) {
    url = this.addApiToUrl(url);

    return this.http.put<any>(url, data, {
      observe: 'body',
      responseType: 'json',
      withCredentials: true,
    });
  }

  delete (url, data?) {
    url = this.addApiToUrl(url);

    return this.http.delete(url, {
      withCredentials: true,
      responseType: 'json',
      body: data || {}
    });
  }
}
