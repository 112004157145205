import { AutoTracking } from './tracking/autoTracking.interface';
import {
  forEach as _forEach,
  isString as _isString
} from 'lodash-es';

import { unescapeHtml } from './helper/unescapeHtml';

export class User {
  avatar: string;
  created: Date;
  firstName: string;
  lastName: string;
  relationship: string;
  updated: Date;
  id: any;
  permissions: any;
  image: any;
  email: string;
  ancestryId: string;
  acceptTerms: any;
  acceptCookie: any;
  googleId: string;
  appleId: string;
  facebookId: string;
  sub?: string;

  constructor (data) {
    _forEach(data, (value, key) => {
      if (_isString(value)) {
        value = unescapeHtml(value);
      }
      this[key] = value;
    });
  }

  getTracking (): AutoTracking {
    return new AutoTracking({
      userAcceptCookie: this.acceptCookie,
      userImage: this.image,
      userCreatedAt: this.created ? new Date(this.created).toISOString() : null
    })
  }
}
