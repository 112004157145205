import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'mem-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class FooterComponent implements OnInit {
  @ViewChild('placeholder', { static: true }) placeholder: any;
  @ViewChild('footerContainer', { static: true }) footerContainer: any;
  seoFooter: boolean = false;
  footerHeight: number = 250;
  currentYear;

  constructor () {
  }

  ngOnInit () {
    this.currentYear = new Date().getFullYear();
  }

}
