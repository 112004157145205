<ng-template>
<div class="stack-heading" [class.invert]="sectionStack.modal">
  <h3 *ngIf="!sectionStack.active || hideClose" [class.white]="hideClose">{{title}}</h3>
  <ng-container *ngIf="sectionStack.active" [class.white-button]="sectionStack.modal">
    <button *ngIf="sectionStack.history.length > 1 && !forceCancel && !hideBack" [white]="active" iconLeft="icon-arrow-left" buttonIcon lineHeight="40" (click)="back()">BACK</button>
    <button *ngIf="sectionStack.namedActive && false && !forceCancel" [white]="active" iconLeft="icon-close" buttonIcon lineHeight="40" (click)="close()">CLOSE</button>
    <button *ngIf="(sectionStack.history.length == 1 && !hideClose) || forceCancel" [white]="active" iconLeft="icon-close" buttonIcon lineHeight="40" (click)="close()">CANCEL</button>
  </ng-container>
  <div class="right">
    <ng-content></ng-content>
  </div>
</div>
</ng-template>
