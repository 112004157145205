import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpService } from '../http/http.service';
import { ToastService } from '../toast/toast.service';
import { TrackingName } from './../../models/tracking/trackingEvent.model';
import { AnalyticsService } from './../analytics/analytics.service';
import { MemorialStore } from './../memorial.store';
import { RaygunService } from './../raygun/raygun.service';
import { UserStore } from './../user.store';

@Injectable()
export class EmailService {
  sendShareEmail = '/v1/share/shareMemorial';
  sendContact = '/api/v1/contact'

  constructor (
    private httpService: HttpService,
    private store: MemorialStore,
    private toastService: ToastService,
    private userStore: UserStore,
    private analyticsService: AnalyticsService,
    private raygunService: RaygunService
  ) { }

  shareMemorial (inModal = false, email?: string) {

    let url = this.sendShareEmail;

    let memorial = this.store.memorial.id;
    let user = this.userStore.user;

    if (user) {
      email = user.email;
    }

    let payload = {
      memorialId: memorial,
      shareLocation: (typeof window !== 'undefined' ? window.location.href.toString().replace(/\(([^\)]+)\)/, '') : ''),
      decoratedLocation: (typeof window !== 'undefined' ? window.location.hostname.toString() + window.location.pathname.toString().replace(/\(([^\)]+)\)/, '') : ''),
      email: email
    };

    return this.httpService
      .post(url, payload)
      .pipe(
        tap((res) => {
          this.toastService.openToast('We have sent you an email to ' + email + ' that you can forward to family and friends.  It includes a link to ' + this.store.memorial.preferredName + '\'s memorial and you can include a personalized message when you forward it.', ['green']);
        }),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  shareNewPage (email: string, seoName: string, memorialId: string) {
    let payload = {
      memorialId: memorialId,
      shareLocation: `https://${window.location.hostname.toString()}/${seoName}/memories`,
      decoratedLocation: `${window.location.hostname.toString()}/${seoName}/memories`,
      email: email
    }

    return this.httpService
      .post(this.sendShareEmail, payload)
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })
      )
  }

  sendContactEmail (form, successMessage?: string) {
    let message = 'Your message has been sent. We will get back to you as soon as possible'
    if (successMessage) {
      message = successMessage;
    }
    let url = this.sendContact
    return this.httpService
      .post(url, form)
      .pipe(
        tap(body => {
          this.toastService.openToast(message, ['green']);
          this.analyticsService.sendTrackingEvent(TrackingName.ContactSubmission, {})
        }),
        catchError(error => {
          return this.handleError(error, form);
        })
      )
  }

  private handleError (error: HttpErrorResponse, form?: any) {
    let message = 'Error sending email from frontend';
    let errorDetails: any = error;
    if (form) {
      message = 'Error sending contact message';
      errorDetails = {
        error,
        form
      }
    }
    this.toastService.openToast('There was an error submitting your request. Please try again. If this problem persists, plesase contact support@weremember.com', ['red']);
    this.raygunService.sendError(new Error(message), errorDetails)
    return observableThrowError(error.message || 'Server error');
  }
}
