<div class="snack-container"
  style="display: flex; justify-content: space-between; align-items: center; font-size: 16px; color: white; font-family: 'Source Sans Pro'">
  <div style="display: flex; align-items: center;">
    <span *ngIf="data.icon" style="margin-right: 2px" class="icon {{data.icon}}" aria-hidden="true"></span>
    <span>{{data.message}}</span>
  </div>
  <span class="icon icon-close" *ngIf="!data.action" (click)="close()" tabindex="0"><span class="visually-hidden">CLOSE
      TOAST</span></span>
  <div style="display: flex; align-items: center; cursor: pointer" *ngIf="data.action"
    (click)="snackBarRef.dismissWithAction()" tabindex="0">
    <span style="display: flex; align-items: center; font-size: 12px">{{data.action}}</span>
    <span style="font-size: 12px; margin-bottom: -2px" class="icon icon-arrow-right" aria-hidden="true"></span>
  </div>
</div>