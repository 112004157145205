import { FocusableOption } from '@angular/cdk/a11y'
import { Directive, Input, ElementRef, HostBinding, Renderer2 } from '@angular/core'

@Directive({
  selector: '[focusElement]'
})
export class FocusElementDirective implements FocusableOption {

  focused: boolean

  constructor (public el: ElementRef, private renderer: Renderer2) {
  }



  focus () {
    this.focused = true
    this.renderer.addClass(this.el.nativeElement, 'focused')
  }

  blur () {
    this.focused = false
    this.renderer.removeClass(this.el.nativeElement, 'focused')
  }

}