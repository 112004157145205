import { RouterModule } from '@angular/router';
import { CustomOverlayService } from './../../../routes/memorial/services/custom-overlay.service';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogComponentsModule } from './../dialog/dialog-components.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from './../buttons/button.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CookieNoticeComponent } from './cookie-notice.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogModule,
    DialogComponentsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    CookieNoticeComponent,
  ],
  exports: [
    CookieNoticeComponent
  ],
  providers: [
    CustomOverlayService
  ]
})
export class CookieNoticeModule {
}
