import { Injectable } from '@angular/core';
import { isEmpty as _isEmpty } from 'lodash-es';

import { TrackingName } from './../../models/tracking/trackingEvent.model';
import { AnalyticsService } from './../analytics/analytics.service';
import { HttpService } from './../http/http.service';

@Injectable()
export class SearchService {

  previewUrl: string = '/v1/search?term=:query&size=3&start=0';
  searchUrl: string = '/v1/search?term=:query&size=:size&start=:start';
  orgSearchUrl: string = '/v1/search/org';
  topSearchUrl: string = '/v1/search/top';
  searchFacetUrl: string = '/v1/search/facet';

  constructor (private httpService: HttpService, private analyticsService: AnalyticsService) { }

  getPreview (query: string) {
    const options = {
      userInput: query,
    };
    this.analyticsService.sendTrackingEvent(TrackingName.Search, options);
    let url = this.previewUrl.replace(':query', query);
    return this.httpService.get(url);
  }

  get (query: string, page?: number, size?: number) {
    let start = 0;
    let results = 20;

    if (size) {
      results = size;
    }

    if (page) {
      start = (page - 1) * results;
    }

    let url = this.searchUrl
      .replace(':query', query)
      .replace(':start', start.toString())
      .replace(':size', results.toString());

    const options = {
      userInput: query,
      pagination: page || 1
    };
    this.analyticsService.sendTrackingEvent(TrackingName.SearchResults, options);

    return this.httpService.get(url);
  }

  getByOrg (id: string, type: string, query?: string, cursor?: string, size?: number, page?: number, time?: string) {
    let url = `${this.orgSearchUrl}?`;
    // check for compound id's
    url += `id=${id}&type=${type}`

    if (cursor) {
      url += `&cursor=${cursor}`;
    }
    if (page) {
      url += `&page=${page}`;
    }
    if (size) {
      url += `&size=${size}`;
    }
    if (query) {
      url += `&terms=${query}`;
    }
    if (time) {
      url += `&time=${time}`;
    }

    const options = {
      userInput: query,
      pagination: page || 1
    };
    this.analyticsService.sendTrackingEvent(TrackingName.SearchResults, options);

    return this.httpService.get(url);
  }

  getTopMemorials (size: number, partner?: string, type?: string) {
    if (type && type === 'funeral-home') {
      //names are hard, realign for the API
      type = 'funeralHome'
    }
    if (_isEmpty(partner)) {
      partner = null;
    }
    let url = `${this.topSearchUrl}?size=${size}${partner ? `&type=${type}&id=` + partner : ''}`;
    return this.httpService.get(url);
  }

  getSearchFacets () {
    return this.httpService.get(this.searchFacetUrl);
  }
}
