import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { EmailService } from './../../../services/email/email.service';
import { ShareService } from './../../../services/share/share.service';
import { DirectivesModule } from './../../directives/directives.module';
import { ButtonModule } from './../buttons/button.module';
import { DialogComponentsModule } from './../dialog/dialog-components.module';
import { InputModule } from './../inputs/input.module';
import { SectionStackModule } from './../section-stack/section-stack.module';
import { ShareDialogComponent } from './share.dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MatDialogModule,
    RouterModule,
    DialogComponentsModule,
    ButtonModule,
    InputModule,
    SectionStackModule,
  ],
  declarations: [
    ShareDialogComponent
  ],
  exports: [
    ShareDialogComponent
  ],
  providers: [
    EmailService,
    ShareService
  ],
  entryComponents: [
    ShareDialogComponent,
  ]
})

export class ShareModule {
}
