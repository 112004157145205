import { Component, Input } from '@angular/core';

import { Memorial } from './../../../models/memorial.model';

@Component({
  selector: 'mem-memorial-preview',
  templateUrl: 'memorial-preview.component.html',
  styleUrls: ['memorial-preview.component.scss']
})
export class MemorialPreviewComponent {

  @Input('memorial') memorial: Memorial;
  @Input('small') small: boolean = false;
  @Input() noRoute: boolean = true;

  hasImage: boolean = true;
  imageSize: number = 80;

}
