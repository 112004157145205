import { UnsubscribeOnDestroyAdapter } from './../../adapters/unsubscribe-adapter';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HelperService } from '../../../services/helper/helper.service';
import { Signature } from './../../../models/signature.model';
import { GuestbookService } from './../../../routes/memorial/services/guestbook.service';
import { MemorialStore } from './../../../services/memorial.store';
import { UserStore, UserUpdateObject } from './../../../services/user.store';

@Component({
  selector: 'mem-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
})
export class PersonComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() signature: any;
  @Input() userId: any = null;
  @Input() theme: string = 'D6B66D';
  @Input() size: string = 'medium';
  @Input() text: boolean = true;
  @Input() relation: boolean = true;
  @Input() pending: boolean = false;
  @Input() light: boolean = false;

  photo: any = null;
  photoSize: number = 32;
  photoMargin: number = 7;
  firstName: string = '';
  lastName: string = '';
  name: string = '';
  relationship: string = '';
  monogram: string;

  guest: boolean = true;


  constructor (
    public helperService: HelperService,
    private store: MemorialStore,
    private guestbookService: GuestbookService,
    private componentRef: ChangeDetectorRef,
    private userStore: UserStore
  ) {
    super();
  }

  ngOnInit () {
    if (this.store.memorial && this.store.memorial.theme) {
      this.theme = this.store.memorial.theme;
    }

    if (this.userId && this.userStore) {
      this.subs.sink = this.userStore.userObservable.subscribe((uuo: UserUpdateObject) => {
        if (uuo && uuo.user && uuo.user.id === this.userId) {
          this.setupSignature();
        }
      })
    } else if (this.signature) {
      this.setupSignature();
    }

  }

  ngOnChanges () {
    this.setupSignature();
  }

  setupSignature () {
    if (this.userId && !this.signature) {
      this.getSignature();
      this.guest = false;
    } else {
      if (this.signature && !this.signature.relationGroup && !this.signature.relation) {
        this.relation = false;
      }
      if (this.signature) {
        if (this.signature.firstName && this.signature.firstName.length > 0) {
          this.firstName = this.signature.firstName.charAt(0).toUpperCase() + this.signature.firstName.slice(1);
        } else {
          this.firstName = '';
        }
        if (this.signature.lastName && this.signature.lastName.length > 0) {
          this.lastName = this.signature.lastName.charAt(0).toUpperCase() + this.signature.lastName.slice(1);
        } else {
          this.lastName = '';
        }

        this.name = this.firstName + ' ' + this.lastName;

        // we messed up guest object, so we need to check for both relationship and relation
        this.relationship = this.signature?.relationship?.length > 0 ? this.signature?.relationship : this.signature?.relation?.length > 0 ? this.signature?.relation : this.signature?.relationGroup;
      }
      this.getPhoto();
      this.componentRef.detectChanges();
    }
  }

  getInitials () {
    if (this.firstName && this.firstName.length > 0 && this.lastName && this.lastName.length > 0) {
      return this.firstName.charAt(0) + this.lastName.charAt(0);
    } else {
      return
    }
  }

  getPhoto () {
    switch (this.size) {
      case 'small':
        this.photoSize = 24;
        this.photoMargin = 4;
        break;
      case 'large':
        this.photoSize = 40;
        this.photoMargin = 11;
        break;
      case 'xlarge':
        this.photoSize = 100;
        this.text = false;
        break;
      case 'menu':
        this.photoSize = 50;
    }
    if (this.signature && this.signature.image && this.signature.image.url) {
      this.photo = this.helperService.getPersonImage(['c_crop,g_custom', `c_fill,q_auto:eco,w_${this.photoSize},h_${this.photoSize},r_max`], this.signature.image.filename, this.signature.image.url);
    }
  }

  getSignature () {
    if (this.userId && this.guestbookService) {
      this.subs.sink = this.guestbookService
        .getSignatureFromLocalGuestbook(this.userId)
        .subscribe((signature: Signature) => {
          if (signature) {
            this.signature = signature;
            this.setupSignature();
          }
          this.componentRef.detectChanges();
        }, error => {
          // TODO handle 500s and 404s
        })
    }
  }

  errorHandler (event) {
    this.photo = ''
  }

}
