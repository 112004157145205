import { DOCUMENT } from '@angular/common';
import { HelperService } from './../../services/helper/helper.service';
import { environment } from './../../../environments/environment';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class CanonicalResolver implements Resolve<any> {
  constructor (private helperService: HelperService, @Inject(DOCUMENT) private doc) { }

  resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.helperService.isBrowser()) {
      if (route.routeConfig.path == 'memories') {
        this.addCanonical(environment.siteUrl + '/' + route.params.memorial + '/' + route.params.shortId + '/memories');
      } else {
        let url = state.url;
        let index = url.indexOf('?')
        if (index > 0) {
          url = url.substring(0, url.indexOf('?'));
        }
        this.addCanonical(environment.siteUrl + url);
      }
    }
  }

  addCanonical (url: string) {
    let oldlink: HTMLLinkElement = this.doc.querySelector('link[rel = canonical]');
    if (oldlink) {
      oldlink.remove();
    }

    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', url);
  }
}
