import { ErrorHandler } from '@angular/core';


export class RaygunErrorHandler implements ErrorHandler {

  handleError (e: any) {
    if (typeof window !== 'undefined') {
      console.error(e);
      window['rg4js']('send', {
        error: e,
      });
    }
  }
}