<mem-dialog-header [close]="true" (closeEvent)="close.next(null)" [componentClose]="true"></mem-dialog-header>
<div class="dialog-content">
  <span class="icon" [ngClass]="data.icon" aria-hidden="true"></span>
  <div class="title">{{data.title}}</div>
  <div class="content" [innerHtml]="data.content"></div>
  <a *ngIf="data.externalUrl" [href]="data.externalUrl | qurl" target="_blank" rel="noopener" (click)="closePortal()">
    <mem-button-cta [medium]="true" style="margin-bottom: 16px;" width="180" mobileWidth="180">{{data.button}}
    </mem-button-cta>
  </a>
  <mem-button-cta *ngIf="!data.externalUrl" [medium]="true" style="margin-bottom: 16px;" width="180" mobileWidth="180"
    (click)="closePortal()">{{data.button}}
  </mem-button-cta>
</div>