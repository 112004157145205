import { ToastService } from './../toast/toast.service';
import { CustomOverlayService } from './../../routes/memorial/services/custom-overlay.service';
import { EventEmitter, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { pick } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Signature } from '../../models/signature.model';
import { UserRegisterData } from '../../models/sso/user-register-data.interface';
import { User } from '../../models/user.model';
import { AnalyticsService } from '../analytics/analytics.service';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../http/http.service';
import { LoaderService } from '../loader/loader.service';
import { MemorialStore } from '../memorial.store';
import { StorageService } from '../storage/storage.service';
import { FacebookCreds } from './../../models/sso/facebook-creds.interface';
import { SignInVerifyResponse } from './../../models/sso/verify-response.interface';
import { TrackingName } from './../../models/tracking/trackingEvent.model';
import { TrackingOptions } from './../../models/tracking/trackingOptions.interface';
import { RaygunService } from './../raygun/raygun.service';
import { UserStore } from './../user.store';

declare const FB: any;

@Injectable()
export class SignInService {

  private appleVerifyUrl: string = '/v1/sso/apple/verify';
  private editUserUrl: string = '/v1/user/:userId';
  private facebookVerifyUrl: string = '/v1/sso/facebook/verify';
  private googleVerifyUrl: string = '/v2/sso/google/verify';
  private guestbookUrl: string = '/v1/memorial/:memorialId/guestbook';
  private registerUrl: string = '/v2/sso/final';

  facebookAttempted: boolean = false;
  facebookCreds: any = {};
  fbLoginOptions = {
    scope: 'public_profile,email',
    return_scopes: true,
    auth_type: ''
  };
  guestbookSignature: any = {
    memorialId: '',
    relationGroup: '',
    relation: ''
  };
  pendingValues: any = {
    editorEmail: '',
    livingPermission: false,
    livingEmail: ''
  }
  hasSignatureForPendingMemorial: boolean = false;
  specialFlow: any = {};
  asModerator: boolean = false;

  private _preSignInUser: any;

  facebookInitialized: boolean = false;
  ssoFinished: EventEmitter<any> = new EventEmitter<any>();

  unconfirmedUser: User;
  noEmailSupplied: boolean = false;

  signInTrackingOptions: TrackingOptions = {
    uiType: 'dialog',
    dialog: 'Sign In Dialog',
    loginProvider: null,
    nameSupplied: false,
    emailSupplied: false
  };

  constructor (private httpService: HttpService,
    @Optional() private store: MemorialStore,
    private storageService: StorageService,
    private cookieService: CookieService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private analyticsService: AnalyticsService,
    private memorialStore: MemorialStore,
    private router: Router,
    private raygun: RaygunService,
    private userStore: UserStore,
    private jwtHelper: JwtHelperService,
    private overlay: CustomOverlayService,
    private toastService: ToastService) {

  }

  get preSignInUser () {
    return this._preSignInUser;
  };

  set preSignInUser (user: any) {
    this._preSignInUser = user;
  }

  updateUser (user: any, signature?: any) {
    if (!user.id) {
      return observableThrowError('Must supply the user id');
    }

    let url = this.editUserUrl.replace(':userId', user.id);

    user = pick(user, ['id', 'firstName', 'lastName', 'email', 'image']);

    if (signature && signature.relationGroup) {
      user.signature = pick(signature, ['memorialId', 'relation', 'relationGroup']);
    }

    return this.httpService
      .put(url, user)
      .pipe(
        tap(res => {

          if (res['signature']) {
            this.memorialStore.setUserSignature(res['signature']);
            delete res['signature'];
          }

          if (res) {
            res = Object.assign(this.userStore.user, res);
            this.userStore.setUser(res);
          }

          return res;
        })
      );
  }

  register (data: UserRegisterData) {
    return this.httpService.post(this.registerUrl, data).pipe(
      tap((response) => {
        if (response?.user && response?.user?.id) {
          this.authService.setUser(response.user);
        }
        this.authService.checkToken();
      }),
      catchError(error => {
        console.error(error);
        this.raygun.sendError('Sign In Error', { error: this.raygun.stringifyError(error) });
        throw error;
      })
    );
  }

  handleVerifyReturn (result: any) {
    const id_token = this.cookieService.get('id_token');
    const auth_token = this.cookieService.get('auth_token');
    if (id_token) {
      if (result?.message === 'success' && result?.status === 'established user' && result?.user) {
        this.authService.setUser(result.user);
      }
      this.authService.checkToken();
      this.unconfirmedUser = result?.user || this.authService.getUserFromToken();
    } else if (auth_token) {
      // we should have the auth_token now
      const u = this.jwtHelper.decodeToken(auth_token);
      this.unconfirmedUser = u?.user;
      if (!this.unconfirmedUser.email || typeof this.unconfirmedUser.email === 'undefined' || this.unconfirmedUser.email.trim() === '') {
        this.noEmailSupplied = true;
      }
    } else if (result && result?.status) {
      this.unconfirmedUser = result.user;
      if (!this.unconfirmedUser.email || typeof this.unconfirmedUser.email === 'undefined' || this.unconfirmedUser.email.trim() === '') {
        this.noEmailSupplied = true;
      }
    }
  };

  verifyGoogle (credential: any, memorialId?: string): Observable<SignInVerifyResponse> {
    return this.httpService.post(this.googleVerifyUrl, { credential, memorialId })
      .pipe(
        tap((result: any) => {
          this.handleVerifyReturn(result);
        })
      );
  }

  verifyAncestry (): Observable<SignInVerifyResponse> {
    return new Observable((observer) => {
      const ssoWindow = window.open(environment.ancestrySignInLink, '_blank', 'location=no, height=598,width=700');
      const timer = setInterval(() => {
        if (ssoWindow && ssoWindow.closed) {
          clearInterval(timer);
          this.storageService.getObject('verifyResponse').subscribe((response: SignInVerifyResponse) => {
            /* This is a fix for Ancestry and subdomains with We Remember. If the subdomain is not www. we have problems
            signing in. This will fix that. @rdivis */
            if (!response) {
              const user = this.authService.getUserFromToken();
              if (user) {
                response = {
                  error: false,
                  message: 'success',
                  status: 'established user',
                  user: user
                };
              }
            }
            /* end fix */
            this.handleVerifyReturn(response);
            this.storageService.delete('verifyResponse'); // cleanup this shouldn't stick around
            observer.next(response);
            observer.complete();
          })
        }
      }, 300);
    });
  }

  verifyApple (id_token: string, authorization_code: string, memorialId?: string): Observable<SignInVerifyResponse> {
    return this.httpService.post(this.appleVerifyUrl, { id_token, authorization_code, memorialId })
      .pipe(
        tap((result: SignInVerifyResponse) => {
          this.handleVerifyReturn(result);
        })
      )
  }

  verifyFacebook (creds: FacebookCreds): Observable<SignInVerifyResponse> {
    return this.httpService
      .post(this.facebookVerifyUrl, creds)
      .pipe(
        tap((result: SignInVerifyResponse) => {
          this.handleVerifyReturn(result);
        })
      )
  }

  logout () {
    this.userStore.setUser(null);// clear user from store
    this.memorialStore.setUserSignature(null);// clear signature
    this.storageService.delete('user');
    this.storageService.delete('token');
    this.storageService.delete('tokenExpiration');
    this.storageService.delete('verifyResponse'); // extra safety, this should never be present when logged out
    this.cookieService.delete('id_token', '/', environment.cookieDomain);
    if (typeof window !== 'undefined') {
      this.loaderService.open();
      window.location.reload();
    }
    return of('logout');
  }

  signGuestbook (relationGroup: string, relation: string, userId: string, memorialId?: string) {

    memorialId = memorialId || this.store.memorial.id;
    let url = this.guestbookUrl.replace(':memorialId', memorialId.toString());

    let params: Object = {
      userId: userId,
      relationGroup: relationGroup,
      relation: relation
    };

    if (this.specialFlow.type && this.specialFlow.hash) {
      params['type'] = this.specialFlow.type;
      params['hash'] = this.specialFlow.hash;
    };

    if (this.asModerator) {
      params['type'] = 'moderator';
    }

    if (this.hasSignatureForPendingMemorial) {
      params = Object.assign(params, this.pendingValues);
    }

    return this.httpService
      .post(url, params)
      .pipe(
        map(res => {
          this.analyticsService.sendTrackingEvent(TrackingName.JoinPage, {
            ...this.signInTrackingOptions
          })
          if (res.seoName) {
            const url = res.seoName.split('?');
            const qParams = {};
            if (url[1]) {
              const vars = url[1].split('&');
              for (let i = 0; i < vars.length; i++) {
                const pair = vars[i].split('=');
                qParams[pair[0]] = decodeURIComponent(pair[1]);
              }
            }
            this.router.navigate([url[0]], { queryParams: qParams });
          }
          this.hasSignatureForPendingMemorial = false;
          let signature = new Signature(res);
          this.memorialStore.setUserSignature(signature);
          if(this.specialFlow.type || this.asModerator) {
            // we know that we need to get updated permissions
            this.authService.getPermissions();
            //reset, only used once
            this.specialFlow = {};
            this.asModerator = false;
          }
          this.authService.emitLoginStatusChange(this.authService.STATUSES.GUESTBOOK, true, this.userStore.user, this.authService.status);
          return signature;
        })
      );
  }

  becomeModerator (memorialId, userId) {
    let url = '/v1/editor/:memorialId/:userId'.replace(':memorialId', memorialId).replace(':userId', userId);

    return this.httpService.put(url, userId).pipe(
      tap(() => {
        // update permissions which will cause memorial status to update
        this.authService.getPermissions()
        this.toastService.openToast('You are now a moderator on this page', ['green']);
      })
    )
  }
}
