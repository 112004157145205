import { JwtHelperService } from '@auth0/angular-jwt';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { map, filter, take, takeWhile } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { UserStore, UserPermissionsObject } from './user.store';
import { Inject, Injectable, PLATFORM_ID, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, NavigationExtras, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MemorialStore } from './memorial.store';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { User } from '../models/user.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AdminGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  user: User;

  constructor (
    private userStore: UserStore,
    private router: Router,
    private authService: AuthService,
    private injector: Injector,
    private jwtHelper: JwtHelperService,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  canActivate (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (isPlatformServer(this.platformId)) {
      //ToDo - once at Angular@13 ngx-cookie-service has SSR support and this.authService.getUserFromToken() can be relied on here instead
      let req = this.injector.get(REQUEST);
      if (req.cookies.id_token) {
        const token = this.jwtHelper.decodeToken(req.cookies.id_token);
        if (token?.user?.id) {
          this.user = token.user;
          return true
        } else {
          this.router.navigate(['sign-in'], { queryParams: { 'redirectUrl': state.url, 'guest': false } } as NavigationExtras);
          return false
        }
      } else {
        this.router.navigate(['sign-in'], { queryParams: { 'redirectUrl': state.url, 'guest': false } } as NavigationExtras);
        return false
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      this.authService.checkToken()
      let jwt = this.cookieService.get('id_token');
      if (jwt) {
        this.user = this.jwtHelper.decodeToken(this.cookieService.get('id_token')).user;
        return this.userStore.userObservable.pipe(
          map((user) => {
            return user.permissions
          }),
          filter(permissions => permissions),
          take(1),
          map((permissions) => {
            if (permissions.siteAdmin) {
              return true
            } else {
              this.router.navigate(['sign-in'], { queryParams: { 'redirectUrl': state.url, 'guest': false } } as NavigationExtras);
              return false
            }
          }))
      } else {
        this.router.navigate(['sign-in'], { queryParams: { 'redirectUrl': state.url, 'guest': false } } as NavigationExtras);
        return false
      }
    }
  }

}
