import { CUSTOM_OVERLAY_DATA } from './../../../services/custom-overlay.service';
import { tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { isObject } from 'lodash-es';
import { MemorialStore } from './../../../../../services/memorial.store';
import { SignInService } from './../../../../../services/sign-in/sign-in.service';
import { ToastService } from './../../../../../services/toast/toast.service';
import { UserStore } from './../../../../../services/user.store';
import { BaseDialogComponent } from './../../../../../shared/components/dialog/base-dialog.component';

@Component({
  selector: 'edit-relationship',
  templateUrl: 'edit-relationship.dialog.component.html',
  styleUrls: ['edit-relationship.dialog.component.scss']
})
export class EditRelationshipDialogComponent extends BaseDialogComponent {
  signature: FormGroup = new FormGroup({
    relationGroup: new FormControl(''),
    relationship: new FormControl(''),
    memorialId: new FormControl(this.store?.memorial?.id)
  })

  submitting: boolean = false;

  constructor (
    public store: MemorialStore,
    private userStore: UserStore,
    private signInService: SignInService,
    private toastService: ToastService,
    @Inject(CUSTOM_OVERLAY_DATA) public data: any) {
    super();
    this.signature.controls.relationGroup.setValue(this.store.currentUserSignature?.relationGroup || '');
    this.signature.controls.relationship.setValue(this.store.currentUserSignature?.relation || '');
  }

  save () {
    this.submitting = true
    let signature = {
      relationGroup: this.signature.get('relationGroup').value,
      relation: this.signature.get('relationship').value,
      memorialId: this.store.memorial.id
    };
    let profile = {
      id: this.userStore.user?.id || '',
      firstName: this.userStore.user?.firstName || '',
      lastName: this.userStore.user?.lastName || '',
      image: {
        url: this.userStore.user?.image?.url || ''
      }
    }

    if (this.store.currentUserSignature) {
      this.subs.sink = this.signInService
        .updateUser(profile, signature)
        .pipe(
          tap(() => {
            this.submitting = false;
            this.toastService.openToast('Relationship updated', ['green']);
            this.close.next(true);
          })
        )
        .subscribe({
          next: () => {
          }, error: (error: HttpErrorResponse) => {
            console.log(error);
            let message;
            if (isObject(error) && error.status >= 400) {
              message = 'Oops something went wrong. Please try again later.'
            } else if (isObject(error) && error.message) {
              message = error.message;
            } else {
              message = error['_body'] || error;
            }
            this.toastService.openToast(message, ['green']);
            this.submitting = false;
          }
        });
    } else if (this.userStore.hasGuestUser) {
      this.userStore.guestUser.relationGroup = signature.relationGroup;
      this.userStore.guestUser.relationship = signature.relation || '';
      this.close.next(true);
    } else {
      this.subs.sink = this.signInService
        .signGuestbook(signature.relationGroup, signature.relation, profile.id, signature.memorialId)
        .pipe(
          tap(() => {
            this.signInService.specialFlow = {};
            this.toastService.openToast('Guestbook Signed', ['green']);
            this.submitting = false;
            this.close.next(true);
          })
        )
        .subscribe({
          next: () => { },
          error: () => {
            this.submitting = false;
            this.signInService.specialFlow = {}; //cleanup if there is an error.
            this.toastService.openToast('There was an error signing the guestbook.', ['red']);
          }
        })
    }
  }

}
