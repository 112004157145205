<div class="person" [ngClass]="{'small': 'small','large':'large','xlarge':'xlarge','menu':'menu'}[size]" data-hj-suppress>
  <div class="badge" *ngIf="pending">!</div>
  <div class="photo" [class.monogram]="!photo" [class.pending]="pending">
    <img [src]="photo" [alt]="firstName + ' ' + lastName" *ngIf="photo" (error)="errorHandler($event)">
    <span *ngIf="!photo">{{getInitials()}}</span>
    <span #ref><ng-content></ng-content></span>
    <span class="icon icon-person" *ngIf="!firstName && !lastName && !photo && signature" aria-hidden="true"></span>
  </div>
  <div class="text" *ngIf="text === true" [style.margin-left]="photoMargin + 'px'" [class.light]="light">
    <div class="name">{{name}}</div>
    <div class="relationship">{{relationship}}</div>
  </div>
</div>
