import { AdService } from './shared/components/ad/ad.service'
import { FocusMonitor } from '@angular/cdk/a11y'
import { DOCUMENT, isPlatformBrowser, isPlatformServer, Location } from '@angular/common'
import { AfterContentInit, Component, Inject, Injector, InjectionToken, PLATFORM_ID, ViewEncapsulation, Renderer2, ElementRef } from '@angular/core'
import { makeStateKey, Meta, TransferState } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { includes as _includes } from 'lodash-es'
import { DeviceDetectorService } from 'ngx-device-detector'
import { tap } from 'rxjs/operators'
import { environment } from '../environments/environment'
import { CustomOverlayRef, CustomOverlayService } from './routes/memorial/services/custom-overlay.service'
import { AnalyticsService } from './services/analytics/analytics.service'
import { ExternalService } from './services/external/external.service'
import { HelperService } from './services/helper/helper.service'
import { RaygunService } from './services/raygun/raygun.service'
import { StorageService } from './services/storage/storage.service'
import { CookieNoticeService, CookieOptions } from './shared/components/cookie-notice/cookie-notice.service'
import { MessageDialogComponent } from './shared/components/message-dialog/message-dialog.component'

@Component({
  selector: 'app-root',
  template: `
    <style type="text/css" media="print" *ngIf="brandless">
      @page {
        size: auto;
        margin: 0;
      }
    </style>
    <header>
      <mem-header
        [class.funeralHome]="funeralHome"
        [class.createPage]="createPage"
        *ngIf="!brandless"
      ></mem-header>
    </header>
    <main>
      <router-outlet id="main-content"></router-outlet>
      <router-outlet class="main-theme" name="modal"></router-outlet>
      <mem-loader></mem-loader>
    </main>
    <footer>
      <mem-footer *ngIf="isBrowser && !brandless"></mem-footer>
    </footer>
  `,
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements AfterContentInit {
  funeralHome: boolean = false;
  createPage: boolean = false;
  isBrowser: boolean = false;
  interrupt: boolean = false;
  brandless: boolean = false;
  device: any = {};
  locale: string = 'en-US'
  private unlistener: () => void

  constructor (
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private meta: Meta,
    private helperService: HelperService,
    private analyticsService: AnalyticsService,
    private deviceService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private storage: StorageService,
    private injector: Injector,
    public cns: CookieNoticeService,
    private cache: TransferState,
    private overlay: CustomOverlayService,
    private raygunService: RaygunService,
    private externalService: ExternalService,
    private renderer2: Renderer2,
    private focusMonitor: FocusMonitor,
    private el: ElementRef,
    private adService: AdService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.helperService.setAppVersion()
    this.isBrowser = isPlatformBrowser(this.platformId)
    const LOCALE_ID = makeStateKey('LOCALE_ID')
    // LOCALE_ID is best supported locale, CF_LOCALE is cloudflare locale by IP
    if (!this.isBrowser) {
      this.locale = this.injector.get('LOCALE_ID')
      this.document.documentElement.lang = this.locale
      this.document.documentElement.setAttribute('data-locale', this.injector.get('CF_LOCALE'))
    }
    this.cache.set(LOCALE_ID, this.locale as any)

    if (this.isBrowser) {
      this.cns.initialize()
      this.route.queryParams.subscribe(params => {
        // for campaign tracking
        if (params['utm_campaign']) {
          this.analyticsService.register({ campaignId: params['utm_campaign'] })
        }
        if (params['utm_source']) {
          this.analyticsService.register({ source: params['utm_source'] })
        }
        if (params['utm_medium']) {
          this.analyticsService.register({ medium: params['utm_medium'] })
        }
      })

      // reinit ads on route change and update route history for contact form
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.adService.reInitAdvertisements()
          this.analyticsService.updatePrevious(event.url)
        }
      })

      // setup sourcepoint
      window['_sp_queue'] = []
      window['_sp_'] = {
        config: {
          accountId: 1726,
          baseEndpoint: 'https://cdn.privacy-mgmt.com',
          usnat: {},
          custom: {},
          gdpr: {},
          events: {
            onConsentReady: function (messageType, consentUUID, euconsent) {
              if (messageType === 'gdpr') {
                // @ts-expect-error: no-undef comes from sourcepoint
                __tcfapi('getCustomVendorConsents', 2, function (vendorConsents, success) {
                  if (success) {
                    // @ts-expect-error: comes from sourcepoint
                    __tcfapi('getTCData', 2, function (tcData, success) {
                      document.dispatchEvent(new CustomEvent('cookie-consent', { detail: { ...tcData, ...vendorConsents } }))
                    })
                  }
                })
              }
            }
          }
        }
      }
      // listener to catch people who already consented
      //@ts-expect-error comes from sourcepoint
      __tcfapi('addEventListener', 2, function (tcData, success) {
        if (success) {
          //@ts-expect-error comes from sourcepoint
          __tcfapi('getCustomVendorConsents', 2, (vendorConsents, success) => {
            document.dispatchEvent(new CustomEvent('cookie-consent', { detail: { ...tcData, ...vendorConsents } }))
          })
        }
      })
      this.externalService.loadScript('https://cdn.privacy-mgmt.com/unified/wrapperMessagingWithoutDetection.js', 'sourcepoint-messaging')
    }

    //set up browser specific styles
    if (typeof document !== 'undefined' && this.isBrowser) {
      document.documentElement.setAttribute('data-browser', navigator.userAgent)
    }

    if (this.location.path() == '/funeral-home') {
      this.funeralHome = true
    }

    if (_includes(location.path(), 'create-page')) {
      this.createPage = true
    } else {
      this.createPage = false
    }

    if (
      this.location.path().indexOf('flyer') > -1 ||
      this.location.path().indexOf('callback') > -1 ||
      this.location.path().indexOf('/external/') > -1 ||
      this.location.path() === '/privacy' ||
      this.location.path() === '/terms'
    ) {
      this.brandless = true
    }

    if (isPlatformServer(this.platformId)) {
      let req = this.injector.get(REQUEST)
      if (
        _includes(req.headers.host, 'test') ||
        _includes(req.headers.host, 'temp') ||
        _includes(req.headers.host, 'swap') ||
        _includes(req.headers.host, 'dev')
      ) {
        this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' })
      }
      this.cache.set(makeStateKey<string>('referrer'), req.header('Referer'))
    }

    if (!environment.production) {
      this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' })
    }

    this.device = this.deviceService.getDeviceInfo()

    if (this.device.browser && this.device.browser_version) {
      if (this.device.browser === 'Chrome' && parseFloat(this.device.browser_version) < 69) {
        this.browserInterrupt(this.device.browser, this.device.browser_version)
      }

      if (this.device.browser === 'Firefox' && parseFloat(this.device.browser_version) < 52) {
        this.browserInterrupt(this.device.browser, this.device.browser_version)
      }

      if (
        this.device.browser === 'Safari' &&
        parseFloat(this.device.browser_version) < 10 &&
        parseFloat(this.device.browser_version) !== 0
      ) {
        this.browserInterrupt(this.device.browser, this.device.browser_version)
      }

      if (this.device.browser === 'MS-Edge' && parseFloat(this.device.browser_version) < 14) {
        this.browserInterrupt(this.device.browser, this.device.browser_version)
      }

      if (this.device.browser === 'IE') {
        this.browserInterrupt(this.device.browser, this.device.browser_version)
      }
    }
  }

  ngOnInit () {
    this.unlistener = this.renderer2.listen('document', 'cookie-consent', event => {
      this.processConsent(event.detail)
    })
  }

  ngOnDestroy () {
    this.unlistener()
  }

  ngAfterContentInit () {
    if (isPlatformBrowser(this.platformId)) {
      this.raygunService.init().subscribe()

    }
  }

  ngAfterViewInit () {
    // get origin classes on focus events
    this.focusMonitor.monitor(this.el, true).subscribe()
  }

  browserInterrupt (browser, version) {
    this.storage.get('browserInterrupt').subscribe(value => {
      if (!value) {
        // set timestamp in localstorage and only show once every 24 hours
        this.storage.set('browserInterrupt', 'true', 'session')
        // send message to raygun
        let customData = {
          browser: browser,
          version: version
        }

        this.overlay.open(MessageDialogComponent, {
          data: {
            ref: 'interrupt',
            title: 'Your browser is out of date and not supported',
            content: 'Basic features work, but for the best experience upgrade your browser. Our Support page has recommendations.',
            icon: 'icon-warning',
            button: 'VISIT SUPPORT'
          }
        }).pipe(
          tap(ref => {
            if (ref instanceof CustomOverlayRef) {
              this.router.navigate(['/help-center'])
              ref.close()
            }
          })
        ).subscribe()
      }
    })
  }
  processConsent (data) {
    // TODO check addtlConsents, dateCreated, and gdprApplies for non-gdpr users that haven't changed permissions
    // todo check how those fields behave overtime for non-gdpr users

    const consentedVendors = data.consentedVendors
    let analytics = !!consentedVendors?.find(vendor => vendor.name === 'Google Analytics')
    let googleLogin = !!consentedVendors?.find(vendor => vendor.name === 'Google Sign-In')
    let facebookLogin = !!consentedVendors?.find(vendor => vendor.name === 'Facebook Login')

    const consentedPurposes = data.consentedPurposes
    let adStorage = !!consentedPurposes?.find(purpose => purpose.name === 'WeRemember Advertising Cookies')
    let analyticsStorage = !!consentedPurposes?.find(purpose => purpose.name === 'WeRemember Analytics Cookies')

    if (!data.gdprApplies && !data.dateCreated) {
      analytics = true
      googleLogin = true
      facebookLogin = true
      adStorage = true
      analyticsStorage = true
    }

    // @ts-expect-error: GTM dataLayer
    gtag('consent', 'update', {
      'ad_storage': adStorage ? 'granted' : 'denied',
      'analytics_storage': analyticsStorage ? 'granted' : 'denied'
    })

    this.cns.updateCookieOptions(new CookieOptions(analytics, facebookLogin, googleLogin))
  }
}
