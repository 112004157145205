import { Memorial } from './../memorial.model';
import { unescapeHtml } from '../helper/unescapeHtml';
import {
  forEach as _forEach,
  isString as _isString
} from 'lodash-es';

export class SearchHit {
  found: number;
  hit: Array<any>;
  start: number;
  cursor: string;
}

export class SearchResult {
  hits: SearchHit;
  status: any;
  memorials: Array<Memorial> = [];
  constructor (data?: any) {
    if (data) {
      _forEach(data, (value, key) => {
        if (_isString(value)) {
          value = unescapeHtml(value);
        }
        this[key] = value;
      });
      _forEach(data.hits.hit, (value, key) => {
        this.memorials.push(new Memorial(value));
      })
    }
  }
}
