import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { tap } from 'rxjs/operators'
import { TrackingName } from '.././../../../models/tracking/trackingEvent.model'
import { CustomOverlayRef, CustomOverlayService } from './../../../../routes/memorial/services/custom-overlay.service'
import { AnalyticsService } from './../../../../services/analytics/analytics.service'
import { ToastService } from './../../../../services/toast/toast.service'
import { UnsubscribeOnDestroyAdapter } from './../../../adapters/unsubscribe-adapter'
import { ConfirmationDialogComponent } from './../../confirmation/confirmation.component'

@Component({
  selector: 'mem-dialog-header',
  template: `
    <ng-container>
      <button buttonIcon [white]="true" iconLeft="icon-close" *ngIf="close" (click)="closeMe('close', $event)" (keydown.enter)="closeMe('close', $event)" type="button" cdkFocusRegionStart tabindex="0">CLOSE</button>
      <button *ngIf="cancel" buttonIcon iconLeft="icon-arrow-left" [white]="true" (click)="closeMe('cancel', $event)" (keydown.enter)="closeMe('cancel', $event)" tabindex="0" cdkFocusRegionStart>CANCEL</button>
      <button *ngIf="cancelClose" buttonIcon iconLeft="icon-close" [white]="true" (click)="closeMe('cancel', $event)" (keydown.enter)="closeMe('cancel', $event)" tabindex="0">CANCEL</button>
      <button *ngIf="backButton" buttonIcon iconLeft="icon-arrow-left" [white]="true" (click)="goBack()" (keydown.enter)="goBack()" tabindex="0">BACK</button>
    </ng-container>
  `,
  styleUrls: [`./dialog-header.component.scss`],
})
export class DialogHeaderComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() close: boolean = false;
  @Input() cancel: boolean = false;
  @Input() cancelClose: boolean = false;
  @Input() backButton: boolean = false;
  @Input() componentClose: boolean = false;
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() closeConfirm: boolean = false;


  constructor (
    public toastService: ToastService,
    private customOverlayService: CustomOverlayService,
    private analyticsService: AnalyticsService,
    private overlay: CustomOverlayService) {
    super()
  }

  closeMe (text, event) {
    if (this.closeConfirm) {
      this.confirm()
    } else {
      this.analyticsService.sendTrackingEvent(TrackingName.Click, {
        uiName: 'Close (Dialog)',
        uiType: 'dialog',
      })
      if (this.componentClose) {
        this.closeEvent.next(null)
      } else {
        this.customOverlayService.closeEvent.next(null)
      }
    }
    event?.preventDefault()
    event?.stopPropagation()
  }

  goBack () {
    if (this.closeConfirm) {
      this.confirm(true)
    } else {
      this.back.emit()
    }
  }

  ngOnInit () {
    this.back.pipe(tap(res => {
      this.analyticsService.sendTrackingEvent(TrackingName.Click, {
        uiName: 'Back (Dialog)',
        uiType: 'dialog'
      })
    })).subscribe()
  }

  confirm (back?: boolean) {
    this.subs.sink = this.overlay.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete changes?',
        description: 'Are you sure you want to delete your unsaved changes?',
        actionText: 'DELETE'
      }
    }).pipe(
      tap((ref) => {
        if (ref instanceof CustomOverlayRef) {
          this.closeConfirm = false
          if (back) {
            this.goBack()
          } else {
            this.closeMe('close', null)
          }
          ref.close()
        }
      })
    ).subscribe()
  }
}
