import { AdModule } from './shared/components/ad/ad.module'
import { DirectivesModule } from './shared/directives/directives.module'
import { A11yModule } from '@angular/cdk/a11y'
import { AdminGuard } from './services/admin.guard'
import { RaygunErrorHandler } from './services/raygun/raygun.error-handler'
import { RaygunService } from './services/raygun/raygun.service'
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule, BrowserTransferStateModule, HammerModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { TransferHttpCacheModule } from '@nguniversal/common'
import { AppComponent } from './app.component'
import { AppRoutes, AuthResolver } from './app.routes'
import { AuthGuard } from './services/auth.guard'
import { GlobalErrorHandler } from './services/global-error-handler'
import { HttpErrorInterceptor } from './services/http/http-error.interceptor'
import { ModGuard } from './services/mod.guard'
import { CookieNoticeModule } from './shared/components/cookie-notice/cookie-notice.module'
import { FooterModule } from './shared/components/footer/footer.module'
import { HeaderModule } from './shared/components/header/header.module'
import { LoadingModule } from './shared/components/loading/loading.module'
import { MessageDialogModule } from './shared/components/message-dialog/message-dialog.module'
import { ToastComponent } from './shared/components/toast/toast.component'
import { ToastModule } from './shared/components/toast/toast.module'
import { SeoResolver } from './shared/resolvers/seo.resolver'
import { SharedRoutesModule } from './shared/shared-routes.module'
import { StartingServicesModule } from './shared/starting-services.module'
import { environment } from '../environments/environment'

import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable()
export class NoopGuard implements CanActivate {

  canActivate (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true
  }

}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'app-root' }),
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, { initialNavigation: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' }),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'CSRF-Token'
    }),
    BrowserTransferStateModule,
    StartingServicesModule,
    TransferHttpCacheModule,
    HeaderModule,
    LoadingModule,
    MessageDialogModule,
    ToastModule,
    FooterModule,
    SharedRoutesModule,
    CookieNoticeModule,
    HammerModule,
    A11yModule,
    DirectivesModule,
    AdModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler
    },
    AuthResolver,
    SeoResolver,
    AuthGuard,
    AdminGuard,
    ModGuard,
    RaygunService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ToastComponent
  ]
})
export class AppModule { }
