import { DeviceDetectorService } from 'ngx-device-detector'
import { debounceTime } from 'rxjs/operators'
import { UnsubscribeOnDestroyAdapter } from '../../adapters/unsubscribe-adapter'
import { isPlatformBrowser, isPlatformServer } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID, Optional } from '@angular/core'
import { fromEvent, ReplaySubject } from 'rxjs'
import { REQUEST } from '@nguniversal/express-engine/tokens'

@Injectable()
export class ScreenSizeService extends UnsubscribeOnDestroyAdapter {

  screenSize: ReplaySubject<number> = new ReplaySubject();
  breakpoint: ReplaySubject<'mobile' | 'tablet' | 'desktop'> = new ReplaySubject();

  constructor (
    @Inject(PLATFORM_ID) private platformId: Object,
    private deviceDetector: DeviceDetectorService,
    @Optional() @Inject(REQUEST) request: Request
  ) {
    super()
    if (isPlatformServer(this.platformId)) {
      this.deviceDetector.setDeviceInfo((request.headers['user-agent'] as string) || '')
    }
    if (isPlatformBrowser(this.platformId)) {
      this.nextBreakpoint()
      this.screenSize.next(window.innerWidth)
      this.subs.sink = fromEvent(window, 'resize').pipe(
        debounceTime(250)
      ).subscribe((e) => {
        this.nextBreakpoint()
        this.screenSize.next(window.innerWidth)
      })
    }
  }

  nextBreakpoint () {
    if (window.innerWidth < 768) this.breakpoint.next('mobile')
    else if (window.innerWidth < 992) this.breakpoint.next('tablet')
    else this.breakpoint.next('desktop')
  }

  isMobileDevice () {
    return this.deviceDetector.isMobile()
  }

  isDesktopDevice () {
    return this.deviceDetector.isDesktop()
  }

  isTabletDevice () {
    return this.deviceDetector.isTablet()
  }
}