import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from './../../pipes/pipes.module';
import { ButtonModule } from './../buttons/button.module';
import { DialogComponentsModule } from './../dialog/dialog-components.module';
import { MessageDialogComponent } from './message-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    DialogComponentsModule,
    ButtonModule,
    PipesModule
  ],
  declarations: [
    MessageDialogComponent
  ],
  exports: [
    MessageDialogComponent
  ],
  providers: [],
  entryComponents: [
    MessageDialogComponent
  ]
})

export class MessageDialogModule {
}
