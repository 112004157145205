import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from './../../../../services/loader/loader.service';

@Component({
  selector: 'mem-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() loadingMessage: string = 'Loading your page'

  visible: boolean = false;
  overlay: boolean = false;

  constructor (private loaderService: LoaderService) {
  }

  ngOnInit () {
    this.loaderService.openLoader.subscribe(value => {
      this.visible = value.visible;
      if (value.message) {
        this.loadingMessage = value.message;
      }
      this.overlay = value.overlay;
    }, err => {
      console.error(err);
    });
  }

}
