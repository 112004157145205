<div class="preview-section" [class.small]="small">
  <div class="image">
    <img [src]="memorial.image" />
  </div>
  <div class="content">
    <div class="name-row">
      <div class="name">
        <span>{{ memorial.prefix }} </span>
        <span itemprop="givenName">{{ memorial.firstName }} </span>
        <span *ngIf="memorial.nickname">"{{ memorial.nickname }}" </span>
        <span itemprop="givenName">{{ memorial.middleName }} </span>
        <span itemprop="familyName">{{ memorial.lastName }} </span>
        <span> {{ memorial.suffix }}</span>
      </div>
      <div class="page-content" *ngIf="!small">
        <span class="guests">
          <span class="icon icon-avatar" [style.color]="memorial.theme || ''" aria-hidden="true"></span><span
            class="visually-hidden">Guestbook Count</span> {{memorial.guestbookCount}}
        </span>
        <span class="memories">
          <span class="icon icon-comment-alt" [style.color]="memorial.theme || ''" aria-hidden="true"></span><span
            class="visually-hidden">Memory Count</span> {{memorial.memoryCount}}
        </span>
      </div>
    </div>
    <div class="details-row">
      <span *ngIf="memorial.birthYear || memorial.deathYear">
        {{memorial.birthYear}}-{{memorial.deathYear}}
      </span>
      <span class="location" *ngIf="memorial.city || memorial.region">
        <span *ngIf="memorial.city">{{memorial.city}}</span><span *ngIf="memorial.region && memorial.city">,
        </span><span *ngIf="memorial.region">{{memorial.region}}</span>
      </span>
    </div>
    <div class="bio" *ngIf="!small">
      {{memorial.headline}}
    </div>
    <div class="owner" *ngIf="!small">
      <mem-person size="small" [signature]="memorial.creator" [theme]="memorial.theme"></mem-person>
    </div>
  </div>
</div>