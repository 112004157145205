import { Meta, Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AnalyticsService } from './../../services/analytics/analytics.service';

@Injectable()
export class SeoResolver implements Resolve<any> {
  constructor (private meta: Meta, private title: Title) { }

  resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(route.data.title) {
      this.title.setTitle(route.data.title);
      this.meta.updateTag({ property: 'og:title', content: route.data.title });
    }

    if(route.data.description) {
      this.meta.updateTag({ name: 'description', content: route.data.description });
      this.meta.updateTag({ property: 'og:description', content: route.data.description })
    }

    if(route.data.nofollow) {
      this.meta.updateTag({name: 'robots', content: 'noindex, nofollow'});
    } else {
      this.meta.removeTag('robots');
    }

  }
}
