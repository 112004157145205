import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastComponent } from './toast.component';


@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  declarations: [
    ToastComponent
  ],
  exports: [
    ToastComponent
  ],
  providers: [],
  entryComponents: [
    ToastComponent
  ]
})

export class ToastModule {
}
