<form class="relationship-wrapper" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="prompt">{{ relationshipTitle }}</div>
  <div class="group" *screenSize="'notMobile'">
    <mem-button-box-group style="width:100%;" [formControlName]="'relationGroup'"
      [initialValue]="buttonGroupOptions[groupIndex]" [options]="buttonGroupOptions"></mem-button-box-group>
  </div>
  <mem-button-stack [value]="form.controls['relationGroup'].value" *screenSize="'mobile'" [stack]="groupStack"
    [check]="true" [form]="form" controlName="relationGroup" (click)="setGroup(form.controls.relationGroup.value)">
  </mem-button-stack>
  <div class="relationship"
    *ngIf="form.controls['relationGroup'].value && form.controls['relationGroup'].value !== 'self'" [@enterAnimation]>
    <mem-input-basic placeholder="{{placeholder}}" [form]="form" controlName="relationship" limit="35">{{prompt}}
    </mem-input-basic>
  </div>
  <mem-button-cta *ngIf="stackRef" [showSpinner]="stackRef.submitting" [large]="true" type="submit"
    [disabled]="!form.valid"><span>NEXT</span></mem-button-cta>
</form>