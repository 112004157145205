import { Component, Input, OnInit } from '@angular/core';
import { FormControlName, FormGroup } from '@angular/forms';

@Component({
  selector: 'mem-button-stack',
  template: `
    <ng-container *ngFor="let option of stack" [formGroup]="form">
      <div class="option" (click)="setValue(option.value)">
        <div class="content">
          <span class="icon {{option.icon}}"></span>
          <div>{{option.label}}</div>
        </div>
        <div class="check" [class.checked]="value == option.value" *ngIf="check">
          <span class="icon icon-check" style="font-size: 12px; margin: 0px 0px 0px 2px; color: #FFFFFF" *ngIf="value == option.value"></span>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: [`./button-stack.component.scss`],
})
export class ButtonStackComponent implements OnInit {
  @Input('stack') stack: { icon: string, label: string, value: any }[];
  @Input('check') check: boolean = false;
  @Input('form') form: FormGroup;
  @Input('controlName') controlName: FormControlName;
  @Input() showSpinner: boolean = false;
  @Input() value: string = '';

  ngOnInit() {
  }

  setValue(value: any) {
    this.form.controls[this.controlName.toString()].setValue(value)
    this.value = value;
  }

}
